import React, { useContext, useEffect, useRef, useState } from "react";
import { Carousel } from "react-bootstrap";
import styles from "./Home.module.scss";
import { Link } from "react-router-dom";
import { fmDataContext } from "../../Context/FmData";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Keyboard, Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import ReleaseCard from "../ReleaseCard/ReleaseCard";
import VideoCard from "../VideoCard/VideoCard";
import NewBlogCard from "../NewBlogCard/NewBlogCard";
import ArtistCard from "../ArtistCard/ArtistCard";
import LoadingScreen from "../LoadingScreen/LoadingScreen";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { Helmet } from "react-helmet-async";

const Home = () => {
  const isCalledRef = useRef(false);
  let { baseUrl, getData, navigate, isMainDataLoading } = useContext(fmDataContext);

  let [isHomeDataLoading, setIsHomeDataLoading] = useState(true);
  let [homeData, setHomeData] = useState({});

  let [nowReleasesSwiperCount, setNowReleasesSwiperCount] = useState(4);
  let [soonReleasesSwiperCount, setSoonReleasesSwiperCount] = useState(4);
  let [videosSwiperCount, setVideosSwiperCount] = useState(2);
  let [newsSwiperCount, setNewsSwiperCount] = useState(2);
  let [artistsSwiperCount, setArtistsSwiperCount] = useState(6);

  const setNowReleasesSlidesCount = () => {
    if (window.innerWidth >= 1400) {
      setNowReleasesSwiperCount(4);
    } else if (window.innerWidth < 1400 && window.innerWidth >= 1200) {
      setNowReleasesSwiperCount(3);
    } else if (window.innerWidth < 1200 && window.innerWidth >= 992) {
      setNowReleasesSwiperCount(3);
    } else if (window.innerWidth < 992 && window.innerWidth >= 768) {
      setNowReleasesSwiperCount(2);
    } else if (window.innerWidth < 768 && window.innerWidth >= 576) {
      setNowReleasesSwiperCount(2);
    } else if (window.innerWidth < 576 && window.innerWidth >= 480) {
      setNowReleasesSwiperCount(2);
    } else if (window.innerWidth < 480 && window.innerWidth >= 400) {
      setNowReleasesSwiperCount(2);
    } else if (window.innerWidth < 400 && window.innerWidth > 0) {
      setNowReleasesSwiperCount(1);
    }
  };

  const setSoonReleasesSlidesCount = () => {
    if (window.innerWidth >= 1400) {
      setSoonReleasesSwiperCount(4);
    } else if (window.innerWidth < 1400 && window.innerWidth >= 1200) {
      setSoonReleasesSwiperCount(3);
    } else if (window.innerWidth < 1200 && window.innerWidth >= 992) {
      setSoonReleasesSwiperCount(3);
    } else if (window.innerWidth < 992 && window.innerWidth >= 768) {
      setSoonReleasesSwiperCount(2);
    } else if (window.innerWidth < 768 && window.innerWidth >= 576) {
      setSoonReleasesSwiperCount(2);
    } else if (window.innerWidth < 576 && window.innerWidth >= 480) {
      setSoonReleasesSwiperCount(2);
    } else if (window.innerWidth < 480 && window.innerWidth >= 400) {
      setSoonReleasesSwiperCount(2);
    } else if (window.innerWidth < 400 && window.innerWidth > 0) {
      setSoonReleasesSwiperCount(1);
    }
  };

  const setVideosSlidesCount = () => {
    if (window.innerWidth >= 1200) {
      setVideosSwiperCount(2);
    } else if (window.innerWidth < 1200 && window.innerWidth >= 992) {
      setVideosSwiperCount(2);
    } else if (window.innerWidth < 992 && window.innerWidth >= 768) {
      setVideosSwiperCount(1);
    } else if (window.innerWidth < 768 && window.innerWidth > 0) {
      setVideosSwiperCount(1);
    }
  };

  const setNewsSlidesCount = () => {
    if (window.innerWidth >= 1400) {
      setNewsSwiperCount(2);
    } else if (window.innerWidth < 1400 && window.innerWidth >= 1200) {
      setNewsSwiperCount(2);
    } else if (window.innerWidth < 1200 && window.innerWidth >= 992) {
      setNewsSwiperCount(2);
    } else if (window.innerWidth < 992 && window.innerWidth >= 768) {
      setNewsSwiperCount(1);
    } else if (window.innerWidth < 768 && window.innerWidth >= 576) {
      setNewsSwiperCount(1);
    } else if (window.innerWidth < 576 && window.innerWidth > 0) {
      setNewsSwiperCount(1);
    }
  };

  const setArtistsSlidesCount = () => {
    if (window.innerWidth > 1400) {
      setArtistsSwiperCount(6);
    } else if (window.innerWidth < 1400 && window.innerWidth >= 1200) {
      setArtistsSwiperCount(5);
    } else if (window.innerWidth < 1200 && window.innerWidth >= 992) {
      setArtistsSwiperCount(5);
    } else if (window.innerWidth < 992 && window.innerWidth >= 768) {
      setArtistsSwiperCount(4);
    } else if (window.innerWidth < 768 && window.innerWidth >= 576) {
      setArtistsSwiperCount(3);
    } else if (window.innerWidth < 576 && window.innerWidth >= 480) {
      setArtistsSwiperCount(3);
    } else if (window.innerWidth < 480 && window.innerWidth >= 400) {
      setArtistsSwiperCount(3);
    } else if (window.innerWidth < 400 && window.innerWidth > 0) {
      setArtistsSwiperCount(2);
    }
  };

  const getHomeData = (reqMethod, pathName, params = null, setLoading = setIsHomeDataLoading) => {
    getData(reqMethod, pathName, params, setLoading, {
      Accept: "application/json",
      "fm-api-secret-key": process.env.REACT_APP_FM_API_SECRET_KEY,
    })
      .then((response) => {
        // ------------------- REMOVE BEFORE PRODUCTION -----------------------------------
        // if (response.data.home_carousel_slides) {
        //   response.data.home_carousel_slides.forEach((slide) => {
        //     if (slide.slide_image.includes("via.placeholder.com")) {
        //       slide.slide_image = slide.slide_image.replace("http://127.0.0.1:8000/storage/", "");
        //     }
        //   });
        // }
        // if (response.data.out_now_releases) {
        //   response.data.out_now_releases.forEach((release) => {
        //     if (release.release_image.includes("via.placeholder.com")) {
        //       release.release_image = release.release_image.replace("http://127.0.0.1:8000/storage/", "");
        //     }
        //   });
        // }
        // if (response.data.out_soon_releases) {
        //   response.data.out_soon_releases.forEach((release) => {
        //     if (release.release_image.includes("via.placeholder.com")) {
        //       release.release_image = release.release_image.replace("http://127.0.0.1:8000/storage/", "");
        //     }
        //   });
        // }
        // if (response.data.latest_news) {
        //   response.data.latest_news.forEach((newBlog) => {
        //     if (newBlog.new_image.includes("via.placeholder.com")) {
        //       newBlog.new_image = newBlog.new_image.replace("http://127.0.0.1:8000/storage/", "");
        //     }
        //   });
        // }
        // if (response.data.latest_artists) {
        //   response.data.latest_artists.forEach((artist) => {
        //     if (artist.artist_image.includes("via.placeholder.com")) {
        //       artist.artist_image = artist.artist_image.replace("http://127.0.0.1:8000/storage/", "");
        //     }
        //   });
        // }
        // ------------------- REMOVE BEFORE PRODUCTION -----------------------------------
        setHomeData(response.data);
        setLoading(false);
      })
      .catch((error) => {
        navigate("/not-found");
      });
  };

  useEffect(() => {
    if (!isCalledRef.current) {
      getHomeData("GET", `${baseUrl}fm-get-home-page`, null, setIsHomeDataLoading);
    }

    setNowReleasesSlidesCount();
    setSoonReleasesSlidesCount();
    setVideosSlidesCount();
    setNewsSlidesCount();
    setArtistsSlidesCount();
    window.addEventListener("resize", function () {
      setNowReleasesSlidesCount();
      setSoonReleasesSlidesCount();
      setVideosSlidesCount();
      setNewsSlidesCount();
      setArtistsSlidesCount();
    });
    return () => {
      isCalledRef.current = true;
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>Futurising Music</title>
        <link rel="canonical" href={`${window.location.href}`} />
      </Helmet>
      {isHomeDataLoading || isMainDataLoading ? (
        <LoadingScreen />
      ) : (
        <>
          {homeData.home_carousel_slides && homeData.home_carousel_slides.length > 1 ? (
            <div className={styles.carousel_container}>
              <div className={`pt-4 ${styles.carousel_parent}`}>
                <Carousel
                  slide={false}
                  indicators={false}
                  controls={true}
                  nextIcon={
                    <button className={`bg-transparent border-0 p-0 ${styles.carousel_next}`} type="button">
                      <span aria-hidden="false">
                        <i className="bi bi-arrow-right"></i>
                      </span>
                      <span className="visually-hidden">Next</span>
                    </button>
                  }
                  prevIcon={
                    <button className={`bg-transparent border-0 p-0 ${styles.carousel_prev}`} type="button">
                      <span aria-hidden="false">
                        <i className="bi bi-arrow-left"></i>
                      </span>
                      <span className="visually-hidden">Previous</span>
                    </button>
                  }
                  pause={false}
                  interval={6000}
                  touch={false}
                  id="homeCarousel">
                  {homeData.home_carousel_slides.map((slide, index) => (
                    <Carousel.Item key={index}>
                      <div className={`${styles.carousel_slide} fu-carousel-slide`}>
                        <div className="container">
                          <div className="row gy-3 gy-sm-4 gy-lg-0">
                            <div className="col-12 col-lg-6 col-xl-7 order-last order-lg-first">
                              <div className={`${styles.carousel_caption} fu-carousel-caption pt-lg-4 mt-lg-5 pt-xxl-5`}>
                                <div className="mb-4 mb-md-5">
                                  <h2 className={`${styles.slide_caption_heading} fu-slide-caption-heading mb-2 mb-sm-3 mb-md-4 line-clamp-1`}>{slide.slide_title.toUpperCase()}</h2>
                                  {slide.slide_description ? <div className={`${styles.slide_caption_descriprion} fu-slide-caption-descriprion mb-0`} dangerouslySetInnerHTML={{ __html: slide.slide_description }} /> : ""}
                                </div>
                                <div>
                                  {slide.btn_text_outside && slide.btn_path_outside ? (
                                    <a href={slide.btn_path_outside} className="fu-btn fu-btn-gray mx-2 mb-2" target="_blank">
                                      <span className="btn-bg"></span>
                                      {slide.btn_text_outside.toUpperCase()}
                                    </a>
                                  ) : (
                                    ""
                                  )}
                                  {slide.btn_text_inside && slide.btn_path_inside ? (
                                    <Link to={`/${slide.btn_path_inside}`} className="fu-btn fu-btn-gray mx-2 mb-2">
                                      <span className="btn-bg"></span>
                                      {slide.btn_text_inside.toUpperCase()}
                                    </Link>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="col-12 col-lg-6 col-xl-5 order-first order-first order-lg-last">
                              <div>
                                <LazyLoadImage loading="lazy" effect="blur" delayMethod="throttle" delayTime={300} src={slide.slide_image} alt="slide" className={`d-block mx-auto mx-lg-0 ms-lg-auto rounded-3 ${styles.carousel_img_size} fu-carousel-img-size`} />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="fu-bg-blur-fade-out">
                        <div className={`${styles.slide_bg_blur} fu-bg-blur-fade-in`} style={{ backgroundImage: `url(${slide.slide_image})` }}></div>
                      </div>
                    </Carousel.Item>
                  ))}
                </Carousel>
              </div>
              <div className={styles.gradient}></div>
            </div>
          ) : (
            ""
          )}
          <div className={`container position-relative min-vh-100 pt-4 ${styles.home_container_section}`}>
            {/* OUT NOW */}
            {homeData.out_now_releases ? (
              <div className="mb-4 mb-md-5">
                <div className={`d-flex justify-content-between align-items-center mb-3 mb-md-4 ${styles.section_heading}`}>
                  <div className="d-flex w-100">
                    <div className="me-2">
                      <h2 className={`mb-0 w-max ${styles.section_title}`}>OUT NOW</h2>
                    </div>
                    <div className={`w-100 align-self-center ${styles.heading_line}`}></div>
                  </div>
                  <div>
                    <Link className="fu-btn-sm fu-btn-white w-max" to="/music">
                      <span className="btn-bg"></span>
                      See More
                    </Link>
                  </div>
                </div>
                <div className="row gx-3">
                  <Swiper className="myswiper px-2 h-100" slidesPerView={nowReleasesSwiperCount} spaceBetween={10} freeMode={true} keyboard={true} navigation={true} modules={[FreeMode, Navigation, Keyboard]}>
                    {homeData.out_now_releases.map((release, index) => (
                      <SwiperSlide className="my-1" key={index}>
                        <ReleaseCard release={release} />
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </div>
              </div>
            ) : (
              ""
            )}
            {/* OUT SOON */}
            {homeData.out_soon_releases ? (
              <div className="mb-4 mb-md-5">
                <div className={`d-flex justify-content-between align-items-center mb-3 mb-md-4 ${styles.section_heading}`}>
                  <div className="d-flex w-100">
                    <div className="me-2">
                      <h2 className={`mb-0 w-max ${styles.section_title}`}>OUT SOON</h2>
                    </div>
                    <div className={`w-100 align-self-center ${styles.heading_line}`}></div>
                  </div>
                  <div>
                    <Link className="fu-btn-sm fu-btn-white w-max" to="/music">
                      <span className="btn-bg"></span>
                      See More
                    </Link>
                  </div>
                </div>
                <div className="row gx-3">
                  <Swiper className="myswiper px-2 h-100" slidesPerView={soonReleasesSwiperCount} spaceBetween={10} freeMode={true} keyboard={true} navigation={true} modules={[FreeMode, Navigation, Keyboard]}>
                    {homeData.out_soon_releases.map((release, index) => (
                      <SwiperSlide className="my-1" key={index}>
                        <ReleaseCard release={release} />
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </div>
              </div>
            ) : (
              ""
            )}
            {/* LATEST MUSIC */}
            {homeData.latest_music ? (
              <div className="mb-4 mb-md-5">
                <div className={`d-flex justify-content-between align-items-center mb-3 mb-md-4 ${styles.section_heading}`}>
                  <div className="d-flex w-100">
                    <div className="me-2">
                      <h2 className={`mb-0 w-max ${styles.section_title}`}>LATEST MUSIC</h2>
                    </div>
                    <div className={`w-100 align-self-center ${styles.heading_line}`}></div>
                  </div>
                  <div>
                    <Link className="fu-btn-sm fu-btn-white w-max" to="/videos">
                      <span className="btn-bg"></span>
                      See More
                    </Link>
                  </div>
                </div>
                <div className="row g-3">
                  <Swiper className="myswiper px-2 h-100" slidesPerView={videosSwiperCount} spaceBetween={10} freeMode={true} keyboard={true} navigation={true} modules={[FreeMode, Navigation, Keyboard]}>
                    {homeData.latest_music.map((singleVideo, index) => (
                      <SwiperSlide className="my-1" key={index}>
                        <VideoCard video={singleVideo} />
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </div>
              </div>
            ) : (
              ""
            )}
            {/* LATEST NEWS */}
            {homeData.latest_news ? (
              <div className="mb-4 mb-md-5">
                <div className={`d-flex justify-content-between align-items-center mb-3 mb-md-4 ${styles.section_heading}`}>
                  <div className="d-flex w-100">
                    <div className="me-2">
                      <h2 className={`mb-0 w-max ${styles.section_title}`}>LATEST NEWS</h2>
                    </div>
                    <div className={`w-100 align-self-center ${styles.heading_line}`}></div>
                  </div>
                  <div>
                    <Link className="fu-btn-sm fu-btn-white w-max" to="/news">
                      <span className="btn-bg"></span>
                      See More
                    </Link>
                  </div>
                </div>
                <div className="row gx-3">
                  <Swiper className="myswiper px-2 h-100" slidesPerView={newsSwiperCount} spaceBetween={10} freeMode={true} keyboard={true} navigation={true} modules={[FreeMode, Navigation, Keyboard]}>
                    {homeData.latest_news.map((newBlog, index) => (
                      <SwiperSlide className="my-1" key={index}>
                        <NewBlogCard newBlog={newBlog} />
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </div>
              </div>
            ) : (
              ""
            )}
            {/* ARTISTS*/}
            {homeData.latest_artists ? (
              <div>
                <div className={`d-flex justify-content-between align-items-center mb-3 mb-md-4 ${styles.section_heading}`}>
                  <div className="d-flex w-100">
                    <div className="me-2">
                      <h2 className={`mb-0 w-max ${styles.section_title}`}>ARTISTS</h2>
                    </div>
                    <div className={`w-100 align-self-center ${styles.heading_line}`}></div>
                  </div>
                  <div>
                    <Link className="fu-btn-sm fu-btn-white w-max" to="/artists">
                      <span className="btn-bg"></span>
                      See More
                    </Link>
                  </div>
                </div>
                <div className="row gx-3">
                  <Swiper className="myswiper px-2 pb-3 h-100" slidesPerView={artistsSwiperCount} spaceBetween={10} freeMode={true} keyboard={true} navigation={true} modules={[FreeMode, Navigation, Keyboard]}>
                    {homeData.latest_artists.map((artist, index) => (
                      <SwiperSlide className="my-1" key={index}>
                        <ArtistCard artist={artist} />
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </>
      )}
    </>
  );
};

export default Home;

/*
<>
  <div className={styles.carousel_container}>
    <div className={`pt-4 ${styles.carousel_parent}`}>
      <Carousel
        slide={false}
        indicators={false}
        controls={true}
        nextIcon={
          <button className={`bg-transparent border-0 p-0 ${styles.carousel_next}`} type="button">
            <span aria-hidden="false">
              <i className="bi bi-arrow-right"></i>
            </span>
            <span className="visually-hidden">Next</span>
          </button>
        }
        prevIcon={
          <button className={`bg-transparent border-0 p-0 ${styles.carousel_prev}`} type="button">
            <span aria-hidden="false">
              <i className="bi bi-arrow-left"></i>
            </span>
            <span className="visually-hidden">Previous</span>
          </button>
        }
        pause={false}
        interval={6000}
        touch={false}
        id="homeCarousel">
        <Carousel.Item>
          <div className={`${styles.carousel_slide} fu-carousel-slide`}>
            <div className="container">
              <div className="row gy-3 gy-sm-4 gy-lg-0">
                <div className="col-12 col-lg-6 col-xl-5 offset-xl-1 order-last order-lg-first">
                  <div className={`${styles.carousel_caption} fu-carousel-caption pt-lg-4 mt-lg-5 pt-xxl-5`}>
                    <div className="mb-4 mb-md-5">
                      <h2 className={`${styles.slide_caption_heading} fu-slide-caption-heading mb-2 mb-sm-3 mb-md-4`}>TALK TO YOU</h2>
                      <p className={`${styles.slide_caption_descriprion} fu-slide-caption-descriprion mb-0`}>The boss is back to destroy the dance floors with a massive bomb! 'Ahmed Helmy' pushes the envelope with his new single 'Talk To You' on 'Futurising Music' from the raging synths to the scorching beat drops and techy vocals.</p>
                    </div>
                    <div>
                      <a href="" className="fu-btn fu-btn-gray me-2" target="_blank">
                        <span className="btn-bg"></span>
                        Listen Now
                      </a>
                      <Link to="" className="fu-btn fu-btn-gray ms-2">
                        <span className="btn-bg"></span>
                        Read More
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-6 col-xl-5 order-first order-first order-lg-last">
                  <div>
                    <img className={`d-block mx-auto mx-lg-0 ms-lg-auto rounded-3 ${styles.carousel_img_size} fu-carousel-img-size`} src={Images.fm005Art} alt="First slide" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="fu-bg-blur-fade-out">
            <div className={`${styles.slide_bg_blur} fu-bg-blur-fade-in`} style={{ backgroundImage: `url(${Images.fm005Art})` }}></div>
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className={`${styles.carousel_slide} fu-carousel-slide`}>
            <div className="container">
              <div className="row gy-3 gy-sm-4 gy-lg-0">
                <div className="col-12 col-lg-6 col-xl-5 offset-xl-1 order-last order-lg-first">
                  <div className={`${styles.carousel_caption} fu-carousel-caption pt-lg-4 mt-lg-5 pt-xxl-5`}>
                    <div className="mb-4 mb-md-5">
                      <h2 className={`${styles.slide_caption_heading} fu-slide-caption-heading mb-2 mb-sm-3 mb-md-4`}>KING'S OUTLAW</h2>
                      <div className={`${styles.slide_caption_descriprion} fu-slide-caption-descriprion`}>Our First release from the label boss, 'Ahmed Helmy' after the success of his King's Series being played everywhere worldwide from Armin Van Buuren, he brings it to its real home 'Futurising Music' with his own signature and recognizable King's sound. Intertwining of the future trance sensibility and dark sounds of techno with the future rave elements giving a literally King's effect.</div>
                    </div>
                    <div>
                      <a href="" className="fu-btn fu-btn-gray" target="_blank">
                        <span className="btn-bg"></span>
                        Listen Now
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-6 col-xl-5 order-first order-first order-lg-last">
                  <div>
                    <img className={`d-block mx-auto mx-lg-0 ms-lg-auto rounded-3 ${styles.carousel_img_size} fu-carousel-img-size`} src={Images.fm001Art} alt="First slide" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="fu-bg-blur-fade-out">
            <div className={`${styles.slide_bg_blur} fu-bg-blur-fade-in`} style={{ backgroundImage: `url(${Images.fm001Art})` }}></div>
          </div>
        </Carousel.Item>
      </Carousel>
    </div>
    <div className={styles.gradient}></div>
  </div>
  <div className={`container position-relative ${styles.home_container_section}`}>
    <div className="mb-5">
      <div className={`d-flex justify-content-between align-items-center mb-4 ${styles.section_heading}`}>
        <div className="d-flex w-100">
          <div className="me-2">
            <h2 className={`mb-0 w-max ${styles.section_title}`}>OUT NOW</h2>
          </div>
          <div className={`w-100 align-self-center ${styles.heading_line}`}></div>
        </div>
        <div>
          <button className="fu-btn-sm fu-btn-white w-max">
            <span className="btn-bg"></span>
            See More
          </button>
        </div>
      </div>
      <div className="row gx-3">
        <Swiper className="myswiper px-2 h-100" slidesPerView={nowReleasesSwiperCount} spaceBetween={10} keyboard={true} navigation={true} modules={[Navigation, Keyboard]}>
          <SwiperSlide className="my-1">
            <ReleaseCard
              release={{
                release_key: "123456789",
                release_deleted_at: null,
                release_image: Images.fm001Art,
                is_pre_order: false,
                is_from_top_five: true,
                release_title: "KING'S OUTLAW",
                release_label: { label_name: "Futurising Music" },
                release_artists: [{ artist_name: "Ahmed Helmy" }],
              }}
            />
          </SwiperSlide>
          <SwiperSlide className="my-1">
            <ReleaseCard
              release={{
                release_key: "123456789",
                release_deleted_at: null,
                release_image: Images.fm002Art,
                is_pre_order: false,
                is_from_top_five: false,
                release_title: "YOU ARE REAL",
                release_label: { label_name: "Futurising Music" },
                release_artists: [{ artist_name: "Ahmed Helmy" }, { artist_name: "Bigtopo" }],
              }}
            />
          </SwiperSlide>
          <SwiperSlide className="my-1">
            <ReleaseCard
              release={{
                release_key: "123456789",
                release_deleted_at: null,
                release_image: Images.fm003Art,
                is_pre_order: false,
                is_from_top_five: false,
                release_title: "FIND YOUR LOVE",
                release_label: { label_name: "Futurising Music" },
                release_artists: [{ artist_name: "Harshil Kamdar" }, { artist_name: "Bigtopo" }],
              }}
            />
          </SwiperSlide>
          <SwiperSlide className="my-1">
            <ReleaseCard
              release={{
                release_key: "123456789",
                release_deleted_at: null,
                release_image: Images.fm004Art,
                is_pre_order: false,
                is_from_top_five: true,
                release_title: "VISION",
                release_label: { label_name: "Futurising Music" },
                release_artists: [{ artist_name: "LKX" }],
              }}
            />
          </SwiperSlide>
          <SwiperSlide className="my-1">
            <ReleaseCard
              release={{
                release_key: "123456789",
                release_deleted_at: null,
                release_image: Images.fm005Art,
                is_pre_order: false,
                is_from_top_five: true,
                release_title: "TALK TO YOU",
                release_label: { label_name: "Futurising Music" },
                release_artists: [{ artist_name: "Ahmed Helmy" }],
              }}
            />
          </SwiperSlide>
          <SwiperSlide className="my-1">
            <ReleaseCard
              release={{
                release_key: "123456789",
                release_deleted_at: null,
                release_image: Images.fm006Art,
                is_pre_order: false,
                is_from_top_five: false,
                release_title: "GLITCH",
                release_label: { label_name: "Futurising Music" },
                release_artists: [{ artist_name: "Ahmed Helmy" }, { artist_name: "Mavros" }, { artist_name: "LKX" }],
              }}
            />
          </SwiperSlide>
          <SwiperSlide className="my-1">
            <ReleaseCard
              release={{
                release_key: "123456789",
                release_deleted_at: null,
                release_image: Images.fm007Art,
                is_pre_order: false,
                is_from_top_five: false,
                release_title: "SOUL EMOTIONS",
                release_label: { label_name: "Futurising Music" },
                release_artists: [{ artist_name: "Bigtopo" }, { artist_name: "LKX" }],
              }}
            />
          </SwiperSlide>
          <SwiperSlide className="my-1">
            <ReleaseCard
              release={{
                release_key: "123456789",
                release_deleted_at: null,
                release_image: Images.fm008Art,
                is_pre_order: false,
                is_from_top_five: true,
                release_title: "NIGHT FURY",
                release_label: { label_name: "Futurising Music" },
                release_artists: [{ artist_name: "Azael" }],
              }}
            />
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
    <div className="mb-5">
      <div className={`d-flex justify-content-between align-items-center mb-4 ${styles.section_heading}`}>
        <div className="d-flex w-100">
          <div className="me-2">
            <h2 className={`mb-0 w-max ${styles.section_title}`}>OUT SOON</h2>
          </div>
          <div className={`w-100 align-self-center ${styles.heading_line}`}></div>
        </div>
        <div>
          <button className="fu-btn-sm fu-btn-white w-max">
            <span className="btn-bg"></span>
            See More
          </button>
        </div>
      </div>
      <div className="row gx-3">
        <Swiper className="myswiper px-2 h-100" slidesPerView={soonReleasesSwiperCount} spaceBetween={10} keyboard={true} navigation={true} modules={[Navigation, Keyboard]}>
          <SwiperSlide className="my-1">
            <ReleaseCard
              release={{
                release_key: "123456789",
                release_deleted_at: null,
                release_image: Images.fm009Art,
                is_pre_order: true,
                is_from_top_five: true,
                release_title: "VALHALLA",
                release_label: { label_name: "Futurising Music" },
                release_artists: [{ artist_name: "Tolga Uzulmez" }],
              }}
            />
          </SwiperSlide>
          <SwiperSlide className="my-1">
            <ReleaseCard
              release={{
                release_key: "123456789",
                release_deleted_at: null,
                release_image: Images.fm010Art,
                is_pre_order: true,
                is_from_top_five: false,
                release_title: "PULSAR",
                release_label: { label_name: "Futurising Music" },
                release_artists: [{ artist_name: "Zeus" }],
              }}
            />
          </SwiperSlide>
          <SwiperSlide className="my-1">
            <ReleaseCard
              release={{
                release_key: "123456789",
                release_deleted_at: null,
                release_image: Images.fm001Art,
                is_pre_order: true,
                is_from_top_five: false,
                release_title: "KING'S OUTLAW",
                release_label: { label_name: "Futurising Music" },
                release_artists: [{ artist_name: "Ahmed Helmy" }],
              }}
            />
          </SwiperSlide>
          <SwiperSlide className="my-1">
            <ReleaseCard
              release={{
                release_key: "123456789",
                release_deleted_at: null,
                release_image: Images.fm002Art,
                is_pre_order: true,
                is_from_top_five: false,
                release_title: "YOU ARE REAL",
                release_label: { label_name: "Futurising Music" },
                release_artists: [{ artist_name: "Bigtopo" }],
              }}
            />
          </SwiperSlide>
          <SwiperSlide className="my-1">
            <ReleaseCard
              release={{
                release_key: "123456789",
                release_deleted_at: null,
                release_image: Images.fm003Art,
                is_pre_order: true,
                is_from_top_five: false,
                release_title: "FIND YOUR LOVE",
                release_label: { label_name: "Futurising Music" },
                release_artists: [{ artist_name: "Harshil Kamdar" }],
              }}
            />
          </SwiperSlide>
          <SwiperSlide className="my-1">
            <ReleaseCard
              release={{
                release_key: "123456789",
                release_deleted_at: null,
                release_image: Images.fm004Art,
                is_pre_order: true,
                is_from_top_five: false,
                release_title: "VISION",
                release_label: { label_name: "Futurising Music" },
                release_artists: [{ artist_name: "LKX" }],
              }}
            />
          </SwiperSlide>
          <SwiperSlide className="my-1">
            <ReleaseCard
              release={{
                release_key: "123456789",
                release_deleted_at: null,
                release_image: Images.fm005Art,
                is_pre_order: true,
                is_from_top_five: false,
                release_title: "TALK TO YOU",
                release_label: { label_name: "Futurising Music" },
                release_artists: [{ artist_name: "LKX" }, { artist_name: "Ahmed Helmy" }, { artist_name: "Bigtopo" }, { artist_name: "Harshil Kamdar" }],
              }}
            />
          </SwiperSlide>
          <SwiperSlide className="my-1">
            <ReleaseCard
              release={{
                release_key: "123456789",
                release_deleted_at: null,
                release_image: Images.fm006Art,
                is_pre_order: true,
                is_from_top_five: false,
                release_title: "GLITCH",
                release_label: { label_name: "Futurising Music" },
                release_artists: [{ artist_name: "LKX" }, { artist_name: "Mavros" }, { artist_name: "Bigtopo" }],
              }}
            />
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
    <div className="mb-5">
      <div className={`d-flex justify-content-between align-items-center mb-4 ${styles.section_heading}`}>
        <div className="d-flex w-100">
          <div className="me-2">
            <h2 className={`mb-0 w-max ${styles.section_title}`}>LATEST MUSIC</h2>
          </div>
          <div className={`w-100 align-self-center ${styles.heading_line}`}></div>
        </div>
        <div>
          <button className="fu-btn-sm fu-btn-white w-max">
            <span className="btn-bg"></span>
            See More
          </button>
        </div>
      </div>
      <div className="row g-3">
        <Swiper className="myswiper px-2 h-100" slidesPerView={videosSwiperCount} spaceBetween={10} keyboard={true} navigation={true} modules={[Navigation, Keyboard]}>
          <SwiperSlide className="my-1">
            <VideoCard
              video={{
                youtube_video_id: "OxRjGxFpCbo",
                video_title: "Ahmed Helmy - King's Outlaw",
                video_label: { label_name: "Futurising Music" },
                video_artists: [{ artist_name: "Ahmed Helmy" }],
              }}
            />
          </SwiperSlide>
          <SwiperSlide className="my-1">
            <VideoCard
              video={{
                youtube_video_id: "u2_86A5_0Sk",
                video_title: "Bigtopo - You Are Real",
                video_label: { label_name: "Futurising Music" },
                video_artists: [{ artist_name: "Bigtopo" }],
              }}
            />
          </SwiperSlide>
          <SwiperSlide className="my-1">
            <VideoCard
              video={{
                youtube_video_id: "fwgnbNfvKTA",
                video_title: "Harshil Kamdar - Find Your Love",
                video_label: { label_name: "Futurising Music" },
                video_artists: [{ artist_name: "Harshil Kamdar" }],
              }}
            />
          </SwiperSlide>
          <SwiperSlide className="my-1">
            <VideoCard
              video={{
                youtube_video_id: "QjlvX2SiKds",
                video_title: "LKX - Vision",
                video_label: { label_name: "Futurising Music" },
                video_artists: [{ artist_name: "LKX" }],
              }}
            />
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
    <div className="mb-5">
      <div className={`d-flex justify-content-between align-items-center mb-4 ${styles.section_heading}`}>
        <div className="d-flex w-100">
          <div className="me-2">
            <h2 className={`mb-0 w-max ${styles.section_title}`}>LATEST NEWS</h2>
          </div>
          <div className={`w-100 align-self-center ${styles.heading_line}`}></div>
        </div>
        <div>
          <button className="fu-btn-sm fu-btn-white w-max">
            <span className="btn-bg"></span>
            See More
          </button>
        </div>
      </div>
      <div className="row gx-3">
        <Swiper className="myswiper px-2 h-100" slidesPerView={newsSwiperCount} spaceBetween={10} keyboard={true} navigation={true} modules={[Navigation, Keyboard]}>
          <SwiperSlide className="my-1">
            <NewBlogCard
              newBlog={{
                new_key: "123456789",
                new_image: Images.blog1,
                new_title: "Azael brings the mainstage sounds here to futurising with banger track 'Night Fury that will destroy the dance floors",
              }}
            />
          </SwiperSlide>
          <SwiperSlide className="my-1">
            <NewBlogCard
              newBlog={{
                new_key: "123456789",
                new_image: Images.blog2,
                new_title: "Bigtopo & LKX joined forces together to bring the future with this beauty, belnding trance",
              }}
            />
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
    <div>
      <div className={`d-flex justify-content-between align-items-center mb-4 ${styles.section_heading}`}>
        <div className="d-flex w-100">
          <div className="me-2">
            <h2 className={`mb-0 w-max ${styles.section_title}`}>ARTISTS</h2>
          </div>
          <div className={`w-100 align-self-center ${styles.heading_line}`}></div>
        </div>
        <div>
          <button className="fu-btn-sm fu-btn-white w-max">
            <span className="btn-bg"></span>
            See More
          </button>
        </div>
      </div>
      <div className="row gx-3">
        <Swiper className="myswiper px-2 pb-3 h-100" slidesPerView={artistsSwiperCount} spaceBetween={10} keyboard={true} navigation={true} modules={[Navigation, Keyboard]}>
          <SwiperSlide className="my-1">
            <ArtistCard
              artist={{
                artist_key: "123456789",
                artist_image: Images.artist,
                is_verified: true,
                is_from_top_five: true,
                artist_name: "Ahmed Helmy",
              }}
            />
          </SwiperSlide>
          <SwiperSlide className="my-1">
            <ArtistCard
              artist={{
                artist_key: "123456789",
                artist_image: Images.artist,
                is_verified: true,
                is_from_top_five: true,
                artist_name: "Ahmed Helmy",
              }}
            />
          </SwiperSlide>
          <SwiperSlide className="my-1">
            <ArtistCard
              artist={{
                artist_key: "123456789",
                artist_image: Images.artist,
                is_verified: false,
                is_from_top_five: false,
                artist_name: "Ahmed Helmy",
              }}
            />
          </SwiperSlide>
          <SwiperSlide className="my-1">
            <ArtistCard
              artist={{
                artist_key: "123456789",
                artist_image: Images.artist,
                is_verified: true,
                is_from_top_five: false,
                artist_name: "Ahmed Helmy",
              }}
            />
          </SwiperSlide>
          <SwiperSlide className="my-1">
            <ArtistCard
              artist={{
                artist_key: "123456789",
                artist_image: Images.artist,
                is_verified: true,
                is_from_top_five: true,
                artist_name: "Ahmed Helmy",
              }}
            />
          </SwiperSlide>
          <SwiperSlide className="my-1">
            <ArtistCard
              artist={{
                artist_key: "123456789",
                artist_image: Images.artist,
                is_verified: false,
                is_from_top_five: false,
                artist_name: "Ahmed Helmy",
              }}
            />
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  </div>
</>;
*/
