import React, { useContext, useEffect, useRef, useState } from "react";
import { fmDataContext } from "../../Context/FmData";
import { fmLabelsContext } from "../../Context/LabelsContext";
import Pagination from "../Pagination/Pagination";
import LoadingScreen from "../LoadingScreen/LoadingScreen";
import LoadingCircle from "../LoadingCircle/LoadingCircle";
import LabelsFiltration from "./LabelsFiltration";
import LabelCard from "../LabelCard/LabelCard";
import { Helmet } from "react-helmet-async";

const Labels = () => {
  const isCalledRef = useRef(false);

  let { baseUrl, navigate, getData, isMainDataLoading } = useContext(fmDataContext);
  let { filtrationDetails, isFiltrationLoading, setFiltrationDetails } = useContext(fmLabelsContext);

  let [labels, setLabels] = useState({});

  let [isGetLabelsLoading, setIsGetLabelsLoading] = useState(true);

  let [isPaginateLoading, setIsPaginateLoading] = useState(false);

  let containerRef = useRef();

  let [offsetElement, setOffsetElement] = useState({});

  let [pageNum, setPageNum] = useState(1);

  let perPage = 20;
  let orderBy = "id";
  let orderType = "asc";

  const getLabels = (reqMethod, pathName, params, setLoading = setIsGetLabelsLoading) => {
    getData(reqMethod, pathName, params, setLoading)
      .then((response) => {
        // ------------------- REMOVE BEFORE PRODUCTION -----------------------------------
        // if (response.data.data) {
        //   response.data.data.forEach((label) => {
        //     if (label.label_image.includes("via.placeholder.com")) {
        //       label.label_image = label.label_image.replace("http://127.0.0.1:8000/storage/", "");
        //     }
        //   });
        // }
        // if (response.data.page_header && response.data.page_header.image.includes("via.placeholder.com")) {
        //   response.data.page_header.image = response.data.page_header.image.replace("http://127.0.0.1:8000/storage/", "");
        // }
        // ------------------- REMOVE BEFORE PRODUCTION -----------------------------------
        setLabels(response.data);
        setLoading(false);
      })
      .catch((error) => {
        navigate("/not-found");
      });
  };

  useEffect(() => {
    if (!isCalledRef.current) {
      getLabels(
        "GET",
        `${baseUrl}fm-labels`,
        {
          page_num: pageNum,
          per_page: perPage,
          order_type: orderType,
          order_by: orderBy,
        },
        setIsGetLabelsLoading
      );
    }

    return () => {
      isCalledRef.current = true;
      setFiltrationDetails({
        search_query: "",
      });
    };
  }, []);

  useEffect(() => {
    if (containerRef.current) {
      setOffsetElement(containerRef);
    }
  }, [containerRef.current, isGetLabelsLoading, isMainDataLoading]);

  return (
    <>
      <Helmet>
        <title>Futurising Music - Labels</title>
        <link rel="canonical" href={`${window.location.href}`} />
      </Helmet>
      {isGetLabelsLoading || isMainDataLoading ? (
        <LoadingScreen />
      ) : (
        <>
          <div className="min-vh-100">
            {labels.page_header !== null ? (
              <div className="page-heading">
                <div className="heading-img-container" style={{ backgroundImage: `url(${labels.page_header.image})` }} />
              </div>
            ) : (
              ""
            )}
            <div className="container position-relative pt-4 min-vh-100" ref={containerRef}>
              <div className="mb-3 mb-sm-4 mb-xl-5">
                <h1 className="page-heading-title mb-0">LABELS</h1>
              </div>
              <div className="mb-5">
                <LabelsFiltration getLabels={getLabels} perPage={perPage} orderBy={orderBy} orderType={orderType} />
              </div>
              {isPaginateLoading ? (
                <div className="d-flex justify-content-center mt-5 pt-5 pb-5 mb-5">
                  <div className="position-relative mb-5 pb-5">
                    <LoadingCircle circleCont={85} icon={80} loader={85} dot={85} />
                  </div>
                </div>
              ) : (
                <>
                  {labels.data && labels.data.length > 0 ? (
                    <div className="row g-2 g-md-3">
                      {labels.data.map((label, index) => (
                        <div className="col-vs-6 col-xxxs-6 col-xxs-4 col-xs-4 col-sm-4 col-md-3 fu-col-lg-20 fu-col-xl-20 col-xxl-2" key={index}>
                          <LabelCard label={label} />
                        </div>
                      ))}
                    </div>
                  ) : labels.data && labels.data.length === 0 && filtrationDetails.search_query === "" && !isFiltrationLoading ? (
                    <div className="d-flex flex-column justify-content-center align-items-center mt-5 pt-5 item-msg">
                      <div>
                        <h2 className="fu-text-3-rem fu-text-yellow">
                          <i className="bi bi-info-circle"></i>
                        </h2>
                      </div>
                      <div>
                        <h3 className="h5 fu-text-light-gray">There Are No Labels In Our Label List, Come Back Later</h3>
                      </div>
                    </div>
                  ) : !isFiltrationLoading ? (
                    <div className="d-flex flex-column justify-content-center align-items-center mt-5 pt-5 pb-5 mb-5 item-msg">
                      <div>
                        <h2 className="fu-text-3-rem fu-text-yellow">
                          <i className="bi bi-search"></i>
                        </h2>
                      </div>
                      <div>
                        <h3 className="h5 fu-text-light-gray">There are no labels matching your search</h3>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="mt-5">{labels ? labels.meta && labels.meta.last_page !== 1 ? <Pagination offsetElement={offsetElement} pages={labels.meta.links} pagination={labels.meta} getItems={getLabels} pathName={"fm-labels"} filtrationDetails={filtrationDetails} setIsPaginateLoading={setIsPaginateLoading} setPageNum={setPageNum} per_page={perPage} order_by={orderBy} order_type={orderType} /> : "" : ""}</div>
                </>
              )}
            </div>
          </div>
        </>
      )}

      {/* <div className="page-heading">
        <div className="heading-img-container" style={{ backgroundImage: `url(${Images.testHeading})` }} />
      </div>
      <div className="container position-relative pt-4">
        <div className="mb-3 mb-sm-4 mb-xl-5">
          <h1 className="page-heading-title mb-0">LABELS</h1>
        </div>
        <div className="mb-5">
          <LabelsFiltration getLabels={getLabels} perPage={perPage} orderBy={orderBy} orderType={orderType} />
        </div>
        <div className="row g-2 g-md-3">
          {Array(24)
            .fill(1)
            .map((key, index) => (
              <div className="col-vs-6 col-xxxs-6 col-xxs-4 col-xs-4 col-sm-4 col-md-3 fu-col-lg-20 fu-col-xl-20 col-xxl-2" key={index}>
                <LabelCard
                  label={{
                    label_key: "123456798",
                    label_image: Images.futurisingIconSquare,
                    label_name: "Futurising Music",
                  }}
                />
              </div>
            ))}
        </div>
      </div> */}
    </>
  );
};

export default Labels;
