import { RouterProvider, createBrowserRouter } from "react-router-dom";
import MasterLayout from "./components/MasterLayout/MasterLayout";
import Home from "./components/Home/Home";
import About from "./components/About/About";
import Music from "./components/Music/Music";
import Artists from "./components/Artists/Artists";
import DemoDrop from "./components/DemoDrop/DemoDrop";
import Videos from "./components/Videos/Videos";
import News from "./components/News/News";
import Partnerships from "./components/Partnerships/Partnerships";
import Contact from "./components/Contact/Contact";
import PrivacyPolicy from "./components/PrivacyPolicy/PrivacyPolicy";
import NotFound from "./components/NotFound/NotFound";
import ArtistDetails from "./components/ArtistDetails/ArtistDetails";
import ReleaseDetails from "./components/ReleaseDetails/ReleaseDetails";
import NewDetails from "./components/NewDetails/NewDetails";
import Labels from "./components/Labels/Labels";
import LabelDetails from "./components/LabelDetails/LabelDetails";
import Search from "./components/Search/Search";
import FmData from "./Context/FmData";
import MusicContext from "./Context/MusicContext";
import NewsContext from "./Context/NewsContext";
import VideosContext from "./Context/VideosContext";
import ArtistsContext from "./Context/ArtistsContext";
import LabelsContext from "./Context/LabelsContext";
import PartnershipsContext from "./Context/PartnershipsContext";

function App() {
  const routes = createBrowserRouter([
    {
      path: "/",
      element: (
        <FmData>
          <MasterLayout />
        </FmData>
      ),
      children: [
        { index: true, element: <Home /> },
        {
          path: "news",
          element: (
            <NewsContext>
              <News />
            </NewsContext>
          ),
        },
        { path: "news/:newId", element: <NewDetails /> },
        {
          path: "music",
          element: (
            <MusicContext>
              <Music />
            </MusicContext>
          ),
        },
        { path: "music/:releaseId", element: <ReleaseDetails /> },
        {
          path: "videos",
          element: (
            <VideosContext>
              <Videos />
            </VideosContext>
          ),
        },
        {
          path: "artists",
          element: (
            <ArtistsContext>
              <Artists />
            </ArtistsContext>
          ),
        },
        { path: "artists/:artistId", element: <ArtistDetails /> },
        {
          path: "labels",
          element: (
            <LabelsContext>
              <Labels />
            </LabelsContext>
          ),
        },
        { path: "labels/:labelId", element: <LabelDetails /> },
        { path: "demo-drop", element: <DemoDrop /> },
        {
          path: "partners",
          element: (
            <PartnershipsContext>
              <Partnerships />
            </PartnershipsContext>
          ),
        },
        { path: "about", element: <About /> },
        { path: "contact", element: <Contact /> },
        { path: "privacy-policy", element: <PrivacyPolicy /> },
        { path: "search/:searchObj", element: <Search /> },
        { path: "*", element: <NotFound /> },
      ],
    },
  ]);

  return (
    <>
      <RouterProvider router={routes} />
    </>
  );
}

export default App;
