import React, { useContext, useEffect, useState } from "react";
import styles from "./Contact.module.scss";
import { Button, Col, Form, Row } from "react-bootstrap";
import { fmDataContext } from "../../Context/FmData";
import LoadingScreen from "../LoadingScreen/LoadingScreen";
import { Helmet } from "react-helmet-async";
import axios from "axios";
import { toast } from "react-toastify";
import Joi from "joi";
import Reveal from "../Reveal/Reveal";
import { motion } from "framer-motion";
import LoadingCircle from "../LoadingCircle/LoadingCircle";

const Contact = () => {
  let { baseUrl, navigate, getData, mainData, isMainDataLoading } = useContext(fmDataContext);

  let [pageHeader, setPageHeader] = useState({});

  let [isGetPageHeaderLoading, setIsGetPageHeaderLoading] = useState(true);

  let [joiErrorsList, setJoiErrorsList] = useState([]);

  let [sendMsgResponse, setSendMsgResponse] = useState({});

  let [isSendMsgLoading, setIsSendMsgLoading] = useState(false);

  const getPageHeader = (reqMethod, pathName, params, setLoading = setIsGetPageHeaderLoading) => {
    getData(reqMethod, pathName, params, setLoading)
      .then((response) => {
        // ------------------- REMOVE BEFORE PRODUCTION -----------------------------------
        // if (response.data.page_header && response.data.page_header.image.includes("via.placeholder.com")) {
        //   response.data.page_header.image = response.data.page_header.image.replace("http://127.0.0.1:8000/storage/", "");
        // }
        // ------------------- REMOVE BEFORE PRODUCTION -----------------------------------
        setPageHeader(response.data.page_header);
        setLoading(false);
      })
      .catch((error) => {
        navigate("/not-found");
      });
  };

  const sendMsgMail = async (e) => {
    e.preventDefault();

    let msgData = {
      first_name: e.target.first_name.value,
      last_name: e.target.last_name.value,
      email_address: e.target.email_address.value,
      user_message: e.target.user_message.value.replaceAll("\n", "<br />"),
    };

    const validationResult = validateDemoForm(msgData);
    setSendMsgResponse({});

    if (validationResult.error) {
      validationResult.error ? setJoiErrorsList(validationResult.error.details) : setJoiErrorsList([]);
    } else {
      setIsSendMsgLoading(true);
      setJoiErrorsList([]);
      await axios({
        method: "POST",
        url: `${baseUrl}fm-contact-message-confirmation-mail`,
        data: msgData,
        headers: {
          Accept: "application/json",
          "fm-api-secret-key": process.env.REACT_APP_FM_API_SECRET_KEY,
        },
      })
        .then((response) => {
          setIsSendMsgLoading(false);
          if (response.data.message && response.data.message.includes("successfully")) {
            e.target.reset();
            setSendMsgResponse({});
            toast(`confirmation mail was sent successfully to ${msgData.email_address}`, {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              progressStyle: { backgroundColor: "#fdb915" },
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
              progress: undefined,
              theme: "dark",
            });
          } else if (response.data.errors) {
            setSendMsgResponse(response.data);
          } else {
            navigate("/not-found");
          }
        })
        .catch((errors) => {
          navigate("/not-found");
        });
    }
  };

  const validateDemoForm = (msgData) => {
    const scheme = Joi.object({
      first_name: Joi.string().min(1).max(255).required(),
      last_name: Joi.string().min(1).max(255).required(),
      email_address: Joi.string()
        .max(255)
        .email({ tlds: { allow: ["com", "net", "org", "eg"] } })
        .required(),
      user_message: Joi.string().min(1).max(1000).required(),
    });
    return scheme.validate(msgData, { abortEarly: false });
  };

  useEffect(() => {
    getPageHeader(
      "GET",
      `${baseUrl}fm-get-page-header`,
      {
        page_title: "contact",
      },
      setIsGetPageHeaderLoading
    );
  }, []);

  return (
    <>
      <Helmet>
        <title>Futurising Music - Contact</title>
        <link rel="canonical" href={`${window.location.href}`} />
        <meta name="robots" content="noindex" />
      </Helmet>
      {isMainDataLoading || isGetPageHeaderLoading ? (
        <LoadingScreen />
      ) : (
        <>
          {pageHeader ? (
            <div className="page-heading">
              <div className="heading-img-container" style={{ backgroundImage: `url(${pageHeader.image})` }} />
            </div>
          ) : (
            ""
          )}
          <div className="container position-relative pt-4">
            <div className="mb-3 mb-sm-4 mb-xl-5">
              <h1 className="page-heading-long-title mb-0">CONTACT US</h1>
            </div>
            <div className="mb-3 mb-md-4 item-row-heading">
              <h2 className="mb-0 row-title">Get In Touch</h2>
            </div>
            <div className="row gy-3 gy-md-3 gy-lg-4">
              <div className="col-xl-8 order-last order-xl-first">
                <div className={`pe-0 pe-xl-4 ${styles.contact_form_box}`}>
                  <div className="mb-3">
                    <h3 className={`${styles.form_title} fu-text-light-gray mb-1`}>Do You Have a Question ?</h3>
                    <h3 className={`${styles.form_title} fu-text-yellow fu-fw-700 mb-0`}>Send Us Now...</h3>
                  </div>
                  <div>
                    <Form onSubmit={sendMsgMail}>
                      <Row>
                        <Col md={6}>
                          <Form.Group className="mb-3" controlId="firstName">
                            <Form.Label className="fu-text-light-gray" data-title="required">
                              First Name
                              <span className="required-input mx-1" data-title="required">
                                *
                              </span>
                            </Form.Label>
                            <Form.Control type="text" placeholder="Enter your first name" className="fu-input" name="first_name" autoComplete="on" />
                            {joiErrorsList.map((error, index) =>
                              error.path[0] === "first_name" ? (
                                <motion.div key={index} initial={{ height: 0 }} animate={{ height: "auto" }} exit={{ height: 0, transition: { duration: 0.2, ease: "easeInOut" } }} transition={{ duration: 0.2, ease: "easeInOut" }}>
                                  <Reveal>
                                    <div className="alert fu-alert-yellow mt-2 mb-1">{error.message.replaceAll(`"first_name"`, "First Name")}</div>
                                  </Reveal>
                                </motion.div>
                              ) : (
                                ""
                              )
                            )}
                          </Form.Group>
                        </Col>
                        <Col md={6}>
                          <Form.Group className="mb-3" controlId="lastName">
                            <Form.Label className="fu-text-light-gray" data-title="required">
                              Last Name
                              <span className="required-input mx-1" data-title="required">
                                *
                              </span>
                            </Form.Label>
                            <Form.Control type="text" placeholder="Enter your last name" className="fu-input" name="last_name" />
                            {joiErrorsList.map((error, index) =>
                              error.path[0] === "last_name" ? (
                                <motion.div key={index} initial={{ height: 0 }} animate={{ height: "auto" }} exit={{ height: 0, transition: { duration: 0.2, ease: "easeInOut" } }} transition={{ duration: 0.2, ease: "easeInOut" }}>
                                  <Reveal>
                                    <div className="alert fu-alert-yellow mt-2 mb-1">{error.message.replaceAll(`"last_name"`, "Last Name")}</div>
                                  </Reveal>
                                </motion.div>
                              ) : (
                                ""
                              )
                            )}
                          </Form.Group>
                        </Col>
                      </Row>
                      <Form.Group className="mb-3" controlId="emailAddress">
                        <Form.Label className="fu-text-light-gray" data-title="required">
                          Email address
                          <span className="required-input mx-1" data-title="required">
                            *
                          </span>
                        </Form.Label>
                        <Form.Control type="email" placeholder="Enter your E-mail address" className="fu-input" name="email_address" />
                        {joiErrorsList.map((error, index) =>
                          error.path[0] === "email_address" ? (
                            <motion.div key={index} initial={{ height: 0 }} animate={{ height: "auto" }} exit={{ height: 0, transition: { duration: 0.2, ease: "easeInOut" } }} transition={{ duration: 0.2, ease: "easeInOut" }}>
                              <Reveal>
                                <div className="alert fu-alert-yellow mt-2 mb-1">{error.message.replaceAll(`"email_address"`, "Email Address")}</div>
                              </Reveal>
                            </motion.div>
                          ) : (
                            ""
                          )
                        )}
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="userMsg">
                        <Form.Label className="fu-text-light-gray" data-title="required">
                          Message
                          <span className="required-input mx-1" data-title="required">
                            *
                          </span>
                        </Form.Label>
                        <Form.Control as="textarea" rows={4} className="fu-input" placeholder="Enter your message" name="user_message" />
                        {joiErrorsList.map((error, index) =>
                          error.path[0] === "user_message" ? (
                            <motion.div key={index} initial={{ height: 0 }} animate={{ height: "auto" }} exit={{ height: 0, transition: { duration: 0.2, ease: "easeInOut" } }} transition={{ duration: 0.2, ease: "easeInOut" }}>
                              <Reveal>
                                <div className="alert fu-alert-yellow mt-2 mb-1">{error.message.replaceAll(`"user_message"`, "Message")}</div>
                              </Reveal>
                            </motion.div>
                          ) : (
                            ""
                          )
                        )}
                      </Form.Group>
                      {sendMsgResponse.errors
                        ? Object.keys(sendMsgResponse.errors).map((key) =>
                            sendMsgResponse.errors[key].map((error, index) => (
                              <motion.div key={index} initial={{ height: 0 }} animate={{ height: "auto" }} exit={{ height: 0, transition: { duration: 0.2, ease: "easeInOut" } }} transition={{ duration: 0.2, ease: "easeInOut" }}>
                                <Reveal>
                                  <div className="alert fu-alert-yellow mt-2 mb-1">{error}</div>
                                </Reveal>
                              </motion.div>
                            ))
                          )
                        : ""}
                      <div className="d-flex align-items-center mt-4">
                        <div>
                          <Button className="btn fu-btn fu-btn-gray d-flex justify-content-center align-items-center" type="submit" disabled={isSendMsgLoading ? true : false}>
                            <span className="btn-bg"></span>
                            Send<i className="bi bi-send ms-2"></i>
                          </Button>
                        </div>
                        {isSendMsgLoading ? (
                          <div className="position-relative ms-3">
                            <LoadingCircle circleCont={30} icon={25} loader={30} dot={30} />
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 order-first order-xl-last">
                <div className="mb-3 mb-lg-4 mb-xl-3">
                  <p className="fu-text-light-gray demo-drop-explain mb-0">The interests of our customers are always the top priority for us, so we hope you will enjoy our services as much as we enjoy making them available to you.</p>
                </div>
                <div className="row">
                  {mainData.futurising_data && mainData.futurising_data.phone_number ? (
                    <div className="col-12 col-xs-6 col-sm-6 col-xl-12">
                      <div className="d-flex align-items-center mb-4 h-100">
                        <div>
                          <div className={styles.contact_circle}>
                            <svg className={styles.contact_icon} viewBox="0 0 32.00 32.00" xmlns="http://www.w3.org/2000/svg">
                              <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                              <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                              <g id="SVGRepo_iconCarrier">
                                <path d="M11.748 5.773S11.418 5 10.914 5c-.496 0-.754.229-.926.387S6.938 7.91 6.938 7.91s-.837.731-.773 2.106c.054 1.375.323 3.332 1.719 6.058 1.386 2.72 4.855 6.876 7.047 8.337 0 0 2.031 1.558 3.921 2.191.549.173 1.647.398 1.903.398.26 0 .719 0 1.246-.385.536-.389 3.543-2.807 3.543-2.807s.736-.665-.119-1.438c-.859-.773-3.467-2.492-4.025-2.944-.559-.459-1.355-.257-1.699.054-.343.313-.956.828-1.031.893-.112.086-.419.365-.763.226-.438-.173-2.234-1.148-3.899-3.426-1.655-2.276-1.837-3.02-2.084-3.824a.56.56 0 0 1 .225-.657c.248-.172 1.161-.933 1.161-.933s.591-.583.344-1.27-1.906-4.716-1.906-4.716z"></path>
                              </g>
                            </svg>
                          </div>
                        </div>
                        <div className="ms-3">
                          <h3 className={`${styles.contact_info_title} mb-0`}>Phone Number</h3>
                          <h3 className={`${styles.contact_info_desc} mb-0`}>{mainData.futurising_data.phone_number}</h3>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {mainData.futurising_data && mainData.futurising_data.email_address ? (
                    <div className="col-12 col-xs-6 col-sm-6 col-xl-12">
                      <div className="d-flex align-items-center mb-4 h-100">
                        <div>
                          <div className={styles.contact_circle}>
                            <svg className={styles.contact_icon} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                              <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                              <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                              <g id="SVGRepo_iconCarrier">
                                <path
                                  strokeWidth="evenodd"
                                  clipRule="evenodd"
                                  d="M2.272 6.365C2 6.9 2 7.6 2 9v6c0 1.4 0 2.1.272 2.635a2.5 2.5 0 0 0 1.093 1.092C3.9 19 4.6 19 6 19h12c1.4 0 2.1 0 2.635-.273a2.5 2.5 0 0 0 1.092-1.092C22 17.1 22 16.4 22 15V9c0-1.4 0-2.1-.273-2.635a2.5 2.5 0 0 0-1.092-1.093C20.1 5 19.4 5 18 5H6c-1.4 0-2.1 0-2.635.272a2.5 2.5 0 0 0-1.093 1.093zM4.647 7h14.706a.5.5 0 0 1 .278.916l-7.075 4.732a1 1 0 0 1-1.112 0L4.369 7.916A.5.5 0 0 1 4.647 7z"
                                  className={styles.contact_icon}></path>
                              </g>
                            </svg>
                          </div>
                        </div>
                        <div className="ms-3">
                          <h3 className={`${styles.contact_info_title} mb-0`}>E-mail Address</h3>
                          <h3 className={`${styles.contact_info_desc} mb-0`}>{mainData.futurising_data.email_address}</h3>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {mainData.futurising_data && mainData.futurising_data.office_address ? (
                    <div className="col-12 col-xs-6 col-sm-6 col-xl-12">
                      <div className="d-flex align-items-center mb-4 h-100">
                        <div>
                          <div className={styles.contact_circle}>
                            <svg className={styles.contact_icon} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" viewBox="0 0 297 297" space="preserve">
                              <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                              <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                              <g id="SVGRepo_iconCarrier">
                                <path d="M148.5,0C87.43,0,37.747,49.703,37.747,110.797c0,91.026,99.729,179.905,103.976,183.645 c1.936,1.705,4.356,2.559,6.777,2.559c2.421,0,4.841-0.853,6.778-2.559c4.245-3.739,103.975-92.618,103.975-183.645 C259.253,49.703,209.57,0,148.5,0z M148.5,79.693c16.964,0,30.765,13.953,30.765,31.104c0,17.151-13.801,31.104-30.765,31.104 c-16.964,0-30.765-13.953-30.765-31.104C117.735,93.646,131.536,79.693,148.5,79.693z"></path>
                              </g>
                            </svg>
                          </div>
                        </div>
                        <div className="ms-3">
                          <h3 className={`${styles.contact_info_title} mb-0`}>Office Address</h3>
                          <h3 className={`${styles.contact_info_desc} mb-0`}>{mainData.futurising_data.office_address}</h3>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {mainData.futurising_data && mainData.futurising_data.working_hours ? (
                    <div className="col-12 col-xs-6 col-sm-6 col-xl-12">
                      <div className="d-flex align-items-center mb-4 h-100">
                        <div>
                          <div className={styles.contact_circle}>
                            <svg className={styles.contact_icon} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                              <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                              <g id="SVGRepo_iconCarrier">
                                <path
                                  strokeWidth="evenodd"
                                  clipRule="evenodd"
                                  d="M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12ZM12.4999 11.7929V6.96997C12.4999 6.69383 12.2761 6.46997 11.9999 6.46997C11.7238 6.46997 11.4999 6.69383 11.4999 6.96997V11.97C11.4999 11.975 11.5 11.9801 11.5002 11.9851C11.4962 12.118 11.545 12.2521 11.6464 12.3535L14.4748 15.1819C14.6701 15.3772 14.9867 15.3772 15.1819 15.1819C15.3772 14.9867 15.3772 14.6701 15.1819 14.4748L12.4999 11.7929Z"
                                  className={styles.contact_icon}></path>
                              </g>
                            </svg>
                          </div>
                        </div>
                        <div className="ms-3">
                          <h3 className={`${styles.contact_info_title} mb-0`}>Working Hours</h3>
                          <h3 className={`${styles.contact_info_desc} mb-0`}>{mainData.futurising_data.working_hours}</h3>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Contact;
