import React, { useContext, useEffect, useRef, useState } from "react";
import { fmDataContext } from "../../Context/FmData";
import Pagination from "../Pagination/Pagination";
import LoadingScreen from "../LoadingScreen/LoadingScreen";
import LoadingCircle from "../LoadingCircle/LoadingCircle";
import { fmMusicContext } from "../../Context/MusicContext";
import ReleaseCard from "../ReleaseCard/ReleaseCard";
import MusicFiltration from "./MusicFiltration";
import { Helmet } from "react-helmet-async";

const Music = () => {
  const isCalledRef = useRef(false);

  let { baseUrl, navigate, getData, getAllDataWithoutPaginate, isMainDataLoading } = useContext(fmDataContext);
  let { filtrationDetails, isFiltrationLoading, setFiltrationDetails } = useContext(fmMusicContext);

  let [releases, setReleases] = useState({});
  let [labels, setLabels] = useState([]);
  let [artists, setArtists] = useState([]);
  let [genres, setGenres] = useState([]);

  let containerRef = useRef();

  let [offsetElement, setOffsetElement] = useState({});

  let [isGetMediaBasiesLoading, setIsGetMediaBasiesLoading] = useState(true);

  let [isGetReleasesLoading, setIsGetReleasesLoading] = useState(true);

  let [isPaginateLoading, setIsPaginateLoading] = useState(false);

  let [pageNum, setPageNum] = useState(1);
  let perPage = 20;
  let orderBy = "id";
  let orderType = "desc";

  const getReleases = (reqMethod, pathName, params, setLoading = setIsGetReleasesLoading, message = null) => {
    getData(reqMethod, pathName, params, setLoading)
      .then((response) => {
        // ------------------- REMOVE BEFORE PRODUCTION -----------------------------------
        // if (response.data.data) {
        //   response.data.data.forEach((release) => {
        //     if (release.release_image.includes("via.placeholder.com")) {
        //       release.release_image = release.release_image.replace("http://127.0.0.1:8000/storage/", "");
        //     }
        //   });
        // }
        // if (response.data.page_header && response.data.page_header.image.includes("via.placeholder.com")) {
        //   response.data.page_header.image = response.data.page_header.image.replace("http://127.0.0.1:8000/storage/", "");
        // }
        // ------------------- REMOVE BEFORE PRODUCTION -----------------------------------
        setReleases(response.data);
        setLoading(false);
      })
      .catch((error) => {
        navigate("/not-found");
      });
  };

  const getMediaBasies = (reqMethod, pathName, params, setLoading = setIsGetMediaBasiesLoading) => {
    getAllDataWithoutPaginate(reqMethod, pathName, params, setLoading)
      .then((response) => {
        setLabels(response.data.labels);
        setArtists(response.data.artists);
        setGenres(response.data.genres);
        setLoading(false);
      })
      .catch((error) => {
        navigate("/not-found");
      });
  };

  useEffect(() => {
    if (!isCalledRef.current) {
      getReleases(
        "GET",
        `${baseUrl}fm-releases`,
        {
          page_num: pageNum,
          per_page: perPage,
          order_type: orderType,
          order_by: orderBy,
        },
        setIsGetReleasesLoading
      );
      getMediaBasies("GET", `${baseUrl}fm-get-tracks-data`, null, setIsGetMediaBasiesLoading);
    }
    return () => {
      isCalledRef.current = true;
      setFiltrationDetails({
        search_query: "",
        pre_order: null,
        label_keys: [],
        genre_keys: [],
        artist_keys: [],
        top_five_releases: null,
      });
    };
  }, []);

  useEffect(() => {
    if (containerRef.current) {
      setOffsetElement(containerRef);
    }
  }, [containerRef.current, isGetReleasesLoading, isGetMediaBasiesLoading, isMainDataLoading]);

  return (
    <>
      <Helmet>
        <title>Futurising Music - Music</title>
        <link rel="canonical" href={`${window.location.href}`} />
      </Helmet>
      {isGetReleasesLoading || isGetMediaBasiesLoading || isMainDataLoading ? (
        <LoadingScreen />
      ) : (
        <>
          <div className="min-vh-100">
            {releases.page_header !== null ? (
              <div className="page-heading">
                <div className="heading-img-container" style={{ backgroundImage: `url(${releases.page_header.image})` }} />
              </div>
            ) : (
              ""
            )}

            <div className="container position-relative pt-4 min-vh-100" ref={containerRef}>
              <div className="mb-3 mb-sm-4 mb-xl-5">
                <h1 className="page-heading-title mb-0">Music</h1>
              </div>
              <div className="mb-5">
                <MusicFiltration labels={labels} artists={artists} genres={genres} getReleases={getReleases} perPage={perPage} orderBy={orderBy} orderType={orderType} />
              </div>
              {isPaginateLoading ? (
                <div className="d-flex justify-content-center mt-5 pt-5 pb-5 mb-5">
                  <div className="position-relative mb-5 pb-5">
                    <LoadingCircle circleCont={85} icon={80} loader={85} dot={85} />
                  </div>
                </div>
              ) : (
                <>
                  {releases.data && releases.data.length > 0 ? (
                    <div className="row g-2 g-sm-3">
                      {releases.data.map((release, index) => (
                        <div className="fu-col-vs-12 fu-col-xxxs-12 col-xxs-6 col-xs-6 col-sm-6 col-lg-4 col-xxl-3" key={index}>
                          <ReleaseCard release={release} />
                        </div>
                      ))}
                    </div>
                  ) : releases.data && releases.data.length === 0 && filtrationDetails.search_query === "" && filtrationDetails.label_keys.length === 0 && filtrationDetails.genre_keys.length === 0 && filtrationDetails.artist_keys.length === 0 && filtrationDetails.pre_order === null && filtrationDetails.top_five_releases === null && !isFiltrationLoading ? (
                    <div className="d-flex flex-column justify-content-center align-items-center mt-5 pt-5 item-msg">
                      <div>
                        <h2 className="fu-text-3-rem fu-text-yellow">
                          <i className="bi bi-info-circle"></i>
                        </h2>
                      </div>
                      <div>
                        <h3 className="h5 fu-text-light-gray">There Are No Tracks In Our Track List, Come Back Later</h3>
                      </div>
                    </div>
                  ) : !isFiltrationLoading ? (
                    <div className="d-flex flex-column justify-content-center align-items-center mt-5 pt-5 pb-5 mb-5 item-msg">
                      <div>
                        <h2 className="fu-text-3-rem fu-text-yellow">
                          <i className="bi bi-search"></i>
                        </h2>
                      </div>
                      <div>
                        <h3 className="h5 fu-text-light-gray">There are no tracks matching your search</h3>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="mt-5">{releases ? releases.meta && releases.meta.last_page !== 1 ? <Pagination offsetElement={offsetElement} pages={releases.meta.links} pagination={releases.meta} getItems={getReleases} pathName={"fm-releases"} filtrationDetails={filtrationDetails} setIsPaginateLoading={setIsPaginateLoading} setPageNum={setPageNum} per_page={perPage} order_by={orderBy} order_type={orderType} /> : "" : ""}</div>
                </>
              )}
            </div>
          </div>
        </>
      )}

      {/* <div className="page-heading">
        <div className="heading-img-container" style={{ backgroundImage: `url(${Images.testHeading})` }} />
      </div>
      <div className="container position-relative pt-4">
        <div className="mb-3 mb-sm-4 mb-xl-5">
          <h1 className="page-heading-title mb-0">Music</h1>
        </div>
        <div className="mb-5">
          <MusicFiltration labels={labels} artists={artists} genres={genres} getReleases={getReleases} perPage={perPage} orderBy={orderBy} orderType={orderType} />
        </div>
        <div className="row g-2 g-sm-3">
          <div className="col-xxs-6 col-xs-6 col-sm-6 col-lg-4 col-xxl-3">
            <ReleaseCard
              release={{
                release_key: "123456789",
                release_deleted_at: null,
                release_image: Images.fm001Art,
                is_pre_order: false,
                is_from_top_five: true,
                release_title: "KING'S OUTLAW",
                release_label: { label_name: "Futurising Music" },
                release_artists: [{ artist_name: "Ahmed Helmy" }],
              }}
            />
          </div>
          <div className="col-xxs-6 col-xs-6 col-sm-6 col-lg-4 col-xxl-3">
            <ReleaseCard
              release={{
                release_key: "123456789",
                release_deleted_at: null,
                release_image: Images.fm002Art,
                is_pre_order: false,
                is_from_top_five: false,
                release_title: "YOU ARE REAL",
                release_label: { label_name: "Futurising Music" },
                release_artists: [{ artist_name: "Ahmed Helmy" }, { artist_name: "Bigtopo" }],
              }}
            />
          </div>
          <div className="col-xxs-6 col-xs-6 col-12 col-sm-6 col-lg-4 col-xxl-3">
            <ReleaseCard
              release={{
                release_key: "123456789",
                release_deleted_at: null,
                release_image: Images.fm003Art,
                is_pre_order: false,
                is_from_top_five: false,
                release_title: "FIND YOUR LOVE",
                release_label: { label_name: "Futurising Music" },
                release_artists: [{ artist_name: "Harshil Kamdar" }, { artist_name: "Bigtopo" }],
              }}
            />
          </div>
          <div className="col-xxs-6 col-xs-6 col-12 col-sm-6 col-lg-4 col-xxl-3">
            <ReleaseCard
              release={{
                release_key: "123456789",
                release_deleted_at: null,
                release_image: Images.fm004Art,
                is_pre_order: false,
                is_from_top_five: true,
                release_title: "VISION",
                release_label: { label_name: "Futurising Music" },
                release_artists: [{ artist_name: "LKX" }],
              }}
            />
          </div>
          <div className="col-xxs-6 col-xs-6 col-12 col-sm-6 col-lg-4 col-xxl-3">
            <ReleaseCard
              release={{
                release_key: "123456789",
                release_deleted_at: null,
                release_image: Images.fm005Art,
                is_pre_order: false,
                is_from_top_five: true,
                release_title: "TALK TO YOU",
                release_label: { label_name: "Futurising Music" },
                release_artists: [{ artist_name: "Ahmed Helmy" }],
              }}
            />
          </div>
          <div className="col-xxs-6 col-xs-6 col-12 col-sm-6 col-lg-4 col-xxl-3">
            <ReleaseCard
              release={{
                release_key: "123456789",
                release_deleted_at: null,
                release_image: Images.fm006Art,
                is_pre_order: false,
                is_from_top_five: false,
                release_title: "GLITCH",
                release_label: { label_name: "Futurising Music" },
                release_artists: [{ artist_name: "Ahmed Helmy" }, { artist_name: "Mavros" }, { artist_name: "LKX" }],
              }}
            />
          </div>
          <div className="col-xxs-6 col-xs-6 col-12 col-sm-6 col-lg-4 col-xxl-3">
            <ReleaseCard
              release={{
                release_key: "123456789",
                release_deleted_at: null,
                release_image: Images.fm007Art,
                is_pre_order: false,
                is_from_top_five: false,
                release_title: "SOUL EMOTIONS",
                release_label: { label_name: "Futurising Music" },
                release_artists: [{ artist_name: "Bigtopo" }, { artist_name: "LKX" }],
              }}
            />
          </div>
          <div className="col-xxs-6 col-xs-6 col-12 col-sm-6 col-lg-4 col-xxl-3">
            <ReleaseCard
              release={{
                release_key: "123456789",
                release_deleted_at: null,
                release_image: Images.fm008Art,
                is_pre_order: false,
                is_from_top_five: true,
                release_title: "NIGHT FURY",
                release_label: { label_name: "Futurising Music" },
                release_artists: [{ artist_name: "Azael" }],
              }}
            />
          </div>
          <div className="col-xxs-6 col-xs-6 col-12 col-sm-6 col-lg-4 col-xxl-3">
            <ReleaseCard
              release={{
                release_key: "123456789",
                release_deleted_at: null,
                release_image: Images.fm001Art,
                is_pre_order: false,
                is_from_top_five: true,
                release_title: "KING'S OUTLAW",
                release_label: { label_name: "Futurising Music" },
                release_artists: [{ artist_name: "Ahmed Helmy" }],
              }}
            />
          </div>
          <div className="col-xxs-6 col-xs-6 col-12 col-sm-6 col-lg-4 col-xxl-3">
            <ReleaseCard
              release={{
                release_key: "123456789",
                release_deleted_at: null,
                release_image: Images.fm002Art,
                is_pre_order: false,
                is_from_top_five: false,
                release_title: "YOU ARE REAL",
                release_label: { label_name: "Futurising Music" },
                release_artists: [{ artist_name: "Ahmed Helmy" }, { artist_name: "Bigtopo" }],
              }}
            />
          </div>
          <div className="col-xxs-6 col-xs-6 col-12 col-sm-6 col-lg-4 col-xxl-3">
            <ReleaseCard
              release={{
                release_key: "123456789",
                release_deleted_at: null,
                release_image: Images.fm003Art,
                is_pre_order: false,
                is_from_top_five: false,
                release_title: "FIND YOUR LOVE",
                release_label: { label_name: "Futurising Music" },
                release_artists: [{ artist_name: "Harshil Kamdar" }, { artist_name: "Bigtopo" }],
              }}
            />
          </div>
          <div className="col-xxs-6 col-xs-6 col-12 col-sm-6 col-lg-4 col-xxl-3">
            <ReleaseCard
              release={{
                release_key: "123456789",
                release_deleted_at: null,
                release_image: Images.fm004Art,
                is_pre_order: false,
                is_from_top_five: true,
                release_title: "VISION",
                release_label: { label_name: "Futurising Music" },
                release_artists: [{ artist_name: "LKX" }],
              }}
            />
          </div>
          <div className="col-xxs-6 col-xs-6 col-12 col-sm-6 col-lg-4 col-xxl-3">
            <ReleaseCard
              release={{
                release_key: "123456789",
                release_deleted_at: null,
                release_image: Images.fm005Art,
                is_pre_order: false,
                is_from_top_five: true,
                release_title: "TALK TO YOU",
                release_label: { label_name: "Futurising Music" },
                release_artists: [{ artist_name: "Ahmed Helmy" }],
              }}
            />
          </div>
          <div className="col-xxs-6 col-xs-6 col-12 col-sm-6 col-lg-4 col-xxl-3">
            <ReleaseCard
              release={{
                release_key: "123456789",
                release_deleted_at: null,
                release_image: Images.fm006Art,
                is_pre_order: false,
                is_from_top_five: false,
                release_title: "GLITCH",
                release_label: { label_name: "Futurising Music" },
                release_artists: [{ artist_name: "Ahmed Helmy" }, { artist_name: "Mavros" }, { artist_name: "LKX" }],
              }}
            />
          </div>
          <div className="col-xxs-6 col-xs-6 col-12 col-sm-6 col-lg-4 col-xxl-3">
            <ReleaseCard
              release={{
                release_key: "123456789",
                release_deleted_at: null,
                release_image: Images.fm007Art,
                is_pre_order: false,
                is_from_top_five: false,
                release_title: "SOUL EMOTIONS",
                release_label: { label_name: "Futurising Music" },
                release_artists: [{ artist_name: "Bigtopo" }, { artist_name: "LKX" }],
              }}
            />
          </div>
          <div className="col-xxs-6 col-xs-6 col-12 col-sm-6 col-lg-4 col-xxl-3">
            <ReleaseCard
              release={{
                release_key: "123456789",
                release_deleted_at: null,
                release_image: Images.fm008Art,
                is_pre_order: false,
                is_from_top_five: true,
                release_title: "NIGHT FURY",
                release_label: { label_name: "Futurising Music" },
                release_artists: [{ artist_name: "Azael" }],
              }}
            />
          </div>
          <div className="col-xxs-6 col-xs-6 col-12 col-sm-6 col-lg-4 col-xxl-3">
            <ReleaseCard
              release={{
                release_key: "123456789",
                release_deleted_at: null,
                release_image: Images.fm001Art,
                is_pre_order: false,
                is_from_top_five: true,
                release_title: "KING'S OUTLAW",
                release_label: { label_name: "Futurising Music" },
                release_artists: [{ artist_name: "Ahmed Helmy" }],
              }}
            />
          </div>
          <div className="col-xxs-6 col-xs-6 col-12 col-sm-6 col-lg-4 col-xxl-3">
            <ReleaseCard
              release={{
                release_key: "123456789",
                release_deleted_at: null,
                release_image: Images.fm002Art,
                is_pre_order: false,
                is_from_top_five: false,
                release_title: "YOU ARE REAL",
                release_label: { label_name: "Futurising Music" },
                release_artists: [{ artist_name: "Ahmed Helmy" }, { artist_name: "Bigtopo" }],
              }}
            />
          </div>
          <div className="col-xxs-6 col-xs-6 col-12 col-sm-6 col-lg-4 col-xxl-3">
            <ReleaseCard
              release={{
                release_key: "123456789",
                release_deleted_at: null,
                release_image: Images.fm003Art,
                is_pre_order: false,
                is_from_top_five: false,
                release_title: "FIND YOUR LOVE",
                release_label: { label_name: "Futurising Music" },
                release_artists: [{ artist_name: "Harshil Kamdar" }, { artist_name: "Bigtopo" }],
              }}
            />
          </div>
          <div className="col-xxs-6 col-xs-6 col-12 col-sm-6 col-lg-4 col-xxl-3">
            <ReleaseCard
              release={{
                release_key: "123456789",
                release_deleted_at: null,
                release_image: Images.fm004Art,
                is_pre_order: false,
                is_from_top_five: true,
                release_title: "VISION",
                release_label: { label_name: "Futurising Music" },
                release_artists: [{ artist_name: "LKX" }],
              }}
            />
          </div>
          <div className="col-xxs-6 col-xs-6 col-12 col-sm-6 col-lg-4 col-xxl-3">
            <ReleaseCard
              release={{
                release_key: "123456789",
                release_deleted_at: null,
                release_image: Images.fm005Art,
                is_pre_order: false,
                is_from_top_five: true,
                release_title: "TALK TO YOU",
                release_label: { label_name: "Futurising Music" },
                release_artists: [{ artist_name: "Ahmed Helmy" }],
              }}
            />
          </div>
          <div className="col-xxs-6 col-xs-6 col-12 col-sm-6 col-lg-4 col-xxl-3">
            <ReleaseCard
              release={{
                release_key: "123456789",
                release_deleted_at: null,
                release_image: Images.fm006Art,
                is_pre_order: false,
                is_from_top_five: false,
                release_title: "GLITCH",
                release_label: { label_name: "Futurising Music" },
                release_artists: [{ artist_name: "Ahmed Helmy" }, { artist_name: "Mavros" }, { artist_name: "LKX" }],
              }}
            />
          </div>
          <div className="col-xxs-6 col-xs-6 col-12 col-sm-6 col-lg-4 col-xxl-3">
            <ReleaseCard
              release={{
                release_key: "123456789",
                release_deleted_at: null,
                release_image: Images.fm007Art,
                is_pre_order: false,
                is_from_top_five: false,
                release_title: "SOUL EMOTIONS",
                release_label: { label_name: "Futurising Music" },
                release_artists: [{ artist_name: "Bigtopo" }, { artist_name: "LKX" }],
              }}
            />
          </div>
          <div className="col-xxs-6 col-xs-6 col-12 col-sm-6 col-lg-4 col-xxl-3">
            <ReleaseCard
              release={{
                release_key: "123456789",
                release_deleted_at: null,
                release_image: Images.fm008Art,
                is_pre_order: false,
                is_from_top_five: true,
                release_title: "NIGHT FURY",
                release_label: { label_name: "Futurising Music" },
                release_artists: [{ artist_name: "Azael" }],
              }}
            />
          </div>
        </div>
      </div> */}
    </>
  );
};

export default Music;
