import React, { createContext, useState } from "react";

export let fmVideosContext = createContext(0);

const VideosContext = ({ children }) => {
  let [filtrationDetails, setFiltrationDetails] = useState({
    search_query: "",
    label_keys: [],
    genre_keys: [],
    artist_keys: [],
  });

  let [isFiltrationLoading, setIsFiltrationLoading] = useState(false);

  let fmVideosLifting = {
    filtrationDetails,
    setFiltrationDetails,
    isFiltrationLoading,
    setIsFiltrationLoading,
  };
  return (
    <>
      <fmVideosContext.Provider value={{ ...fmVideosLifting }}>{children}</fmVideosContext.Provider>
    </>
  );
};

export default VideosContext;
