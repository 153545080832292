import React, { useContext, useEffect, useRef, useState } from "react";
import { fmDataContext } from "../../Context/FmData";
import { fmArtistsContext } from "../../Context/ArtistsContext";
import Pagination from "../Pagination/Pagination";
import LoadingScreen from "../LoadingScreen/LoadingScreen";
import LoadingCircle from "../LoadingCircle/LoadingCircle";
import ArtistsFiltration from "./ArtistsFiltration";
import ArtistCard from "../ArtistCard/ArtistCard";
import { Helmet } from "react-helmet-async";

const Artists = () => {
  const isCalledRef = useRef(false);

  let { baseUrl, navigate, getData, getAllDataWithoutPaginate, isMainDataLoading } = useContext(fmDataContext);

  let { filtrationDetails, isFiltrationLoading, setFiltrationDetails } = useContext(fmArtistsContext);

  let [artists, setArtists] = useState({});
  let [labels, setLabels] = useState([]);

  let containerRef = useRef();

  let [offsetElement, setOffsetElement] = useState({});

  let [isGetArtistsLoading, setIsGetArtistsLoading] = useState(true);
  let [isGetLabelsLoading, setIsGetLabelsLoading] = useState(true);

  let [isPaginateLoading, setIsPaginateLoading] = useState(false);

  let [pageNum, setPageNum] = useState(1);

  let perPage = 20;
  let orderBy = "id";
  let orderType = "asc";

  const getArtists = (reqMethod, pathName, params, setLoading = setIsGetArtistsLoading, message = null) => {
    getData(reqMethod, pathName, params, setLoading)
      .then((response) => {
        // ------------------- REMOVE BEFORE PRODUCTION -----------------------------------
        // if (response.data.data) {
        //   response.data.data.forEach((artist) => {
        //     if (artist.artist_image.includes("via.placeholder.com")) {
        //       artist.artist_image = artist.artist_image.replace("http://127.0.0.1:8000/storage/", "");
        //     }
        //   });
        // }
        // if (response.data.page_header && response.data.page_header.image.includes("via.placeholder.com")) {
        //   response.data.page_header.image = response.data.page_header.image.replace("http://127.0.0.1:8000/storage/", "");
        // }
        // ------------------- REMOVE BEFORE PRODUCTION -----------------------------------
        setArtists(response.data);
        setLoading(false);
      })
      .catch((error) => {
        navigate("/not-found");
      });
  };

  const getAllLabels = (reqMethod, pathName, params, setLoading = setIsGetLabelsLoading) => {
    getAllDataWithoutPaginate(reqMethod, pathName, params, setLoading)
      .then((response) => {
        setLoading(false);
        setLabels(response.data.data);
      })
      .catch((error) => {
        navigate("/not-found");
      });
  };

  useEffect(() => {
    if (!isCalledRef.current) {
      getArtists(
        "GET",
        `${baseUrl}fm-artists`,
        {
          page_num: pageNum,
          per_page: perPage,
          order_type: orderType,
          order_by: orderBy,
        },
        setIsGetArtistsLoading
      );
      getAllLabels("GET", `${baseUrl}fm-all-labels`, null, setIsGetLabelsLoading);
    }

    return () => {
      isCalledRef.current = true;
      setFiltrationDetails({
        search_query: "",
        label_keys: [],
        main_artist: null,
        top_five_artists: null,
      });
    };
  }, []);

  useEffect(() => {
    if (containerRef.current) {
      setOffsetElement(containerRef);
    }
  }, [containerRef.current, isGetArtistsLoading, isGetLabelsLoading, isMainDataLoading]);

  return (
    <>
      <Helmet>
        <title>Futurising Music - Artists</title>
        <link rel="canonical" href={`${window.location.href}`} />
      </Helmet>
      {isGetArtistsLoading || isGetLabelsLoading || isMainDataLoading ? (
        <LoadingScreen />
      ) : (
        <>
          <div className="min-vh-100">
            {artists.page_header !== null ? (
              <div className="page-heading">
                <div className="heading-img-container" style={{ backgroundImage: `url(${artists.page_header.image})` }} />
              </div>
            ) : (
              ""
            )}

            <div className="container position-relative pt-4 min-vh-100" ref={containerRef}>
              <div className="mb-3 mb-sm-4 mb-xl-5">
                <h1 className="page-heading-title mb-0">ARTISTS</h1>
              </div>
              <div className="mb-5">
                <ArtistsFiltration labels={labels} getArtists={getArtists} perPage={perPage} orderBy={orderBy} orderType={orderType} />
              </div>

              {isPaginateLoading ? (
                <div className="d-flex justify-content-center mt-5 pt-5 pb-5 mb-5">
                  <div className="position-relative mb-5 pb-5">
                    <LoadingCircle circleCont={85} icon={80} loader={85} dot={85} />
                  </div>
                </div>
              ) : (
                <>
                  {artists.data && artists.data.length > 0 ? (
                    <div className="row g-2 g-md-3">
                      {artists.data.map((artist, index) => (
                        <div className="col-vs-6 col-xxxs-6 col-xxs-4 col-xs-4 col-sm-4 col-md-3 fu-col-lg-20 fu-col-xl-20 col-xxl-2" key={index}>
                          <ArtistCard artist={artist} />
                        </div>
                      ))}
                    </div>
                  ) : artists.data && artists.data.length === 0 && filtrationDetails.search_query === "" && filtrationDetails.label_keys.length === 0 && filtrationDetails.main_artist === null && filtrationDetails.top_five_artists === null && !isFiltrationLoading ? (
                    <div className="d-flex flex-column justify-content-center align-items-center mt-5 pt-5 item-msg">
                      <div>
                        <h2 className="fu-text-3-rem fu-text-yellow">
                          <i className="bi bi-info-circle"></i>
                        </h2>
                      </div>
                      <div>
                        <h3 className="h5 fu-text-light-gray">There Are No Artists In Our Artist List, Come Back Later</h3>
                      </div>
                    </div>
                  ) : !isFiltrationLoading ? (
                    <div className="d-flex flex-column justify-content-center align-items-center mt-5 pt-5 pb-5 mb-5 item-msg">
                      <div>
                        <h2 className="fu-text-3-rem fu-text-yellow">
                          <i className="bi bi-search"></i>
                        </h2>
                      </div>
                      <div>
                        <h3 className="h5 fu-text-light-gray">There are no artists matching your search</h3>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="mt-5">{artists ? artists.meta && artists.meta.last_page !== 1 ? <Pagination offsetElement={offsetElement} pages={artists.meta.links} pagination={artists.meta} getItems={getArtists} pathName={"fm-artists"} filtrationDetails={filtrationDetails} setIsPaginateLoading={setIsPaginateLoading} setPageNum={setPageNum} per_page={perPage} order_by={orderBy} order_type={orderType} /> : "" : ""}</div>
                </>
              )}
            </div>
          </div>
        </>
      )}

      {/* <div className="page-heading">
        <div className="heading-img-container" style={{ backgroundImage: `url(${Images.testHeading})` }} />
      </div>
      <div className="container position-relative pt-4">
        <div className="mb-3 mb-sm-4 mb-xl-5">
          <h1 className="page-heading-title mb-0">ARTISTS</h1>
        </div>
        <div className="mb-5">
          <ArtistsFiltration labels={labels} getArtists={getArtists} perPage={perPage} orderBy={orderBy} orderType={orderType} />
        </div>
        <div className="row g-2 g-md-3">
          {Array(24)
            .fill(1)
            .map((key, index) => (
              <div className="col-vs-6 col-xxxs-6 col-xxs-4 col-xs-4 col-sm-4 col-md-3 fu-col-lg-20 fu-col-xl-20 col-xxl-2" key={index}>
                <ArtistCard
                  artist={{
                    artist_key: "12346789",
                    artist_image: Images.artist,
                    is_verified: true,
                    is_from_top_five: true,
                    artist_name: "Ahmed Helmy",
                    order_in_top_five: 1,
                  }}
                />
              </div>
            ))}
        </div>
      </div> */}
    </>
  );
};

export default Artists;
