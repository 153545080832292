import React, { useEffect, useState } from "react";
import Navbar from "../Navbar/Navbar";
import { Outlet } from "react-router-dom";
import Footer from "../Footer/Footer";
import ScrollLayoutTop from "../ScrollLayoutTop/ScrollLayoutTop";
import MobileNavbar from "../MobileNavbar/MobileNavbar";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import BreadcrumbBar from "../BreadcrumbBar/BreadcrumbBar";

const MasterLayout = () => {
  let [windowWidth, setWindowWidth] = useState(0);

  useEffect(() => {
    setWindowWidth(window.innerWidth);
    window.addEventListener("resize", function () {
      setWindowWidth(window.innerWidth);
    });
  }, []);

  return (
    <>
      <ScrollLayoutTop>
        {windowWidth >= 1250 ? <Navbar /> : ""}
        <div>
          <div className="layout-container position-relative">
            <div className="toast-msg-container-parent">
              <ToastContainer className="toast-msg-container" />
            </div>
            {windowWidth >= 1250 ? (
              <div>
                <div className="virtual-nav-width"></div>
              </div>
            ) : windowWidth !== 0 ? (
              <MobileNavbar />
            ) : (
              ""
            )}
            <div className="flex-grow-1 test-center outlet">
              <div className="min-vh-100">
                <div className="fu-bg-black breadcrumb-bar">
                  <div className="container">
                    <div>
                      <BreadcrumbBar />
                    </div>
                  </div>
                </div>
                <Outlet></Outlet>
                <Footer />
                {windowWidth >= 1250 ? "" : windowWidth !== 0 ? <div className="virtual-mobile-bar-height"></div> : ""}
              </div>
            </div>
          </div>
        </div>
      </ScrollLayoutTop>
      {/* <div className="d-flex">
        <div className="virtual-nav-width"></div>
      </div> */}
    </>
  );
};

export default MasterLayout;
