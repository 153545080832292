import React, { useContext, useEffect, useState } from "react";
import { fmDataContext } from "../../Context/FmData";
import LoadingScreen from "../LoadingScreen/LoadingScreen";
import { Helmet } from "react-helmet-async";

const PrivacyPolicy = () => {
  let { baseUrl, navigate, getData, isMainDataLoading } = useContext(fmDataContext);

  let [privacyPolicy, setPrivacyPolicy] = useState({});

  let [isGetprivacyPolicyLoading, setIsGetprivacyPolicyLoading] = useState(true);

  const getprivacyPolicy = (reqMethod, pathName, params = null, setLoading = setIsGetprivacyPolicyLoading) => {
    getData(reqMethod, pathName, params, setLoading)
      .then((response) => {
        // ------------------- REMOVE BEFORE PRODUCTION -----------------------------------
        // if (response.data.page_header && response.data.page_header.image.includes("via.placeholder.com")) {
        //   response.data.page_header.image = response.data.page_header.image.replace("http://127.0.0.1:8000/storage/", "");
        // }
        // ------------------- REMOVE BEFORE PRODUCTION -----------------------------------
        setPrivacyPolicy(response.data);
        setLoading(false);
      })
      .catch((error) => {
        navigate("/not-found");
      });
  };

  useEffect(() => {
    getprivacyPolicy("GET", `${baseUrl}fm-privacy-policies`, null, setIsGetprivacyPolicyLoading);
  }, []);

  return (
    <>
      <Helmet>
        <title>Privacy Policy</title>
        <link rel="canonical" href={`${window.location.href}`} />
        <meta name="robots" content="noindex" />
      </Helmet>
      {isMainDataLoading || isGetprivacyPolicyLoading ? (
        <LoadingScreen />
      ) : (
        <>
          {privacyPolicy.page_header ? (
            <div className="page-heading">
              <div className="heading-img-container" style={{ backgroundImage: `url(${privacyPolicy.page_header.image})` }} />
            </div>
          ) : (
            ""
          )}
          <div className="container position-relative min-vh-100 pt-4">
            <div className="mb-3 mb-sm-4 mb-xl-4">
              <h1 className="page-heading-long-title mb-0">PRIVACY POLICY</h1>
            </div>
            {privacyPolicy.data.map((privacyPolicyDesc, index) => (
              <div key={index} className="privacy-policies-description" dangerouslySetInnerHTML={{ __html: privacyPolicyDesc.description }} />
            ))}
          </div>
        </>
      )}
    </>
  );
};

export default PrivacyPolicy;
