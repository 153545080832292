import React, { useContext } from "react";
import { fmDataContext } from "../../Context/FmData";

export default function Pagination(props) {
  let { offsetElement, pages, pagination, getItems, pathName, filtrationDetails, order_by, order_type, per_page, setPageNum, setIsPaginateLoading } = props;
  let { baseUrl } = useContext(fmDataContext);

  return (
    <>
      <div className="d-flex justify-content-center align-items-center">
        <div className="d-flex">
          {pages.map((page, index) =>
            page.label.includes("Previous") ? (
              <button
                className={`me-2 pagination-prev ${page.url === null ? "disabled opacity-50" : "opacity-100"}`}
                disabled={page.url === null ? true : false}
                onClick={() => {
                  if (page.url !== null) {
                    document.documentElement.scrollTo(0, offsetElement ? offsetElement.current.offsetTop : 0);
                    getItems(
                      "GET",
                      `${baseUrl}${pathName}`,
                      {
                        page_num: --pagination.current_page,
                        per_page,
                        order_type,
                        order_by,
                        ...filtrationDetails,
                      },
                      setIsPaginateLoading
                    );
                    setPageNum(--pagination.current_page);
                  }
                }}
                key={index}>
                <i className="bi bi-arrow-left-circle fu-text-white"></i>
              </button>
            ) : page.label.includes("Next") ? (
              <button
                className={`ms-2 pagination-next ${page.url === null ? "disabled opacity-50" : "opacity-100"}`}
                disabled={page.url === null ? true : false}
                onClick={() => {
                  if (page.url !== null) {
                    document.documentElement.scrollTo(0, offsetElement ? offsetElement.current.offsetTop : 0);
                    getItems(
                      "GET",
                      `${baseUrl}${pathName}`,
                      {
                        page_num: ++pagination.current_page,
                        per_page,
                        order_type,
                        order_by,
                        ...filtrationDetails,
                      },
                      setIsPaginateLoading
                    );
                    setPageNum(++pagination.current_page);
                  }
                }}
                key={index}>
                <i className="bi bi-arrow-right-circle fu-text-white"></i>
              </button>
            ) : (
              <button
                key={index}
                className={`pagination-circle mx-1 border-0 ${page.url === null ? "disabled" : ""} ${page.active ? "active-pagination-circle" : ""}`}
                disabled={page.url === null ? true : false}
                onClick={() => {
                  if (page.url !== null) {
                    document.documentElement.scrollTo(0, offsetElement ? offsetElement.current.offsetTop : 0);
                    getItems(
                      "GET",
                      `${baseUrl}${pathName}`,
                      {
                        page_num: page.label,
                        per_page,
                        order_type,
                        order_by,
                        ...filtrationDetails,
                      },
                      setIsPaginateLoading
                    );
                    setPageNum(page.label);
                  }
                }}>
                <span className="pagination-circle-inner">{page.label}</span>
              </button>
            )
          )}
        </div>
      </div>
    </>
  );
}
