import React, { useContext } from "react";
import Reveal from "../Reveal/Reveal";
import { fmDataContext } from "../../Context/FmData";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

const LabelCard = ({ label }) => {
  let { navigate } = useContext(fmDataContext);

  return (
    <div className="h-100">
      <Reveal>
        <div className="label-box" onClick={() => navigate(`/labels/${label.label_key}`)}>
          <div className="label-img-container mb-3">
            <div className="label-img">
              <LazyLoadImage loading="lazy" effect="blur" src={label.label_image} alt="company icon" className="w-100 h-100" />
              {/* <img src={label.label_image} alt="label" className="w-100 h-100" /> */}
            </div>
          </div>
          <div className="label-name-container">
            <h2 className="label-name">{label.label_name}</h2>
          </div>
        </div>
      </Reveal>
    </div>
  );
};

export default LabelCard;
