import React, { useContext, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { fmDataContext } from "../../Context/FmData";

const BreadcrumbBar = () => {
  let { globalCrumbs, setGlobalCrumbs } = useContext(fmDataContext);
  const location = useLocation();
  const crumbs = location.pathname
    .split("/")
    .map((pathSegment, index) => {
      if (index === 0) {
        return {
          status: "",
          url: "/",
          name: "HOME",
        };
      } else if (index > 0 && pathSegment !== "") {
        return {
          status: "",
          url: location.pathname
            .split("/")
            .map((pathSegment) => (pathSegment === "" ? "" : `/${pathSegment}`))
            .splice(0, pathSegment === "search" ? index + 2 : index + 1)
            .join(""),
          name: decodeURIComponent(pathSegment).toUpperCase(),
        };
      } else {
        return null;
      }
    })
    .filter((crumb) => crumb !== null);
  crumbs.map((crumb, index) => (index === crumbs.length - 1 ? (crumb.status = "active") : (crumb.status = "inactive")));
  crumbs.map((crumb, index) => ((index === crumbs.length - 1 || index === crumbs.length - 2) && location.search !== null ? (crumb.url = crumb.url.concat(location.search)) : (crumb.url = crumb.url)));

  useEffect(() => {
    setGlobalCrumbs(crumbs);
  }, [location.pathname]);

  return (
    <>
      {location.pathname !== "/" ? (
        <div className="fu-breadcrumb py-1">
          <div>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb mb-0">
                {globalCrumbs.map((crumb, index) => (
                  <li className={`breadcrumb-item fu-breadcrumb-item ${crumb.status === "active" ? "fu-breadcrumb-item-active" : ""}`} key={index}>
                    <Link to={crumb.url} className="fu-link-text">
                      {crumb.name.length > 20 ? `${crumb.name.slice(0, 20)}...` : crumb.name}
                    </Link>
                  </li>
                ))}
              </ol>
            </nav>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default BreadcrumbBar;
