import React, { useContext } from "react";
import { Helmet } from "react-helmet-async";
import styles from "./NotFound.module.scss";
import { Link } from "react-router-dom";
import { fmDataContext } from "../../Context/FmData";
import LoadingScreen from "../LoadingScreen/LoadingScreen";

const NotFound = () => {
  let { isMainDataLoading } = useContext(fmDataContext);
  return (
    <>
      <Helmet>
        <title>404 - Not Found</title>
        <link rel="canonical" href={`${window.location.href}`} />
        <meta name="robots" content="noindex" />
      </Helmet>
      <div className="container">
        <div className="custom-height d-flex align-items-center w-100">
          <div className="row align-items-center justify-content-center flex-grow-1">
            <div className="col-12">
              <div>
                <h1 className={`mb-0 mb-md-1 mb-xl-2 ${styles.code}`}>404</h1>
                <h2 className={styles.oops}>OOPS! PAGE NOT BE FOUND</h2>
              </div>
            </div>
            <div className="col-12 col-md-10 col-lg-8 col-xl-6">
              <div>
                <p className={styles.desc}>The page you are looking for might have been removed had its name changed or is temporarily unavailable</p>
                <div className="d-flex flex-wrap justify-content-center">
                  <div>
                    <Link to="/" className="fu-btn-sm fu-btn-gray m-2">
                      <span className="btn-bg"></span>
                      GO HOME
                    </Link>
                  </div>
                  <div>
                    <Link to="/contact" className="fu-btn-sm fu-btn-gray m-2">
                      <span className="btn-bg"></span>
                      CONTACT US
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NotFound;
