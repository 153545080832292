import React, { useContext, useEffect, useRef, useState } from "react";
import { fmDataContext } from "../../Context/FmData";
import { fmVideosContext } from "../../Context/VideosContext";
import Pagination from "../Pagination/Pagination";
import VideoCard from "../VideoCard/VideoCard";
import LoadingScreen from "../LoadingScreen/LoadingScreen";
import LoadingCircle from "../LoadingCircle/LoadingCircle";
import VideosFiltration from "./VideosFiltration";
import { Helmet } from "react-helmet-async";

const Videos = () => {
  const isCalledRef = useRef(false);

  let { baseUrl, navigate, getData, getAllDataWithoutPaginate, isMainDataLoading } = useContext(fmDataContext);
  let { filtrationDetails, isFiltrationLoading, setFiltrationDetails } = useContext(fmVideosContext);

  let [videos, setVideos] = useState({});
  let [labels, setLabels] = useState([]);
  let [artists, setArtists] = useState([]);
  let [genres, setGenres] = useState([]);

  let containerRef = useRef();

  let [offsetElement, setOffsetElement] = useState({});

  let [isGetMediaBasiesLoading, setIsGetMediaBasiesLoading] = useState(true);

  let [isGetVideosLoading, setIsGetVideosLoading] = useState(true);

  let [isPaginateLoading, setIsPaginateLoading] = useState(false);

  let [pageNum, setPageNum] = useState(1);

  let perPage = 20;
  let orderBy = "id";
  let orderType = "desc";

  const getVideos = (reqMethod, pathName, params, setLoading = setIsGetVideosLoading, message = null) => {
    getData(reqMethod, pathName, params, setLoading)
      .then((response) => {
        if (response.data.page_header && response.data.page_header.image.includes("via.placeholder.com")) {
          response.data.page_header.image = response.data.page_header.image.replace("http://127.0.0.1:8000/storage/", "");
        }

        setVideos(response.data);
        setLoading(false);
      })
      .catch((error) => {
        navigate("/not-found");
      });
  };

  const getMediaBasies = (reqMethod, pathName, params, setLoading = setIsGetMediaBasiesLoading) => {
    getAllDataWithoutPaginate(reqMethod, pathName, params, setLoading)
      .then((response) => {
        setLabels(response.data.labels);
        setArtists(response.data.artists);
        setGenres(response.data.genres);
        setLoading(false);
      })
      .catch((error) => {
        navigate("/not-found");
      });
  };

  useEffect(() => {
    if (!isCalledRef.current) {
      getVideos(
        "GET",
        `${baseUrl}fm-videos`,
        {
          page_num: pageNum,
          per_page: perPage,
          order_type: orderType,
          order_by: orderBy,
        },
        setIsGetVideosLoading
      );
      getMediaBasies("GET", `${baseUrl}fm-get-tracks-data`, null, setIsGetMediaBasiesLoading);
    }

    return () => {
      isCalledRef.current = true;
      setFiltrationDetails({
        search_query: "",
        label_keys: [],
        genre_keys: [],
        artist_keys: [],
      });
    };
  }, []);

  useEffect(() => {
    if (containerRef.current) {
      setOffsetElement(containerRef);
    }
  }, [containerRef.current, isGetVideosLoading, isGetMediaBasiesLoading, isMainDataLoading]);

  return (
    <>
      <Helmet>
        <title>Futurising Music - Videos</title>
        <link rel="canonical" href={`${window.location.href}`} />
      </Helmet>
      {isGetVideosLoading || isGetMediaBasiesLoading || isMainDataLoading ? (
        <LoadingScreen />
      ) : (
        <>
          <div className="min-vh-100">
            {videos.page_header !== null ? (
              <div className="page-heading">
                <div className="heading-img-container" style={{ backgroundImage: `url(${videos.page_header.image})` }} />
              </div>
            ) : (
              ""
            )}

            <div className="container position-relative pt-4 min-vh-100" ref={containerRef}>
              <div className="mb-3 mb-sm-4 mb-xl-5">
                <h1 className="page-heading-title mb-0">VIDEOS</h1>
              </div>
              <div className="mb-5">
                <VideosFiltration labels={labels} artists={artists} genres={genres} getVideos={getVideos} perPage={perPage} orderBy={orderBy} orderType={orderType} />
              </div>
              {isPaginateLoading ? (
                <div className="d-flex justify-content-center mt-5 pt-5 pb-5 mb-5">
                  <div className="position-relative mb-5 pb-5">
                    <LoadingCircle circleCont={85} icon={80} loader={85} dot={85} />
                  </div>
                </div>
              ) : (
                <>
                  {videos.data && videos.data.length > 0 ? (
                    <div className="row g-3">
                      {videos.data.map((video, index) => (
                        <div className="col-12 col-lg-6" key={index}>
                          <VideoCard video={video} />
                        </div>
                      ))}
                    </div>
                  ) : videos.data && videos.data.length === 0 && filtrationDetails.search_query === "" && filtrationDetails.label_keys.length === 0 && filtrationDetails.genre_keys.length === 0 && filtrationDetails.artist_keys.length === 0 && !isFiltrationLoading ? (
                    <div className="d-flex flex-column justify-content-center align-items-center mt-5 pt-5 item-msg">
                      <div>
                        <h2 className="fu-text-3-rem fu-text-yellow">
                          <i className="bi bi-info-circle"></i>
                        </h2>
                      </div>
                      <div>
                        <h3 className="h5 fu-text-light-gray">There Are No track videos In Our Track videos List, Come Back Later</h3>
                      </div>
                    </div>
                  ) : !isFiltrationLoading ? (
                    <div className="d-flex flex-column justify-content-center align-items-center mt-5 pt-5 pb-5 mb-5 item-msg">
                      <div>
                        <h2 className="fu-text-3-rem fu-text-yellow">
                          <i className="bi bi-search"></i>
                        </h2>
                      </div>
                      <div>
                        <h3 className="h5 fu-text-light-gray">There are no track videos matching your search</h3>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="mt-5">{videos ? videos.meta && videos.meta.last_page !== 1 ? <Pagination offsetElement={offsetElement} pages={videos.meta.links} pagination={videos.meta} getItems={getVideos} pathName={"fm-videos"} filtrationDetails={filtrationDetails} setIsPaginateLoading={setIsPaginateLoading} setPageNum={setPageNum} per_page={perPage} order_by={orderBy} order_type={orderType} /> : "" : ""}</div>
                </>
              )}
            </div>
          </div>
        </>
      )}
      {/* <div className="page-heading">
        <div className="heading-img-container" style={{ backgroundImage: `url(${Images.testHeading})` }} />
      </div>
      <div className="container position-relative pt-4">
        <div className="mb-3 mb-sm-4 mb-xl-5">
          <h1 className="page-heading-title mb-0">VIDEOS</h1>
        </div>
        <div className="mb-5">
          <VideosFiltration labels={labels} artists={artists} genres={genres} getVideos={getVideos} perPage={perPage} orderBy={orderBy} orderType={orderType} />
        </div>
        <div className="row g-3">
          <div className="col-12 col-lg-6">
            <VideoCard
              video={{
                youtube_video_id: "OxRjGxFpCbo",
                video_title: "Ahmed Helmy - King's Outlaw",
                video_label: { label_name: "Futurising Music" },
                video_artists: [{ artist_name: "Ahmed Helmy" }],
              }}
            />
          </div>
          <div className="col-12 col-lg-6">
            <VideoCard
              video={{
                youtube_video_id: "OxRjGxFpCbo",
                video_title: "Ahmed Helmy - King's Outlaw",
                video_label: { label_name: "Futurising Music" },
                video_artists: [{ artist_name: "Ahmed Helmy" }],
              }}
            />
          </div>
          <div className="col-12 col-lg-6">
            <VideoCard
              video={{
                youtube_video_id: "OxRjGxFpCbo",
                video_title: "Ahmed Helmy - King's Outlaw",
                video_label: { label_name: "Futurising Music" },
                video_artists: [{ artist_name: "Ahmed Helmy" }],
              }}
            />
          </div>
          <div className="col-12 col-lg-6">
            <VideoCard
              video={{
                youtube_video_id: "OxRjGxFpCbo",
                video_title: "Ahmed Helmy - King's Outlaw",
                video_label: { label_name: "Futurising Music" },
                video_artists: [{ artist_name: "Ahmed Helmy" }],
              }}
            />
          </div>
          <div className="col-12 col-lg-6">
            <VideoCard
              video={{
                youtube_video_id: "OxRjGxFpCbo",
                video_title: "Ahmed Helmy - King's Outlaw",
                video_label: { label_name: "Futurising Music" },
                video_artists: [{ artist_name: "Ahmed Helmy" }],
              }}
            />
          </div>
          <div className="col-12 col-lg-6">
            <VideoCard
              video={{
                youtube_video_id: "OxRjGxFpCbo",
                video_title: "Ahmed Helmy - King's Outlaw",
                video_label: { label_name: "Futurising Music" },
                video_artists: [{ artist_name: "Ahmed Helmy" }],
              }}
            />
          </div>
          <div className="col-12 col-lg-6">
            <VideoCard
              video={{
                youtube_video_id: "OxRjGxFpCbo",
                video_title: "Ahmed Helmy - King's Outlaw",
                video_label: { label_name: "Futurising Music" },
                video_artists: [{ artist_name: "Ahmed Helmy" }],
              }}
            />
          </div>
          <div className="col-12 col-lg-6">
            <VideoCard
              video={{
                youtube_video_id: "OxRjGxFpCbo",
                video_title: "Ahmed Helmy - King's Outlaw",
                video_label: { label_name: "Futurising Music" },
                video_artists: [{ artist_name: "Ahmed Helmy" }],
              }}
            />
          </div>
          <div className="col-12 col-lg-6">
            <VideoCard
              video={{
                youtube_video_id: "OxRjGxFpCbo",
                video_title: "Ahmed Helmy - King's Outlaw",
                video_label: { label_name: "Futurising Music" },
                video_artists: [{ artist_name: "Ahmed Helmy" }],
              }}
            />
          </div>
          <div className="col-12 col-lg-6">
            <VideoCard
              video={{
                youtube_video_id: "OxRjGxFpCbo",
                video_title: "Ahmed Helmy - King's Outlaw",
                video_label: { label_name: "Futurising Music" },
                video_artists: [{ artist_name: "Ahmed Helmy" }],
              }}
            />
          </div>
          <div className="col-12 col-lg-6">
            <VideoCard
              video={{
                youtube_video_id: "OxRjGxFpCbo",
                video_title: "Ahmed Helmy - King's Outlaw",
                video_label: { label_name: "Futurising Music" },
                video_artists: [{ artist_name: "Ahmed Helmy" }],
              }}
            />
          </div>
          <div className="col-12 col-lg-6">
            <VideoCard
              video={{
                youtube_video_id: "OxRjGxFpCbo",
                video_title: "Ahmed Helmy - King's Outlaw",
                video_label: { label_name: "Futurising Music" },
                video_artists: [{ artist_name: "Ahmed Helmy" }],
              }}
            />
          </div>
        </div>
      </div> */}
    </>
  );
};

export default Videos;
