import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { fmDataContext } from "../../Context/FmData";
import SearchResult from "../SearchResult/SearchResult";
import styles from "./Search.module.scss";
import LoadingScreen from "../LoadingScreen/LoadingScreen";
import LoadingCircle from "../LoadingCircle/LoadingCircle";
import Pagination from "../Pagination/Pagination";
import { Helmet } from "react-helmet-async";

const Search = () => {
  const isCalledRef = useRef(false);
  let [mounted, setMounted] = useState(false);
  let { baseUrl, getData, navigate, isMainDataLoading } = useContext(fmDataContext);

  let [searchParams, setSearchParams] = useSearchParams();
  let searchWords = searchParams.get("sq");

  let [isSearchLoading, setIsSearchLoading] = useState(true);

  let [isSearchItemsLoading, setIsSearchItemsLoading] = useState(false);
  let [isSearchItemsCountLoading, setIsSearchItemsCountLoading] = useState(false);

  let [isPaginateLoading, setIsPaginateLoading] = useState(false);

  let { searchObj } = useParams();

  let [searchitems, setSearchItems] = useState({});
  let [searchitemsCount, setSearchItemsCount] = useState({});

  let [pageNum, setPageNum] = useState(1);
  let perPage = 20;
  let orderBy = "id";
  let orderType = "desc";

  let availableObjects = ["releases", "videos", "artists", "labels", "news"];

  const getSearchItems = (reqMethod, pathName, params, setLoading = setIsSearchLoading) => {
    getData(reqMethod, pathName, params, setLoading)
      .then((response) => {
        // ------------------------------- REMOVE BEFORE PRODUCTION -----------------------
        // if (searchObj === "labels") {
        //   if (response.data.data) {
        //     response.data.data.forEach((label) => {
        //       if (label.label_image.includes("via.placeholder.com")) {
        //         label.label_image = label.label_image.replace("http://127.0.0.1:8000/storage/", "");
        //       }
        //     });
        //   }
        // }
        // if (searchObj === "releases") {
        //   if (response.data.data) {
        //     response.data.data.forEach((release) => {
        //       if (release.release_image.includes("via.placeholder.com")) {
        //         release.release_image = release.release_image.replace("http://127.0.0.1:8000/storage/", "");
        //       }
        //     });
        //   }
        // }
        // if (searchObj === "artists") {
        //   if (response.data.data) {
        //     response.data.data.forEach((artist) => {
        //       if (artist.artist_image.includes("via.placeholder.com")) {
        //         artist.artist_image = artist.artist_image.replace("http://127.0.0.1:8000/storage/", "");
        //       }
        //     });
        //   }
        // }
        // if (searchObj === "news") {
        //   if (response.data.data) {
        //     response.data.data.forEach((newBlog) => {
        //       if (newBlog.new_image.includes("via.placeholder.com")) {
        //         newBlog.new_image = newBlog.new_image.replace("http://127.0.0.1:8000/storage/", "");
        //       }
        //     });
        //   }
        // }
        // ------------------------------- REMOVE BEFORE PRODUCTION -----------------------
        setSearchItems(response.data);
        setLoading(false);
      })
      .catch((error) => {
        navigate("/not-found");
      });
  };

  const getSearchItemsCount = (reqMethod, pathName, params, setLoading = setIsSearchItemsCountLoading) => {
    getData(reqMethod, pathName, params, setLoading)
      .then((response) => {
        setSearchItemsCount(response.data);
        setLoading(false);
      })
      .catch((error) => {
        navigate("/not-found");
      });
  };

  useEffect(() => {
    if (!isCalledRef.current) {
      getSearchItems(
        "GET",
        `${baseUrl}fm-${searchObj}`,
        {
          page_num: pageNum,
          per_page: perPage,
          order_type: orderType,
          order_by: orderBy,
          search_query: searchWords,
        },
        setIsSearchLoading
      );
      getSearchItemsCount(
        "GET",
        `${baseUrl}fm-search-items-count`,
        {
          search_query: searchWords,
        },
        setIsSearchItemsCountLoading
      );
      setMounted(true);
    }

    if (!searchWords || searchWords === "" || !availableObjects.includes(searchObj)) {
      navigate("/not-found");
    }

    return () => {
      isCalledRef.current = true;
    };
  }, []);

  useEffect(() => {
    if (mounted) {
      setPageNum(1);
      getSearchItems(
        "GET",
        `${baseUrl}fm-${searchObj}`,
        {
          page_num: 1,
          per_page: perPage,
          order_type: orderType,
          order_by: orderBy,
          search_query: searchWords,
        },
        setIsSearchLoading
      );
      getSearchItemsCount(
        "GET",
        `${baseUrl}fm-search-items-count`,
        {
          search_query: searchWords,
        },
        setIsSearchItemsCountLoading
      );
    }
    if (!searchWords || searchWords === "" || !availableObjects.includes(searchObj)) {
      navigate("/not-found");
    }
  }, [searchWords]);

  useEffect(() => {
    if (mounted) {
      setPageNum(1);
      getSearchItems(
        "GET",
        `${baseUrl}fm-${searchObj}`,
        {
          page_num: 1,
          per_page: perPage,
          order_type: orderType,
          order_by: orderBy,
          search_query: searchWords,
        },
        setIsSearchItemsLoading
      );
    }
    if (!searchWords || searchWords === "" || !availableObjects.includes(searchObj)) {
      navigate("/not-found");
    }
  }, [searchObj]);

  return (
    <>
      <Helmet>
        <title>Futurising Music - Search</title>
        <link rel="canonical" href={`${window.location.href}`} />
        <meta name="robots" content="noindex" />
      </Helmet>
      {isSearchLoading || isMainDataLoading || isSearchItemsCountLoading ? (
        <LoadingScreen />
      ) : (
        <>
          <div className="container position-relative min-vh-100 pt-4">
            <div className="mb-1 mb-sm-2 mb-xl-2">
              <h1 className="page-heading-long-title mb-0">SEARCH RESULT</h1>
            </div>
            <h6 className={`mb-4 ${styles.search_info}`}>
              SEARCH RESULT FOR <span className="fu-text-yellow">"{`${searchWords.length > 15 ? searchWords.slice(0, 15) + "..." : searchWords}`.toUpperCase()}"</span> IN <span className="fu-text-yellow">{searchObj === "partnerships" ? "partners".toUpperCase() : searchObj.toUpperCase()}</span>
            </h6>
            <div className="row g-2 g-md-3">
              <div className="fu-col-vs-12 fu-col-xxxs-12 col-xxs-6 col-xs-6 col-sm-4 col-md-3 fu-col-lg-20 fu-col-xl-20 fu-col-xxl-20">
                <div className={styles.obj_btn_container}>
                  <Link to={`/search/releases?sq=${searchWords}`} className={styles.obj_link} disabled={isSearchItemsLoading ? true : false}>
                    <span className={`${styles.obj_btn} ${searchObj === "releases" ? styles.obj_btn_active : ""} ${isSearchItemsLoading ? "opacity-50" : "opacity-100"}`} onClick={() => searchObj !== "releases" && setIsSearchItemsLoading(true)}>
                      <span className={styles.obj_name}>Releases</span>
                      <span className={styles.obj_count}>{searchitemsCount.releases_count}</span>
                    </span>
                  </Link>
                </div>
              </div>
              <div className="fu-col-vs-12 fu-col-xxxs-12 col-xxs-6 col-xs-6 col-sm-4 col-md-3 fu-col-lg-20 fu-col-xl-20 fu-col-xxl-20">
                <div className={styles.obj_btn_container}>
                  <Link to={`/search/videos?sq=${searchWords}`} className={styles.obj_link} disabled={isSearchItemsLoading ? true : false}>
                    <span className={`${styles.obj_btn} ${searchObj === "videos" ? styles.obj_btn_active : ""} ${isSearchItemsLoading ? "opacity-50" : "opacity-100"}`} onClick={() => searchObj !== "videos" && setIsSearchItemsLoading(true)}>
                      <span className={styles.obj_name}>Videos</span>
                      <span className={styles.obj_count}>{searchitemsCount.videos_count}</span>
                    </span>
                  </Link>
                </div>
              </div>
              <div className="fu-col-vs-12 fu-col-xxxs-12 col-xxs-6 col-xs-6 col-sm-4 col-md-3 fu-col-lg-20 fu-col-xl-20 fu-col-xxl-20">
                <div className={styles.obj_btn_container}>
                  <Link to={`/search/artists?sq=${searchWords}`} className={styles.obj_link} disabled={isSearchItemsLoading ? true : false}>
                    <span className={`${styles.obj_btn} ${searchObj === "artists" ? styles.obj_btn_active : ""} ${isSearchItemsLoading ? "opacity-50" : "opacity-100"}`} onClick={() => searchObj !== "artists" && setIsSearchItemsLoading(true)}>
                      <span className={styles.obj_name}>Artists</span>
                      <span className={styles.obj_count}>{searchitemsCount.artists_count}</span>
                    </span>
                  </Link>
                </div>
              </div>
              <div className="fu-col-vs-12 fu-col-xxxs-12 col-xxs-6 col-xs-6 col-sm-4 col-md-3 fu-col-lg-20 fu-col-xl-20 fu-col-xxl-20">
                <div className={styles.obj_btn_container}>
                  <Link to={`/search/labels?sq=${searchWords}`} className={styles.obj_link} disabled={isSearchItemsLoading ? true : false}>
                    <span className={`${styles.obj_btn} ${searchObj === "labels" ? styles.obj_btn_active : ""} ${isSearchItemsLoading ? "opacity-50" : "opacity-100"}`} onClick={() => searchObj !== "labels" && setIsSearchItemsLoading(true)}>
                      <span className={styles.obj_name}>Labels</span>
                      <span className={styles.obj_count}>{searchitemsCount.labels_count}</span>
                    </span>
                  </Link>
                </div>
              </div>
              <div className="fu-col-vs-12 fu-col-xxxs-12 col-xxs-6 col-xs-6 col-sm-4 col-md-3 fu-col-lg-20 fu-col-xl-20 fu-col-xxl-20">
                <div className={styles.obj_btn_container}>
                  <Link to={`/search/news?sq=${searchWords}`} className={styles.obj_link} disabled={isSearchItemsLoading ? true : false}>
                    <span className={`${styles.obj_btn} ${searchObj === "news" ? styles.obj_btn_active : ""} ${isSearchItemsLoading ? "opacity-50" : "opacity-100"}`} onClick={() => searchObj !== "news" && setIsSearchItemsLoading(true)}>
                      <span className={styles.obj_name}>News</span>
                      <span className={styles.obj_count}>{searchitemsCount.news_count}</span>
                    </span>
                  </Link>
                </div>
              </div>
            </div>

            {isSearchItemsLoading || isPaginateLoading ? (
              <div className="d-flex justify-content-center mt-5 pt-5 pb-5 mb-5">
                <div className="position-relative mb-5 pb-5">
                  <LoadingCircle circleCont={85} icon={80} loader={85} dot={85} />
                </div>
              </div>
            ) : (
              <>
                <div className="mt-4">
                  <SearchResult searchWords={searchWords} searchObj={searchObj} searchitems={searchitems} isSearchItemsLoading={isSearchItemsLoading} isPaginateLoading={isPaginateLoading} isSearchLoading={isSearchLoading} />
                </div>
                <div className="mt-5">{searchitems ? searchitems.meta && searchitems.meta.last_page !== 1 ? <Pagination offsetElement={null} pages={searchitems.meta.links} pagination={searchitems.meta} getItems={getSearchItems} pathName={`fm-${searchObj}`} filtrationDetails={{ search_query: searchWords }} setIsPaginateLoading={setIsPaginateLoading} setPageNum={setPageNum} per_page={perPage} order_by={orderBy} order_type={orderType} /> : "" : ""}</div>
              </>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default Search;
