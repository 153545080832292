import React, { useContext, useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Keyboard, Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import { toast } from "react-toastify";
import styles from "./NewDetails.module.scss";
import NewBlogCard from "../NewBlogCard/NewBlogCard";
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton, WhatsappShareButton } from "react-share";
import CopyToClipboard from "react-copy-to-clipboard";
import { fmDataContext } from "../../Context/FmData";
import { Link, useParams } from "react-router-dom";
import LoadingScreen from "../LoadingScreen/LoadingScreen";
import { Helmet } from "react-helmet-async";

const NewDetails = () => {
  let [mounted, setMounted] = useState(false);
  let [newsSwiperCount, setNewsSwiperCount] = useState(2);

  let { newId } = useParams();

  let { baseUrl, navigate, showData, globalCrumbs, setGlobalCrumbs, isMainDataLoading } = useContext(fmDataContext);

  let [currentNewBlog, setCurrentNewBlog] = useState({});
  let [isCurrentNewBlogLoading, setIsCurrentNewBlogLoading] = useState(true);

  const setNewsSlidesCount = () => {
    if (window.innerWidth >= 1400) {
      setNewsSwiperCount(2);
    } else if (window.innerWidth < 1400 && window.innerWidth >= 1200) {
      setNewsSwiperCount(2);
    } else if (window.innerWidth < 1200 && window.innerWidth >= 992) {
      setNewsSwiperCount(2);
    } else if (window.innerWidth < 992 && window.innerWidth >= 768) {
      setNewsSwiperCount(1);
    } else if (window.innerWidth < 768 && window.innerWidth >= 576) {
      setNewsSwiperCount(1);
    } else if (window.innerWidth < 576 && window.innerWidth > 0) {
      setNewsSwiperCount(1);
    }
  };

  const getNewBlog = (reqMethod, pathName, setLoading = setIsCurrentNewBlogLoading, headers, params = null) => {
    showData(reqMethod, pathName, setLoading, headers, params)
      .then((response) => {
        // ------------------- REMOVE BEFORE PRODUCTION -----------------------------------
        // if (response.data.data && response.data.data.new_image) {
        //   if (response.data.data.new_image.includes("via.placeholder.com")) {
        //     response.data.data.new_image = response.data.data.new_image.replace("http://127.0.0.1:8000/storage/", "");
        //   }
        // }
        // if (response.data.data && response.data.data.related_news !== null) {
        //   response.data.data.related_news.forEach((newBlog) => {
        //     if (newBlog.new_image.includes("via.placeholder.com")) {
        //       newBlog.new_image = newBlog.new_image.replace("http://127.0.0.1:8000/storage/", "");
        //     }
        //   });
        // }
        // ------------------- REMOVE BEFORE PRODUCTION -----------------------------------
        if (response.data.data && response.data.data.related_news) {
          response.data.data.related_news.reverse();
        }
        setCurrentNewBlog(response.data.data);
        setLoading(false);
      })
      .catch((error) => {
        navigate("/not-found");
      });
  };

  useEffect(() => {
    getNewBlog("GET", `${baseUrl}fm-new/${newId}`, setIsCurrentNewBlogLoading, {
      Accept: "application/json",
      "fm-api-secret-key": process.env.REACT_APP_FM_API_SECRET_KEY,
    });
    setMounted(true);

    setNewsSlidesCount();
    window.addEventListener("resize", function () {
      setNewsSlidesCount();
    });
  }, []);

  useEffect(() => {
    if (mounted) {
      getNewBlog("GET", `${baseUrl}fm-new/${newId}`, setIsCurrentNewBlogLoading, {
        Accept: "application/json",
        "fm-api-secret-key": process.env.REACT_APP_FM_API_SECRET_KEY,
      });
    }
  }, [newId]);

  useEffect(() => {
    if (mounted && currentNewBlog) {
      let globalCrumbsUpdated = globalCrumbs.map((crumb) => {
        if (crumb.name === newId.toUpperCase()) {
          crumb.name = currentNewBlog.new_title > 20 ? `${currentNewBlog.new_title.slice(0, 20).toUpperCase()}...` : currentNewBlog.new_title.toUpperCase();
        }
        return crumb;
      });
      setGlobalCrumbs(globalCrumbsUpdated);
    }
  }, [currentNewBlog]);

  return (
    <>
      {isCurrentNewBlogLoading || isMainDataLoading ? (
        <LoadingScreen />
      ) : (
        <>
          <Helmet>
            <title>{currentNewBlog.new_title}</title>
            <meta name="description" content={`${currentNewBlog.new_description}`.replaceAll("</br>", " ").replaceAll("<br>", " ")} />
            <link rel="canonical" href={`${window.location.href}`} />
            <link href="https://www.futurisingmusic.com" rel="home" />
            <meta property="og:site_name" content="Futurising Music" />
            <meta property="og:type" content="website" />
            <meta property="og:image" content={currentNewBlog.new_image} />
            <meta property="og:description" content={`${currentNewBlog.new_description}`.replaceAll("</br>", " ").replaceAll("<br>", " ")} />
            <meta content={`Futurising Music - ${currentNewBlog.new_title}`} property="og:image:alt" />
            <meta content="1296" property="og:image:width" />
            <meta content="480" property="og:image:height" />
            <meta property="og:url" content={window.location.href} />
            <meta content="https://twitter.com/FuturisingMuisc/" property="og:see_also" />
            <meta content="https://www.facebook.com/Futurisingmusicrec/" property="og:see_also" />
            <meta content="https://www.tiktok.com/@futurisingmusic/" property="og:see_also" />
            <meta content="https://www.instagram.com/futurisingrec/" property="og:see_also" />
          </Helmet>
          <div className={styles.blog_heading_container}>
            <div className={styles.blog_img_section}>
              <div className="container">
                <div className="pt-4">
                  <div className={styles.blog_pic_container}>
                    <img src={currentNewBlog.new_image} alt="blog" className="w-100 h-100" />
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.bg_blur} style={{ backgroundImage: `url(${currentNewBlog.new_image})` }}></div>
            <div className={styles.gradient}></div>
          </div>
          <div className="position-relative mt-3 mt-sm-4 z-index-high">
            <div className="container">
              <div className={styles.created_at_box}>
                <div className={styles.calendar_icon_container}>
                  <i className="bi bi-calendar-event"></i>
                </div>
                <div className={styles.date_container}>
                  <p className={`${styles.creation_date} mb-0`}>{new Date(currentNewBlog.new_created_at).toLocaleString("en-US", { month: "short", day: "2-digit", year: "numeric" })}</p>
                </div>
              </div>
              <div className="mt-2">
                <div className={styles.blog_title_container}>
                  <h1 className={styles.blog_title}>{currentNewBlog.new_title}</h1>
                </div>
              </div>
              <div className={styles.blog_description_container}>
                <p className={styles.blog_description} dangerouslySetInnerHTML={{ __html: currentNewBlog.new_description }} />
                {currentNewBlog.new_btn_path_inside || currentNewBlog.new_btn_path_outside ? (
                  <div className="mt-4">
                    {currentNewBlog.new_btn_path_outside && currentNewBlog.new_btn_text_outside && (
                      <a href={currentNewBlog.new_btn_path_outside} className="fu-btn-sm fu-btn-gray" target="_blank">
                        <span className="btn-bg"></span>
                        {currentNewBlog.new_btn_text_outside}
                      </a>
                    )}
                    {currentNewBlog.new_btn_path_inside && currentNewBlog.new_btn_text_inside && (
                      <Link to={`/${currentNewBlog.new_btn_path_inside}`} className="fu-btn-sm fu-btn-gray ms-2">
                        <span className="btn-bg"></span>
                        {currentNewBlog.new_btn_text_inside}
                      </Link>
                    )}
                  </div>
                ) : (
                  ""
                )}
              </div>
              {currentNewBlog.related_news ? (
                <div className="mt-4">
                  <div className="mb-2 item-row-heading">
                    <h2 className="mb-0 row-title">RELATED NEWS</h2>
                  </div>
                  <div className="row gx-3">
                    <Swiper className="myswiper px-2 h-100" slidesPerView={newsSwiperCount} spaceBetween={10} freeMode={true} keyboard={true} navigation={true} modules={[FreeMode, Navigation, Keyboard]}>
                      {currentNewBlog.related_news.map((newBlog, index) => (
                        <SwiperSlide className="my-1" key={index}>
                          <NewBlogCard newBlog={newBlog} />
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  </div>
                </div>
              ) : (
                ""
              )}

              <div className="d-flex justify-content-end mt-4">
                <div className={`${styles.share_row}`}>
                  <div className={`share-box-container ${styles.share_box_container}`}>
                    <div className={styles.share_btn_container}>
                      <button className="fu-btn fu-btn-gray d-flex align-items-center" type="button" data-bs-toggle="collapse" data-bs-target="#collapseWidthExample" aria-expanded="false" aria-controls="collapseWidthExample">
                        <span className="btn-bg"></span>
                        Share
                        <span className="ms-1 align-self-center d-inline-flex">
                          <svg className="share-icon-svg" width={16} version="1.1" id="_x32_" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" viewBox="0 0 512 512" space="preserve">
                            <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                            <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                            <g id="SVGRepo_iconCarrier">
                              <g>
                                <path className="st0" d="M512,230.431L283.498,44.621v94.807C60.776,141.244-21.842,307.324,4.826,467.379 c48.696-99.493,149.915-138.677,278.672-143.14v92.003L512,230.431z"></path>
                              </g>
                            </g>
                          </svg>
                        </span>
                      </button>
                    </div>

                    <div className={`collapse collapse-horizontal share-icons-box ${styles.share_icons_box}`} id="collapseWidthExample">
                      {/* facebook -- DON'T FORGET SET WINDOW.LOCATION.HREF IN URL */}
                      <div className={styles.share_icon_container}>
                        <div>
                          <FacebookShareButton url={window.location.href} windowWidth={800} windowHeight={800}>
                            <span className={styles.social_icon_container}>
                              <svg className={styles.social_icon} width={16} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                                <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                                <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                                <g id="SVGRepo_iconCarrier">
                                  <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z"></path>
                                </g>
                              </svg>
                            </span>
                          </FacebookShareButton>
                        </div>
                      </div>
                      {/* X -- DON'T FORGET SET WINDOW.LOCATION.HREF IN URL */}
                      <div className={`${styles.share_icon_container}`}>
                        <div>
                          {/* allow title */}
                          <TwitterShareButton url={window.location.href} title={currentNewBlog.new_title} windowWidth={800} windowHeight={800}>
                            <div className={styles.social_icon_container}>
                              <svg xmlns="http://www.w3.org/2000/svg" width={16} viewBox="0 0 512 512" id="twitter">
                                <g clipPath="url(#clip0_84_15698)">
                                  <path className={styles.social_icon} width={16} d="M355.904 100H408.832L293.2 232.16L429.232 412H322.72L239.296 302.928L143.84 412H90.8805L214.56 270.64L84.0645 100H193.28L268.688 199.696L355.904 100ZM337.328 380.32H366.656L177.344 130.016H145.872L337.328 380.32Z"></path>
                                </g>
                              </svg>
                            </div>
                          </TwitterShareButton>
                        </div>
                      </div>
                      {/* linkedin -- DON'T FORGET SET WINDOW.LOCATION.HREF IN URL */}
                      <div className={`${styles.share_icon_container}`}>
                        <div>
                          <LinkedinShareButton url={window.location.href} title={currentNewBlog.new_title} windowWidth={800} windowHeight={800}>
                            <div className={styles.social_icon_container}>
                              <svg className={styles.social_icon} width={16} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                                <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                                <g id="SVGRepo_iconCarrier">
                                  <path
                                    fillRule="evenodd"
                                    d="M20.3292112,2 C21.2519624,2 22,2.74752185 22,3.67078882 L22,20.3292112 C22,21.2519624 21.2524781,22 20.3292112,22 L3.67078882,22 C2.74803764,22 2,21.2524781 2,20.3292112 L2,3.67078882 C2,2.74803764 2.74752185,2 3.67078882,2 L20.3292112,2 Z M15.51875,9.5 C14.0993287,9.5 13.128125,10.127356 12.6956992,10.8562567 L12.625,10.9858333 L12.625,9.625 L9.91666667,9.625 L9.91666667,19.2083333 L12.8333333,19.2083333 L12.8333333,14.56625 C12.8333333,13.0104167 13.40625,12.0208333 14.7833333,12.0208333 C15.7330797,12.0208333 16.1315784,12.8606664 16.1644352,14.3580086 L16.1666667,14.56625 L16.1666667,19.2083333 L19.0833333,19.2083333 L19.0833333,13.9154167 C19.0833333,11.0575 18.3995833,9.5 15.51875,9.5 Z M7.83333333,9.5 L4.91666667,9.5 L4.91666667,19.0833333 L7.83333333,19.0833333 L7.83333333,9.5 Z M6.375,4.5 C5.33958333,4.5 4.5,5.33958333 4.5,6.375 C4.5,7.41041667 5.33958333,8.25 6.375,8.25 C7.41041667,8.25 8.25,7.41041667 8.25,6.375 C8.25,5.33958333 7.41041667,4.5 6.375,4.5 Z"></path>
                                </g>
                              </svg>
                            </div>
                          </LinkedinShareButton>
                        </div>
                      </div>
                      {/* whatsapp -- DON'T FORGET SET WINDOW.LOCATION.HREF IN URL */}
                      <div className={`${styles.share_icon_container}`}>
                        <div>
                          <WhatsappShareButton url={window.location.href} title={currentNewBlog.new_title} windowWidth={800} windowHeight={800}>
                            <div className={styles.social_icon_container}>
                              <svg className={styles.social_icon} width={16} viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink">
                                <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                                <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                                <g id="SVGRepo_iconCarrier">
                                  <g id="Page-1" stroke="none" strokeWidth="1" fillRule="evenodd">
                                    <g id="Dribbble-Light-Preview" transform="translate(-300.000000, -7599.000000)">
                                      <g id="icons" transform="translate(56.000000, 160.000000)">
                                        <path
                                          d="M259.821,7453.12124 C259.58,7453.80344 258.622,7454.36761 257.858,7454.53266 C257.335,7454.64369 256.653,7454.73172 254.355,7453.77943 C251.774,7452.71011 248.19,7448.90097 248.19,7446.36621 C248.19,7445.07582 248.934,7443.57337 250.235,7443.57337 C250.861,7443.57337 250.999,7443.58538 251.205,7444.07952 C251.446,7444.6617 252.034,7446.09613 252.104,7446.24317 C252.393,7446.84635 251.81,7447.19946 251.387,7447.72462 C251.252,7447.88266 251.099,7448.05372 251.27,7448.3478 C251.44,7448.63589 252.028,7449.59418 252.892,7450.36341 C254.008,7451.35771 254.913,7451.6748 255.237,7451.80984 C255.478,7451.90987 255.766,7451.88687 255.942,7451.69881 C256.165,7451.45774 256.442,7451.05762 256.724,7450.6635 C256.923,7450.38141 257.176,7450.3464 257.441,7450.44643 C257.62,7450.50845 259.895,7451.56477 259.991,7451.73382 C260.062,7451.85686 260.062,7452.43903 259.821,7453.12124 M254.002,7439 L253.997,7439 L253.997,7439 C248.484,7439 244,7443.48535 244,7449 C244,7451.18666 244.705,7453.21526 245.904,7454.86076 L244.658,7458.57687 L248.501,7457.3485 C250.082,7458.39482 251.969,7459 254.002,7459 C259.515,7459 264,7454.51465 264,7449 C264,7443.48535 259.515,7439 254.002,7439"
                                          id="whatsapp-[#128]"></path>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </svg>
                            </div>
                          </WhatsappShareButton>
                        </div>
                      </div>
                      {/* Copy */}
                      <div className={`${styles.share_icon_container}`}>
                        <div>
                          <CopyToClipboard text={window.location.href}>
                            <div
                              className={styles.social_icon_container}
                              onClick={() =>
                                toast.success("Copied to clipboard!", {
                                  position: "top-right",
                                  autoClose: 3000,
                                  hideProgressBar: false,
                                  progressStyle: { backgroundColor: "#fdb915" },
                                  closeOnClick: true,
                                  pauseOnHover: false,
                                  draggable: true,
                                  progress: undefined,
                                  theme: "dark",
                                })
                              }>
                              <svg className={styles.social_icon} width={16} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" viewBox="0 0 425.466 425.467" space="preserve">
                                <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                                <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                                <g id="SVGRepo_iconCarrier">
                                  <g>
                                    <g>
                                      <g>
                                        <path d="M318.15,230.195l77.934-77.937c31.894-31.892,31.894-83.782-0.004-115.674l-12.66-12.66 c-31.893-31.896-83.78-31.896-115.674-0.004l-77.937,77.934c-17.588,17.588-25.457,41.264-23.646,64.311 c-23.045-1.813-46.722,6.056-64.308,23.647L23.92,267.748c-31.894,31.889-31.894,83.779,0,115.674l12.664,12.662 c31.893,31.893,83.783,31.893,115.674,0l77.935-77.936c17.592-17.59,25.459-41.266,23.647-64.309 C276.884,255.654,300.56,247.783,318.15,230.195z M202.653,290.605l-77.936,77.938c-16.705,16.703-43.889,16.703-60.59,0 l-12.666-12.666c-16.705-16.701-16.703-43.885,0-60.594l77.936-77.932c14.14-14.141,35.779-16.306,52.226-6.516l-32.302,32.307 c-7.606,7.604-7.606,19.938,0,27.541c7.605,7.607,19.937,7.607,27.541,0l32.306-32.303 C218.959,254.828,216.795,276.469,202.653,290.605z M238.382,209.169l32.299-32.306c7.608-7.602,7.608-19.935,0-27.538 c-7.604-7.61-19.936-7.61-27.541-0.004l-32.303,32.303c-9.791-16.446-7.627-38.087,6.514-52.226l77.935-77.935 c16.707-16.707,43.89-16.707,60.594,0l12.664,12.664c16.705,16.705,16.705,43.886,0,60.591l-77.936,77.937 C276.468,216.797,254.828,218.959,238.382,209.169z"></path>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </svg>
                            </div>
                          </CopyToClipboard>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {/* 
      <div className={styles.blog_heading_container}>
        <div className={styles.blog_img_section}>
          <div className="container">
            <div className="pt-4">
              <div className={styles.blog_pic_container}>
                <img src={Images.blog1} alt="blog" className="w-100 h-100" />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.bg_blur} style={{ backgroundImage: `url(${Images.blog1})` }}></div>
        <div className={styles.gradient}></div>
      </div>
      <div className="position-relative mt-3 mt-sm-4 z-index-high">
        <div className="container">
          <div className={styles.created_at_box}>
            <div className={styles.calendar_icon_container}>
              <i className="bi bi-calendar-event"></i>
            </div>
            <div className={styles.date_container}>
              <p className={`${styles.creation_date} mb-0`}>April 12, 2023</p>
            </div>
          </div>
          <div className="mt-2">
            <div className={styles.blog_title_container}>
              <h1 className={styles.blog_title}>Azael brings the mainstage sounds here to futurising with banger track 'Night Fury' that will destroy the dance floors.</h1>
            </div>
          </div>
          <div className={styles.blog_description_container}>
            <p className={styles.blog_description}>
              Azael brings the mainstage sounds here to futurising with banger track 'Night Fury' that will destroy the dance floors Azael brings the mainstage sounds here to futurising with banger track 'Night Fury that will destroy the dance floors Azael brings the mainstage sounds here to futurising with banger track 'Night Fury that will destroy the dance floors Azael brings the mainstage sounds here to futurising with banger track 'Night Fury that will destroy the dance floors Azael brings
              the mainstage sounds here to futurising with banger track 'Night Fury that will destroy the dance floors Azael brings the mainstage sounds here to futurising with banger track 'Night Fury that will destroy the dance floors.
            </p>
            <p className={styles.blog_description}>
              Azael brings the mainstage sounds here to futurising with banger track 'Night Fury' that will destroy the dance floors Azael brings the mainstage sounds here to futurising with banger track 'Night Fury that will destroy the dance floors Azael brings the mainstage sounds here to futurising with banger track 'Night Fury that will destroy the dance floors Azael brings the mainstage sounds here to futurising with banger track 'Night Fury that will destroy the dance floors Azael brings
              the mainstage sounds here to futurising with banger track 'Night Fury that will destroy the dance floors Azael brings the mainstage sounds here to futurising with banger track 'Night Fury that will destroy the dance floors.
            </p>
            <div className="mt-4">
              link outside website
              <a href="" className="fu-btn-sm fu-btn-gray" target="_blank">
                <span className="btn-bg"></span>
                Listen Now
              </a>
              link inside website
              <Link to="" className="fu-btn-sm fu-btn-gray ms-2">
                <span className="btn-bg"></span>
                Listen Now
              </Link>
            </div>
          </div>

          <div className="mt-4">
            <div className="mb-2 item-row-heading">
              <h2 className="mb-0 row-title">RELATED NEWS</h2>
            </div>
            <div className="row gx-3">
              <Swiper className="myswiper px-2 h-100" slidesPerView={newsSwiperCount} spaceBetween={10} freeMode={true} keyboard={true} navigation={true} modules={[FreeMode, Navigation, Keyboard]}>
                <SwiperSlide className="my-1">
                  <NewBlogCard
                    newBlog={{
                      new_key: "123456789",
                      new_image: Images.blog1,
                      new_title: "Azael brings the mainstage sounds here to futurising with banger track 'Night Fury that will destroy the dance floors",
                    }}
                  />
                </SwiperSlide>
                <SwiperSlide className="my-1">
                  <NewBlogCard
                    newBlog={{
                      new_key: "123456789",
                      new_image: Images.blog1,
                      new_title: "Azael brings the mainstage sounds here to futurising with banger track 'Night Fury that will destroy the dance floors",
                    }}
                  />
                </SwiperSlide>
                <SwiperSlide className="my-1">
                  <NewBlogCard
                    newBlog={{
                      new_key: "123456789",
                      new_image: Images.blog2,
                      new_title: "Bigtopo & LKX joined forces together to bring the future with this beauty, belnding trance",
                    }}
                  />
                </SwiperSlide>
                <SwiperSlide className="my-1">
                  <NewBlogCard
                    newBlog={{
                      new_key: "123456789",
                      new_image: Images.blog1,
                      new_title: "Azael brings the mainstage sounds here to futurising with banger track 'Night Fury that will destroy the dance floors",
                    }}
                  />
                </SwiperSlide>
                <SwiperSlide className="my-1">
                  <NewBlogCard
                    newBlog={{
                      new_key: "123456789",
                      new_image: Images.blog2,
                      new_title: "Bigtopo & LKX joined forces together to bring the future with this beauty, belnding trance",
                    }}
                  />
                </SwiperSlide>
                <SwiperSlide className="my-1">
                  <NewBlogCard
                    newBlog={{
                      new_key: "123456789",
                      new_image: Images.blog1,
                      new_title: "Azael brings the mainstage sounds here to futurising with banger track 'Night Fury that will destroy the dance floors",
                    }}
                  />
                </SwiperSlide>
                <SwiperSlide className="my-1">
                  <NewBlogCard
                    newBlog={{
                      new_key: "123456789",
                      new_image: Images.blog2,
                      new_title: "Bigtopo & LKX joined forces together to bring the future with this beauty, belnding trance",
                    }}
                  />
                </SwiperSlide>
                <SwiperSlide className="my-1">
                  <NewBlogCard
                    newBlog={{
                      new_key: "123456789",
                      new_image: Images.blog1,
                      new_title: "Azael brings the mainstage sounds here to futurising with banger track 'Night Fury that will destroy the dance floors",
                    }}
                  />
                </SwiperSlide>
                <SwiperSlide className="my-1">
                  <NewBlogCard
                    newBlog={{
                      new_key: "123456789",
                      new_image: Images.blog2,
                      new_title: "Bigtopo & LKX joined forces together to bring the future with this beauty, belnding trance",
                    }}
                  />
                </SwiperSlide>
                <SwiperSlide className="my-1">
                  <NewBlogCard
                    newBlog={{
                      new_key: "123456789",
                      new_image: Images.blog1,
                      new_title: "Azael brings the mainstage sounds here to futurising with banger track 'Night Fury that will destroy the dance floors",
                    }}
                  />
                </SwiperSlide>
                <SwiperSlide className="my-1">
                  <NewBlogCard
                    newBlog={{
                      new_key: "123456789",
                      new_image: Images.blog2,
                      new_title: "Bigtopo & LKX joined forces together to bring the future with this beauty, belnding trance",
                    }}
                  />
                </SwiperSlide>
              </Swiper>
            </div>
          </div>

          <div className="d-flex justify-content-end mt-4">
            <div className={`${styles.share_row}`}>
              <div className={`share-box-container ${styles.share_box_container}`}>
                <div className={styles.share_btn_container}>
                  <button className="fu-btn fu-btn-gray d-flex align-items-center" type="button" data-bs-toggle="collapse" data-bs-target="#collapseWidthExample" aria-expanded="false" aria-controls="collapseWidthExample">
                    <span className="btn-bg"></span>
                    Share
                    <span className="ms-1 align-self-center d-inline-flex">
                      <svg className="share-icon-svg" width={16} version="1.1" id="_x32_" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" viewBox="0 0 512 512" space="preserve">
                        <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                        <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                        <g id="SVGRepo_iconCarrier">
                          <g>
                            <path className="st0" d="M512,230.431L283.498,44.621v94.807C60.776,141.244-21.842,307.324,4.826,467.379 c48.696-99.493,149.915-138.677,278.672-143.14v92.003L512,230.431z"></path>
                          </g>
                        </g>
                      </svg>
                    </span>
                  </button>
                </div>

                <div className={`collapse collapse-horizontal share-icons-box ${styles.share_icons_box}`} id="collapseWidthExample">
                  facebook -- DON'T FORGET SET WINDOW.LOCATION.HREF IN URL
                  <div className={styles.share_icon_container}>
                    <div>
                      <FacebookShareButton url={"https://futurisingmusic.com/"} windowWidth={800} windowHeight={800}>
                        <span className={styles.social_icon_container}>
                          <svg className={styles.social_icon} width={16} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                            <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                            <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                            <g id="SVGRepo_iconCarrier">
                              <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z"></path>
                            </g>
                          </svg>
                        </span>
                      </FacebookShareButton>
                    </div>
                  </div>
                  X allow title -- DON'T FORGET SET WINDOW.LOCATION.HREF IN URL
                  <div className={`${styles.share_icon_container}`}>
                    <div>
                      <TwitterShareButton url={"https://futurisingmusic.com/"} title="Futurising Music" windowWidth={800} windowHeight={800}>
                        <div className={styles.social_icon_container}>
                          <svg xmlns="http://www.w3.org/2000/svg" width={16} viewBox="0 0 512 512" id="twitter">
                            <g clipPath="url(#clip0_84_15698)">
                              <path className={styles.social_icon} width={16} d="M355.904 100H408.832L293.2 232.16L429.232 412H322.72L239.296 302.928L143.84 412H90.8805L214.56 270.64L84.0645 100H193.28L268.688 199.696L355.904 100ZM337.328 380.32H366.656L177.344 130.016H145.872L337.328 380.32Z"></path>
                            </g>
                          </svg>
                        </div>
                      </TwitterShareButton>
                    </div>
                  </div>
                  linkedin -- DON'T FORGET SET WINDOW.LOCATION.HREF IN URL
                  <div className={`${styles.share_icon_container}`}>
                    <div>
                      <LinkedinShareButton url={"https://futurisingmusic.com/"} title="Futurising Music" summary="ASDZXCASD" windowWidth={800} windowHeight={800}>
                        <div className={styles.social_icon_container}>
                          <svg className={styles.social_icon} width={16} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                            <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                            <g id="SVGRepo_iconCarrier">
                              <path
                                fillRule="evenodd"
                                d="M20.3292112,2 C21.2519624,2 22,2.74752185 22,3.67078882 L22,20.3292112 C22,21.2519624 21.2524781,22 20.3292112,22 L3.67078882,22 C2.74803764,22 2,21.2524781 2,20.3292112 L2,3.67078882 C2,2.74803764 2.74752185,2 3.67078882,2 L20.3292112,2 Z M15.51875,9.5 C14.0993287,9.5 13.128125,10.127356 12.6956992,10.8562567 L12.625,10.9858333 L12.625,9.625 L9.91666667,9.625 L9.91666667,19.2083333 L12.8333333,19.2083333 L12.8333333,14.56625 C12.8333333,13.0104167 13.40625,12.0208333 14.7833333,12.0208333 C15.7330797,12.0208333 16.1315784,12.8606664 16.1644352,14.3580086 L16.1666667,14.56625 L16.1666667,19.2083333 L19.0833333,19.2083333 L19.0833333,13.9154167 C19.0833333,11.0575 18.3995833,9.5 15.51875,9.5 Z M7.83333333,9.5 L4.91666667,9.5 L4.91666667,19.0833333 L7.83333333,19.0833333 L7.83333333,9.5 Z M6.375,4.5 C5.33958333,4.5 4.5,5.33958333 4.5,6.375 C4.5,7.41041667 5.33958333,8.25 6.375,8.25 C7.41041667,8.25 8.25,7.41041667 8.25,6.375 C8.25,5.33958333 7.41041667,4.5 6.375,4.5 Z"></path>
                            </g>
                          </svg>
                        </div>
                      </LinkedinShareButton>
                    </div>
                  </div>
                  whatsapp -- DON'T FORGET SET WINDOW.LOCATION.HREF IN URL
                  <div className={`${styles.share_icon_container}`}>
                    <div>
                      <WhatsappShareButton url={"https://futurisingmusic.com/"} title="Futurising Music" windowWidth={800} windowHeight={800}>
                        <div className={styles.social_icon_container}>
                          <svg className={styles.social_icon} width={16} viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink">
                            <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                            <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                            <g id="SVGRepo_iconCarrier">
                              <g id="Page-1" stroke="none" strokeWidth="1" fillRule="evenodd">
                                <g id="Dribbble-Light-Preview" transform="translate(-300.000000, -7599.000000)">
                                  <g id="icons" transform="translate(56.000000, 160.000000)">
                                    <path
                                      d="M259.821,7453.12124 C259.58,7453.80344 258.622,7454.36761 257.858,7454.53266 C257.335,7454.64369 256.653,7454.73172 254.355,7453.77943 C251.774,7452.71011 248.19,7448.90097 248.19,7446.36621 C248.19,7445.07582 248.934,7443.57337 250.235,7443.57337 C250.861,7443.57337 250.999,7443.58538 251.205,7444.07952 C251.446,7444.6617 252.034,7446.09613 252.104,7446.24317 C252.393,7446.84635 251.81,7447.19946 251.387,7447.72462 C251.252,7447.88266 251.099,7448.05372 251.27,7448.3478 C251.44,7448.63589 252.028,7449.59418 252.892,7450.36341 C254.008,7451.35771 254.913,7451.6748 255.237,7451.80984 C255.478,7451.90987 255.766,7451.88687 255.942,7451.69881 C256.165,7451.45774 256.442,7451.05762 256.724,7450.6635 C256.923,7450.38141 257.176,7450.3464 257.441,7450.44643 C257.62,7450.50845 259.895,7451.56477 259.991,7451.73382 C260.062,7451.85686 260.062,7452.43903 259.821,7453.12124 M254.002,7439 L253.997,7439 L253.997,7439 C248.484,7439 244,7443.48535 244,7449 C244,7451.18666 244.705,7453.21526 245.904,7454.86076 L244.658,7458.57687 L248.501,7457.3485 C250.082,7458.39482 251.969,7459 254.002,7459 C259.515,7459 264,7454.51465 264,7449 C264,7443.48535 259.515,7439 254.002,7439"
                                      id="whatsapp-[#128]"></path>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </svg>
                        </div>
                      </WhatsappShareButton>
                    </div>
                  </div>
                  opy
                  <div className={`${styles.share_icon_container}`}>
                    <div>
                      <CopyToClipboard text={window.location.href}>
                        <button
                          className={styles.social_icon_container}
                          onClick={() =>
                            toast.success("Copied to clipboard!", {
                              position: "top-right",
                              autoClose: 3000,
                              hideProgressBar: false,
                              progressStyle: { backgroundColor: "#fdb915" },
                              closeOnClick: true,
                              pauseOnHover: false,
                              draggable: true,
                              progress: undefined,
                              theme: "dark",
                            })
                          }>
                          <svg className={styles.social_icon} width={16} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" viewBox="0 0 425.466 425.467" space="preserve">
                            <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                            <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                            <g id="SVGRepo_iconCarrier">
                              <g>
                                <g>
                                  <g>
                                    <path d="M318.15,230.195l77.934-77.937c31.894-31.892,31.894-83.782-0.004-115.674l-12.66-12.66 c-31.893-31.896-83.78-31.896-115.674-0.004l-77.937,77.934c-17.588,17.588-25.457,41.264-23.646,64.311 c-23.045-1.813-46.722,6.056-64.308,23.647L23.92,267.748c-31.894,31.889-31.894,83.779,0,115.674l12.664,12.662 c31.893,31.893,83.783,31.893,115.674,0l77.935-77.936c17.592-17.59,25.459-41.266,23.647-64.309 C276.884,255.654,300.56,247.783,318.15,230.195z M202.653,290.605l-77.936,77.938c-16.705,16.703-43.889,16.703-60.59,0 l-12.666-12.666c-16.705-16.701-16.703-43.885,0-60.594l77.936-77.932c14.14-14.141,35.779-16.306,52.226-6.516l-32.302,32.307 c-7.606,7.604-7.606,19.938,0,27.541c7.605,7.607,19.937,7.607,27.541,0l32.306-32.303 C218.959,254.828,216.795,276.469,202.653,290.605z M238.382,209.169l32.299-32.306c7.608-7.602,7.608-19.935,0-27.538 c-7.604-7.61-19.936-7.61-27.541-0.004l-32.303,32.303c-9.791-16.446-7.627-38.087,6.514-52.226l77.935-77.935 c16.707-16.707,43.89-16.707,60.594,0l12.664,12.664c16.705,16.705,16.705,43.886,0,60.591l-77.936,77.937 C276.468,216.797,254.828,218.959,238.382,209.169z"></path>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </svg>
                        </button>
                      </CopyToClipboard>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default NewDetails;
