import React, { useContext } from "react";
import Reveal from "../Reveal/Reveal";
import { fmDataContext } from "../../Context/FmData";
import { LazyLoadComponent, LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

const ReleaseCard = ({ release }) => {
  let { navigate } = useContext(fmDataContext);

  return (
    <div className="h-100">
      <Reveal>
        <div className="p-2 p-sm-2 p-md-3 p-lg-3 p-xl-3 h-100 release-container" onClick={() => navigate(`/music/${release.release_key}`)}>
          <LazyLoadComponent delayTime={0} threshold={0} placeholder={<div></div>}>
            <div className="release-bg" style={{ backgroundImage: `url(${release.release_image})` }} />
            {release.is_pre_order ? <span className="pre-order-label-container">PRE ORDER</span> : ""}
          </LazyLoadComponent>
          <div className="release-img-container mb-2">
            <LazyLoadImage loading="lazy" effect="blur" src={release.release_image} alt="release" className="w-100 h-100" />
            <div className="listen-now-layer">
              <span className="listen-text">{release.is_pre_order ? "MORE DETAILS" : "LISTEN NOW"}</span>
            </div>
          </div>

          <div className="release-caption">
            <div>
              <div className="d-flex align-items-center mb-1">
                {release.is_from_top_five && (
                  <div className="position-relative z-index-high">
                    <div className="top-five-circle ms-1 me-1" data-title="from top five releases">
                      <i className="bi bi-star-fill d-inline-flex"></i>
                    </div>
                  </div>
                )}
                {release.is_from_top_five && (
                  <div className="position-relative z-index-high">
                    <div className="top-five-circle ms-1 me-2" data-title={`number ${release.order_in_top_five} in top five`}>
                      <span className="d-inline-flex top-five-order">{release.order_in_top_five}</span>
                    </div>
                  </div>
                )}
                <h3 className="mb-0 release-name">{release.release_title}</h3>
              </div>
              {release.release_label !== null ? <p className="mb-0 fu-channel-video-name">{release.release_label.label_name}</p> : ""}
              {release.release_artists !== null ? <p className="mb-0 release-artist"> {release.release_artists.map((artist, index) => `${artist.artist_name}${index < release.release_artists.length - 1 ? "," : ""} `)} </p> : ""}
            </div>
          </div>
        </div>
      </Reveal>
    </div>
  );
};

export default ReleaseCard;
