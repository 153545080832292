import React, { createContext, useState } from "react";
export let fmNewsContext = createContext(0);

const NewsContext = ({ children }) => {
  let [filtrationDetails, setFiltrationDetails] = useState({
    search_query: "",
  });

  let [isFiltrationLoading, setIsFiltrationLoading] = useState(false);

  let fmNewsLifting = {
    filtrationDetails,
    setFiltrationDetails,
    isFiltrationLoading,
    setIsFiltrationLoading,
  };

  return (
    <>
      <fmNewsContext.Provider value={{ ...fmNewsLifting }}>{children}</fmNewsContext.Provider>
    </>
  );
};

export default NewsContext;
