import React, { useContext } from "react";
import Reveal from "../Reveal/Reveal";
import { fmDataContext } from "../../Context/FmData";
import { LazyLoadComponent, LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

const NewBlogCard = ({ newBlog }) => {
  let { navigate } = useContext(fmDataContext);
  // let x = {
  //   new_key: "",
  //   new_image: "",
  //   new_title: "",
  // };

  return (
    <div className="h-100">
      <Reveal>
        <div className="p-2 p-sm-2 p-md-2 p-lg-2 p-xl-3 new-blog-container h-100 " onClick={() => navigate(`/news/${newBlog.new_key}`)}>
          <LazyLoadComponent delayTime={0} threshold={0} placeholder={<div></div>}>
            <div className="new-blog-bg" style={{ backgroundImage: `url(${newBlog.new_image})` }}></div>
          </LazyLoadComponent>
          <div className="blog-box">
            <div className="blog-img-container mb-2">
              <LazyLoadImage loading="lazy" effect="blur" src={newBlog.new_image} alt="blog" className="w-100 h-100 blog-img" />
              {/* <img src={newBlog.new_image} alt="blog one" className="w-100 h-100 blog-img" /> */}
            </div>
            <div className="fu-blog-caption">
              <div>
                <h3 className="mb-2 fu-blog-title">{newBlog.new_title}</h3>
                <span className="mb-0 fu-blog-read">Read more +</span>
              </div>
            </div>
          </div>
        </div>
      </Reveal>
    </div>
  );
};

export default NewBlogCard;
