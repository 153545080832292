import React, { useContext, useEffect, useRef } from "react";
import styles from "./MobileNavbar.module.scss";
import Images from "../../images";
import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { Button, Col, Form, Row } from "react-bootstrap";
import { fmDataContext } from "../../Context/FmData";
import { toast } from "react-toastify";
import Joi from "joi";

const MobileNavbar = () => {
  let isCalledRef = useRef(false);
  let mainNavRef = useRef();
  let location = useLocation();
  let navigate = useNavigate();

  let { mainData, isMainDataLoading } = useContext(fmDataContext);

  let [searchParams, setSearchParams] = useSearchParams();
  let searchWords = searchParams.get("sq");

  const goToSearch = (e) => {
    e.preventDefault();
    let searchText = e.target.search_query.value.toLowerCase();

    const scheme = Joi.object({
      searchText: Joi.string().min(1).max(255).required(),
    });

    let validationResult = scheme.validate({ searchText }, { abortEarly: false });
    let joiErrorsList = validationResult.error;

    if (joiErrorsList) {
      joiErrorsList.details.map((error) => {
        if (error.path[0] === "searchText") {
          toast(error.message.replace(`"searchText"`, "search text"), {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            progressStyle: { backgroundColor: "#fdb915" },
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        }
      });
    } else if (searchWords && searchWords.toLocaleLowerCase() === searchText.toLocaleLowerCase()) {
      toast(`Search result for "${searchText}" is already in show!`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        progressStyle: { backgroundColor: "#fdb915" },
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    } else {
      navigate({
        pathname: `/search/releases`,
        search: `?sq=${searchText}`,
      });
    }
    e.target.reset();
  };

  let setNavLinkActive = () => {
    let navLinks = Array.from(mainNavRef.current.children[1].children[0].children);
    let navLinksText = navLinks.map((navLink) => {
      return navLink.children[0].children[1].children[0].innerHTML;
    });
    navLinks.forEach((navLink) => {
      navLink.classList.remove(`${styles.active}`);
      navLinksText.forEach((navLinkText, index) => {
        if (location.pathname.split("/")[1].toLocaleLowerCase() === "") {
          navLinks[1].classList.add(`${styles.active}`);
        } else if (location.pathname.split("/")[1].toLocaleLowerCase().replace("-", " ") === navLinkText.toLocaleLowerCase()) {
          navLinks[index].classList.add(`${styles.active}`);
        }
      });
    });
  };

  useEffect(() => {
    if (!isCalledRef.current) {
      if (mainNavRef.current) {
        setNavLinkActive();
      }
    }
    return () => {
      isCalledRef.current = true;
    };
  }, []);

  useEffect(() => {
    setNavLinkActive();
  }, [location.pathname]);

  return (
    <>
      <nav className="navbar p-0">
        <div className="container-fluid p-0">
          <div className="search-modal">
            <div className="modal fade" data-bs-backdrop="true" id="searchModal" tabIndex="-1" aria-labelledby="searchModalLabel" aria-hidden="true">
              <div className="modal-dialog m-0 modal-dialog-container">
                <div className="modal-box">
                  <div className="modal-content fu-shadow-light fu-bg-dark-gray">
                    <div className="close-btn-container border-0 p-3">
                      <button type="button" className="fu-btn-close" data-bs-dismiss="modal" aria-label="Close">
                        <i className="bi bi-x-circle"></i>
                      </button>
                    </div>
                    <div className="p-3 p-sm-4">
                      <div className="modal-header border-0 p-0">
                        <div className="mb-3 mb-sm-4">
                          <h3 className="search-modal-title fu-text-light-gray mb-1">Looking for something ?</h3>
                          <h3 className="search-modal-title fu-text-yellow fu-fw-700 mb-0">Search Now...</h3>
                        </div>
                      </div>
                      <Form onSubmit={goToSearch}>
                        <Row>
                          <Col md={12}>
                            <Form.Control type="text" placeholder="Search..." className="fu-input" id="global-search" name="search_query" />
                          </Col>
                        </Row>
                        <div className="modal-footer border-0 p-0 mt-3 mt-sm-4 position-relative z-index-high">
                          <Button className={`d-inline-flex fu-btn fu-btn-gray`} type="submit" data-bs-dismiss="modal" aria-label="Close">
                            <span className="btn-bg"></span>
                            <span className="position-relative">Search</span>
                          </Button>
                        </div>
                      </Form>
                    </div>
                  </div>
                </div>
                <div className="search-modal-backdrop"></div>
              </div>
            </div>
          </div>
          <div className="mobile-nav">
            <div className={`offcanvas offcanvas-start bg-black-color border-0 mobile-side-nav ${styles.side_nav}`} data-bs-scroll="false" data-bs-backdrop="true" tabIndex="-1" id="mobileOffcanvasNavbar" aria-labelledby="mobileOffcanvasNavbarLabel" ref={mainNavRef}>
              <button className="fu-bg-transparent border-0 p-0 shadow-none mobile-nav-home-btn" data-bs-dismiss="offcanvas" aria-label="Close">
                <Link to="/" className="d-block pt-3">
                  <span className="d-flex align-items-center">
                    <span className={`d-block icon-container ${styles.icon_container}`}>
                      <span className={`icon-width ${styles.icon_width}`}>
                        <img src={Images.futurisingIcon} alt="company icon" className={`icon ${styles.icon}`} />
                      </span>
                    </span>
                    <span className={`d-block logo-container ${styles.logo_container}`}>
                      <span className={`d-block logo-width ${styles.logo_width}`}>
                        <img src={Images.futurisingIogo} alt="company logo" className={`logo ${styles.logo}`} />
                      </span>
                    </span>
                  </span>
                </Link>
                <span className="close-mobile-nav-btn" type="button" data-bs-dismiss="offcanvas" aria-label="Close">
                  <i className="bi bi-x d-inline-flex"></i>
                </span>
              </button>
              <div className="d-flex flex-column my-auto">
                <ul className="navbar-nav justify-content-end flex-grow-1 navbar-links-list">
                  <li className="nav-item mb-2 pb-5" data-bs-dismiss="offcanvas" aria-label="Close">
                    <button type="button" className={`d-flex align-items-center nav-link-container ${styles.nav_link_container}`} data-bs-toggle="modal" data-bs-target="#searchModal">
                      <span className="d-block">
                        <span className={`d-block ${styles.link_icon_container}`}>
                          <span className={`nav-link p-0 ${styles.link_icon}`}>
                            <i className="bi bi-search"></i>
                          </span>
                        </span>
                      </span>
                      <span className={`d-block link-text-container ${styles.link_text_container}`}>
                        <span className={`mb-0 d-block ${styles.link_text}`}>Search</span>
                      </span>
                    </button>
                  </li>
                  <li className="nav-item mb-2" data-bs-dismiss="offcanvas" aria-label="Close">
                    <Link to="/" className={`d-flex align-items-center nav-link-container ${styles.nav_link_container}`}>
                      <span className="d-block">
                        <span className={`d-block ${styles.link_icon_container}`}>
                          <span className={`nav-link p-0 ${styles.link_icon}`}>
                            <i className="bi bi-house"></i>
                          </span>
                        </span>
                      </span>
                      <span className={`d-block link-text-container link-text-container-1 ${styles.link_text_container} ${styles.link_text_container_1}`}>
                        <span className={`mb-0 d-block ${styles.link_text}`}>Home</span>
                      </span>
                    </Link>
                  </li>
                  <li className="nav-item mb-2" data-bs-dismiss="offcanvas" aria-label="Close">
                    <Link to="/news" className={`d-flex align-items-center nav-link-container ${styles.nav_link_container}`}>
                      <span className="d-block">
                        <span className={`d-block ${styles.link_icon_container}`}>
                          <span className={`nav-link p-0 ${styles.link_icon}`}>
                            <i className="bi bi-envelope-paper"></i>
                          </span>
                        </span>
                      </span>
                      <span className={`d-block link-text-container link-text-container-2 ${styles.link_text_container} ${styles.link_text_container_2}`}>
                        <span className={`mb-0 d-block ${styles.link_text}`}>News</span>
                      </span>
                    </Link>
                  </li>
                  <li className="nav-item mb-2" data-bs-dismiss="offcanvas" aria-label="Close">
                    <Link to="/music" className={`d-flex align-items-center nav-link-container ${styles.nav_link_container}`}>
                      <span className="d-block">
                        <span className={`d-block ${styles.link_icon_container}`}>
                          <span className={`nav-link p-0 ${styles.link_icon}`}>
                            <i className="bi bi-headphones"></i>
                          </span>
                        </span>
                      </span>
                      <span className={`d-block link-text-container link-text-container-3 ${styles.link_text_container} ${styles.link_text_container_3}`}>
                        <span className={`mb-0 d-block ${styles.link_text}`}>Music</span>
                      </span>
                    </Link>
                  </li>
                  <li className="nav-item mb-2" data-bs-dismiss="offcanvas" aria-label="Close">
                    <Link to="/videos" className={`d-flex align-items-center nav-link-container ${styles.nav_link_container}`}>
                      <span className="d-block">
                        <span className={`d-block ${styles.link_icon_container}`}>
                          <span className={`nav-link p-0 ${styles.link_icon}`}>
                            <i className="bi bi-play-circle"></i>
                          </span>
                        </span>
                      </span>
                      <span className={`d-block link-text-container link-text-container-4 ${styles.link_text_container} ${styles.link_text_container_4}`}>
                        <span className={`mb-0 d-block ${styles.link_text}`}>Videos</span>
                      </span>
                    </Link>
                  </li>
                  <li className="nav-item mb-2" data-bs-dismiss="offcanvas" aria-label="Close">
                    <Link to="/artists" className={`d-flex align-items-center nav-link-container ${styles.nav_link_container}`}>
                      <span className="d-block">
                        <span className={`d-block ${styles.link_icon_container}`}>
                          <span className={`nav-link p-0 ${styles.link_icon}`}>
                            <i className="bi bi-boombox"></i>
                          </span>
                        </span>
                      </span>
                      <span className={`d-block link-text-container link-text-container-5 ${styles.link_text_container} ${styles.link_text_container_5}`}>
                        <span className={`mb-0 d-block ${styles.link_text}`}>Artists</span>
                      </span>
                    </Link>
                  </li>
                  <li className="nav-item mb-2" data-bs-dismiss="offcanvas" aria-label="Close">
                    <Link to="/labels" className={`d-flex align-items-center nav-link-container ${styles.nav_link_container}`}>
                      <span className="d-block">
                        <span className={`d-block ${styles.link_icon_container}`}>
                          <span className={`nav-link p-0 ${styles.link_icon}`}>
                            <i className="bi bi-bookmark-star"></i>
                          </span>
                        </span>
                      </span>
                      <span className={`d-block link-text-container link-text-container-6 ${styles.link_text_container} ${styles.link_text_container_6}`}>
                        <span className={`mb-0 d-block ${styles.link_text}`}>Labels</span>
                      </span>
                    </Link>
                  </li>
                  <li className="nav-item mb-2" data-bs-dismiss="offcanvas" aria-label="Close">
                    <Link to="/demo-drop" className={`d-flex align-items-center nav-link-container ${styles.nav_link_container}`}>
                      <span className="d-block">
                        <span className={`d-block ${styles.link_icon_container}`}>
                          <span className={`nav-link p-0 ${styles.link_icon}`}>
                            <i className="bi bi-cloud-arrow-up"></i>
                          </span>
                        </span>
                      </span>
                      <span className={`d-block link-text-container link-text-container-7 ${styles.link_text_container} ${styles.link_text_container_7}`}>
                        <span className={`mb-0 d-block ${styles.link_text}`}>Demo drop</span>
                      </span>
                    </Link>
                  </li>
                  <li className="nav-item mb-2" data-bs-dismiss="offcanvas" aria-label="Close">
                    <Link to="/partners" className={`d-flex align-items-center nav-link-container ${styles.nav_link_container}`}>
                      <span className="d-block">
                        <span className={`d-block ${styles.link_icon_container}`}>
                          <span className={`nav-link p-0 ${styles.link_icon}`}>
                            <i className="bi bi-diagram-3"></i>
                          </span>
                        </span>
                      </span>
                      <span className={`d-block link-text-container link-text-container-8 ${styles.link_text_container} ${styles.link_text_container_8}`}>
                        <span className={`mb-0 d-block ${styles.link_text}`}>Partners</span>
                      </span>
                    </Link>
                  </li>
                  <li className="nav-item mb-2" data-bs-dismiss="offcanvas" aria-label="Close">
                    <Link to="/about" className={`d-flex align-items-center nav-link-container ${styles.nav_link_container}`}>
                      <span className="d-block">
                        <span className={`d-block ${styles.link_icon_container}`}>
                          <span className={`nav-link p-0 ${styles.link_icon}`}>
                            <i className="bi bi-info-circle"></i>
                          </span>
                        </span>
                      </span>
                      <span className={`d-block link-text-container link-text-container-9 ${styles.link_text_container} ${styles.link_text_container_9}`}>
                        <span className={`mb-0 d-block ${styles.link_text}`}>About</span>
                      </span>
                    </Link>
                  </li>
                  <li className="nav-item mb-2" data-bs-dismiss="offcanvas" aria-label="Close">
                    <Link to="/contact" className={`d-flex align-items-center nav-link-container ${styles.nav_link_container}`}>
                      <span className="d-block">
                        <span className={`d-block ${styles.link_icon_container}`}>
                          <span className={`nav-link p-0 ${styles.link_icon}`}>
                            <i className="bi bi-send"></i>
                          </span>
                        </span>
                      </span>
                      <span className={`d-block link-text-container link-text-container-9 ${styles.link_text_container} ${styles.link_text_container_10}`}>
                        <span className={`mb-0 d-block ${styles.link_text}`}>Contact</span>
                      </span>
                    </Link>
                  </li>
                  <li className="nav-item mb-2" data-bs-dismiss="offcanvas" aria-label="Close">
                    <Link to="/privacy-policy" className={`d-flex align-items-center nav-link-container ${styles.nav_link_container}`}>
                      <span className="d-block">
                        <span className={`d-block ${styles.link_icon_container}`}>
                          <span className={`nav-link p-0 ${styles.link_icon}`}>
                            <i className="bi bi-shield-exclamation"></i>
                          </span>
                        </span>
                      </span>
                      <span className={`d-block link-text-container link-text-container-11 ${styles.link_text_container} ${styles.link_text_container_11}`}>
                        <span className={`mb-0 d-block ${styles.link_text}`}>Privacy policy</span>
                      </span>
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="text-white mb-4 nav-streaming">
                <ul className="navbar-nav justify-content-end flex-grow-1 h-100">
                  <li className={`nav-item mb-2 regular-opacity-transition ${!isMainDataLoading && mainData.futurising_data && mainData.futurising_data.streaming_platforms_links.spotify_url ? "opacity-100" : "opacity-50"}`} data-bs-dismiss="offcanvas" aria-label="Close">
                    <a href={!isMainDataLoading && mainData.futurising_data && mainData.futurising_data.streaming_platforms_links.spotify_url ? mainData.futurising_data.streaming_platforms_links.spotify_url : ""} disabled={!isMainDataLoading && mainData.futurising_data && mainData.futurising_data.streaming_platforms_links.spotify_url ? false : true} target="_blank" rel="noreferrer" className={`d-flex align-items-center nav-link-container ${styles.nav_link_container}`}>
                      <span className="d-block">
                        <span className={`d-block nav-link-icon-container ${styles.link_icon_container}`}>
                          <span className="nav-link p-0">
                            <svg className={styles.svg_icon} width={16} viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink">
                              <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                              <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                              <g id="SVGRepo_iconCarrier">
                                <g id="Page-1" stroke="none" strokeWidth="1" className={styles.svg_icon}>
                                  <g id="Dribbble-Light-Preview" transform="translate(-140.000000, -7479.000000)">
                                    <g id="icons" transform="translate(56.000000, 160.000000)">
                                      <path
                                        d="M99.915,7327.865 C96.692,7325.951 91.375,7325.775 88.297,7326.709 C87.803,7326.858 87.281,7326.58 87.131,7326.085 C86.981,7325.591 87.26,7325.069 87.754,7324.919 C91.287,7323.846 97.159,7324.053 100.87,7326.256 C101.314,7326.52 101.46,7327.094 101.196,7327.538 C100.934,7327.982 100.358,7328.129 99.915,7327.865 L99.915,7327.865 Z M99.81,7330.7 C99.584,7331.067 99.104,7331.182 98.737,7330.957 C96.05,7329.305 91.952,7328.827 88.773,7329.792 C88.36,7329.916 87.925,7329.684 87.8,7329.272 C87.676,7328.86 87.908,7328.425 88.32,7328.3 C91.951,7327.198 96.466,7327.732 99.553,7329.629 C99.92,7329.854 100.035,7330.334 99.81,7330.7 L99.81,7330.7 Z M98.586,7333.423 C98.406,7333.717 98.023,7333.81 97.729,7333.63 C95.381,7332.195 92.425,7331.871 88.944,7332.666 C88.609,7332.743 88.274,7332.533 88.198,7332.197 C88.121,7331.862 88.33,7331.528 88.667,7331.451 C92.476,7330.58 95.743,7330.955 98.379,7332.566 C98.673,7332.746 98.766,7333.129 98.586,7333.423 L98.586,7333.423 Z M94,7319 C88.477,7319 84,7323.477 84,7329 C84,7334.523 88.477,7339 94,7339 C99.523,7339 104,7334.523 104,7329 C104,7323.478 99.523,7319.001 94,7319.001 L94,7319 Z"
                                        id="spotify-[#162]"></path>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </svg>
                          </span>
                        </span>
                      </span>
                      <span className={`d-block link-text-container ${styles.link_text_container}`}>
                        <span className={`mb-0 d-block ${styles.link_text}`}>Spotify</span>
                      </span>
                    </a>
                  </li>
                  <li className={`nav-item mb-2 regular-opacity-transition ${!isMainDataLoading && mainData.futurising_data && mainData.futurising_data.streaming_platforms_links.youtube_url ? "opacity-100" : "opacity-50"}`} data-bs-dismiss="offcanvas" aria-label="Close">
                    <a href={!isMainDataLoading && mainData.futurising_data && mainData.futurising_data.streaming_platforms_links.youtube_url ? mainData.futurising_data.streaming_platforms_links.youtube_url : ""} disabled={!isMainDataLoading && mainData.futurising_data && mainData.futurising_data.streaming_platforms_links.youtube_url ? false : true} target="_blank" rel="noreferrer" className={`d-flex align-items-center nav-link-container ${styles.nav_link_container}`}>
                      <span className="d-block">
                        <span className={`d-block nav-link-icon-container ${styles.link_icon_container}`}>
                          <span className="nav-link p-0">
                            <svg className={styles.svg_icon} width={16} sversion="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" viewBox="0 0 260 180" enableBackground="new 0 0 260 180" space="preserve">
                              <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                              <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                              <g id="SVGRepo_iconCarrier">
                                <path d="M220,2H40C19.01,2,2,19.01,2,40v100c0,20.99,17.01,38,38,38h180c20.99,0,38-17.01,38-38V40C258,19.01,240.99,2,220,2z M102,130V50l68,40L102,130z"></path>
                              </g>
                            </svg>
                          </span>
                        </span>
                      </span>
                      <span className={`d-block link-text-container ${styles.link_text_container}`}>
                        <span className={`mb-0 d-block ${styles.link_text}`}>YouTube</span>
                      </span>
                    </a>
                  </li>
                  <li className={`nav-item mb-2 regular-opacity-transition ${!isMainDataLoading && mainData.futurising_data && mainData.futurising_data.streaming_platforms_links.beatport_url ? "opacity-100" : "opacity-50"}`} data-bs-dismiss="offcanvas" aria-label="Close">
                    <a href={!isMainDataLoading && mainData.futurising_data && mainData.futurising_data.streaming_platforms_links.beatport_url ? mainData.futurising_data.streaming_platforms_links.beatport_url : ""} disabled={!isMainDataLoading && mainData.futurising_data && mainData.futurising_data.streaming_platforms_links.beatport_url ? false : true} target="_blank" rel="noreferrer" className={`d-flex align-items-center nav-link-container ${styles.nav_link_container}`}>
                      <span className="d-block">
                        <span className={`d-block nav-link-icon-container ${styles.link_icon_container}`}>
                          <span className="nav-link p-0">
                            <svg className={styles.svg_icon} width={16} viewBox="0 0 24 24" role="img" xmlns="http://www.w3.org/2000/svg">
                              <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                              <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                              <g id="SVGRepo_iconCarrier">
                                <path d="M14.668 24c-3.857 0-6.935-3.039-6.935-6.974a6.98 6.98 0 0 1 1.812-4.714l-4.714 4.714-2.474-2.474 5.319-5.26c.72-.72 1.09-1.656 1.09-2.688V0h3.487v6.604c0 2.026-.72 3.74-2.123 5.143l-.156.156a6.945 6.945 0 0 1 4.694-1.812c3.955 0 6.975 3.136 6.975 6.935A6.943 6.943 0 0 1 14.668 24zm0-10.714c-2.123 0-3.779 1.753-3.779 3.74 0 2.045 1.675 3.78 3.78 3.78a3.804 3.804 0 0 0 3.818-3.78c0-2.065-1.715-3.74-3.819-3.74z"></path>
                              </g>
                            </svg>
                          </span>
                        </span>
                      </span>
                      <span className={`d-block link-text-container ${styles.link_text_container}`}>
                        <span className={`mb-0 d-block ${styles.link_text}`}>Beatport</span>
                      </span>
                    </a>
                  </li>
                  <li className={`nav-item mb-2 regular-opacity-transition ${!isMainDataLoading && mainData.futurising_data && mainData.futurising_data.streaming_platforms_links.sound_cloud_url ? "opacity-100" : "opacity-50"}`} data-bs-dismiss="offcanvas" aria-label="Close">
                    <a href={!isMainDataLoading && mainData.futurising_data && mainData.futurising_data.streaming_platforms_links.sound_cloud_url ? mainData.futurising_data.streaming_platforms_links.sound_cloud_url : ""} disabled={!isMainDataLoading && mainData.futurising_data && mainData.futurising_data.streaming_platforms_links.sound_cloud_url ? false : true} target="_blank" rel="noreferrer" className={`d-flex align-items-center nav-link-container ${styles.nav_link_container}`}>
                      <span className="d-block">
                        <span className={`d-block nav-link-icon-container ${styles.link_icon_container}`}>
                          <span className="nav-link p-0">
                            <svg className={styles.svg_icon} width={16} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" viewBox="0 0 97.774 97.774" space="preserve">
                              <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                              <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                              <g id="SVGRepo_iconCarrier">
                                <g>
                                  <g>
                                    <g>
                                      <path d="M4.723,49.69c-0.209,0-0.38,0.166-0.407,0.395l-0.961,8.781l0.961,8.586c0.027,0.229,0.198,0.396,0.407,0.396 c0.206,0,0.373-0.164,0.406-0.395l1.094-8.588l-1.094-8.781C5.096,49.851,4.929,49.69,4.723,49.69z"></path> <path d="M1.108,53.054c-0.203,0-0.365,0.156-0.392,0.383L0,58.866l0.716,5.337c0.026,0.226,0.188,0.382,0.392,0.382 c0.198,0,0.359-0.156,0.391-0.38l0.847-5.339l-0.847-5.434C1.467,53.21,1.306,53.054,1.108,53.054z"></path>
                                      <path d="M8.564,47.968c-0.258,0-0.464,0.202-0.49,0.48L7.161,58.866l0.913,10.037c0.026,0.279,0.232,0.48,0.49,0.48 c0.253,0,0.459-0.201,0.489-0.479l1.036-10.039L9.053,48.447C9.023,48.17,8.817,47.968,8.564,47.968z"></path> <path d="M12.437,47.6c-0.304,0-0.551,0.242-0.575,0.564L11,58.869l0.862,10.354c0.024,0.32,0.271,0.563,0.575,0.563 c0.299,0,0.545-0.243,0.573-0.563l0.979-10.354L13.01,48.162C12.982,47.842,12.736,47.6,12.437,47.6z"></path>
                                      <polygon points="16.996,69.31 16.996,69.31 16.996,69.312 "></polygon> <path d="M16.996,48.935c-0.024-0.365-0.308-0.644-0.656-0.644c-0.353,0-0.635,0.278-0.657,0.647l-0.814,9.93l0.814,10.441 c0.022,0.367,0.305,0.646,0.657,0.646c0.349,0,0.632-0.279,0.656-0.646l0.923-10.441L16.996,48.935z"></path>
                                      <path d="M20.276,41.985c-0.396,0-0.72,0.322-0.74,0.733l-0.764,16.155l0.764,10.44c0.021,0.406,0.345,0.729,0.74,0.729 c0.392,0,0.716-0.322,0.74-0.732v0.004l0.864-10.44l-0.864-16.156C20.991,42.307,20.667,41.985,20.276,41.985z"></path>
                                      <path d="M24.181,38.264c-0.443,0-0.804,0.358-0.824,0.815c0,0.002-0.715,19.85-0.715,19.85l0.716,10.375 c0.02,0.455,0.38,0.813,0.823,0.813c0.44,0,0.804-0.358,0.823-0.815v0.006l0.809-10.379l-0.809-19.85 C24.985,38.622,24.621,38.264,24.181,38.264z"></path>
                                      <path d="M28.241,36.47c-0.491,0-0.89,0.396-0.907,0.9c0,0.001-0.665,21.508-0.665,21.508l0.665,10.268 c0.018,0.5,0.416,0.895,0.907,0.895c0.488,0,0.887-0.395,0.907-0.898v0.006l0.751-10.27L29.148,37.37 C29.127,36.866,28.729,36.47,28.241,36.47z"></path>
                                      <path d="M33.26,36.647c-0.017-0.552-0.452-0.985-0.989-0.985c-0.541,0-0.976,0.434-0.991,0.985l-0.616,22.231l0.617,10.204 c0.015,0.546,0.449,0.978,0.99,0.978c0.537,0,0.973-0.432,0.989-0.981l0.694-10.198L33.26,36.647z"></path> <polygon points="33.26,69.078 33.26,69.078 33.26,69.083 "></polygon>
                                      <path d="M36.332,36.149c-0.59,0-1.061,0.469-1.075,1.068l-0.566,21.665l0.568,10.105c0.013,0.593,0.483,1.064,1.073,1.064 c0.586,0,1.057-0.472,1.073-1.07v0.008l0.638-10.109l-0.638-21.666C37.389,36.616,36.918,36.149,36.332,36.149z"></path>
                                      <path d="M40.425,36.856c-0.636,0-1.146,0.507-1.158,1.153L38.75,58.882l0.517,10.045c0.012,0.64,0.522,1.145,1.158,1.145 c0.635,0,1.144-0.505,1.156-1.152v0.008l0.58-10.043l-0.58-20.875C41.569,37.363,41.06,36.856,40.425,36.856z"></path>
                                      <path d="M45.235,33.02c-0.196-0.133-0.433-0.211-0.686-0.211c-0.246,0-0.475,0.075-0.668,0.201 c-0.339,0.221-0.566,0.602-0.572,1.036l-0.004,0.234l-0.466,24.598c0,0.014,0.47,9.984,0.47,9.984 c0,0.016,0.002,0.027,0.003,0.042c0.014,0.278,0.119,0.534,0.288,0.737c0.229,0.273,0.57,0.449,0.949,0.449 c0.337,0,0.644-0.139,0.868-0.363c0.225-0.223,0.367-0.531,0.373-0.873l0.052-0.986l0.471-8.984l-0.522-24.839 C45.783,33.618,45.563,33.242,45.235,33.02z"></path>
                                      <polygon points="45.79,68.862 45.79,68.859 45.79,68.863 45.79,68.858 45.79,68.854 45.79,68.854 45.79,68.859 "></polygon> <path d="M49.329,30.665c-0.196-0.12-0.428-0.19-0.673-0.19c-0.316,0-0.607,0.114-0.836,0.302 c-0.294,0.243-0.484,0.609-0.49,1.019l-0.003,0.136l-0.54,26.956l0.276,4.982l0.267,4.85c0.011,0.717,0.604,1.311,1.326,1.311 c0.719,0,1.312-0.594,1.322-1.32v0.01v0.002l0.59-9.834l0,0L49.98,31.794C49.973,31.315,49.711,30.896,49.329,30.665z"></path>
                                      <path d="M85.748,46.063c-1.646,0-3.22,0.334-4.65,0.933c-0.959-10.837-10.047-19.339-21.133-19.339 c-2.714,0-5.357,0.534-7.693,1.437c-0.907,0.352-1.147,0.712-1.156,1.414c0,0.001,0,38.168,0,38.168 c0.009,0.735,0.579,1.308,1.298,1.379c0.031,0.004,33.336,0,33.336,0c6.642,0,12.025-5.324,12.025-11.967 C97.774,51.447,92.391,46.063,85.748,46.063z"></path>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </svg>
                          </span>
                        </span>
                      </span>
                      <span className={`d-block link-text-container ${styles.link_text_container}`}>
                        <span className={`mb-0 d-block ${styles.link_text}`}>Sound cloud</span>
                      </span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className={`container-fluid p-0 mobile-bar-container ${styles.mobile_bar_container}`}>
            <div className={`container ${styles.mobile_bar}`}>
              <div className="d-flex align-items-center justify-content-between">
                <div className={styles.bar_logo_container}>
                  <Link to="/" className="d-block">
                    <span className="d-flex align-items-center">
                      <span className={`d-block icon-container ${styles.icon_container}`}>
                        <span className={`icon-width ${styles.icon_width}`}>
                          <img src={Images.futurisingIcon} alt="company icon" className={styles.icon} />
                        </span>
                      </span>
                      <span className={`d-block logo-container ${styles.logo_container}`}>
                        <span className={`d-block logo-width ${styles.logo_width}`}>
                          <img src={Images.futurisingIogo} alt="company logo" className={styles.logo} />
                        </span>
                      </span>
                    </span>
                  </Link>
                </div>
                <div>
                  <button className={`navbar-toggler shadow-none border-0 bar-btn ${styles.bar_btn}`} type="button" data-bs-toggle="offcanvas" data-bs-target="#mobileOffcanvasNavbar" aria-controls="mobileOffcanvasNavbar" aria-label="Toggle navigation">
                    <i className="bi bi-menu-button-wide"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default MobileNavbar;
