import React, { useContext } from "react";
import Reveal from "../Reveal/Reveal";
import Images from "../../images";
import { fmDataContext } from "../../Context/FmData";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

const ArtistCard = ({ artist }) => {
  let { navigate } = useContext(fmDataContext);

  return (
    <div className="h-100">
      <Reveal>
        <div className="artist-box" onClick={() => navigate(`/artists/${artist.artist_key}`)}>
          <div className="artist-img-container mb-3">
            <div className="artist-img">
              <LazyLoadImage loading="lazy" effect="blur" src={artist.artist_image} alt="company icon" className="w-100 h-100" />
              {/* <img src={artist.artist_image} alt="artist" className="w-100 h-100" /> */}
            </div>
            {artist.is_verified ? (
              <div className="verification-circle" data-title="main artist">
                <LazyLoadImage loading="lazy" effect="blur" src={Images.futurisingIcon} alt="company icon" className="futurising-icon" />
                {/* <img src={Images.futurisingIcon} alt="company icon" className="futurising-icon" /> */}
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="artist-name-container w-100 d-flex justify-content-center align-items-center">
            {artist.is_from_top_five && (
              <div className="py-1 ps-1">
                <div className="top-five-circle" data-title="from top five artists">
                  <i className="bi bi-star-fill d-inline-flex"></i>
                </div>
              </div>
            )}
            {artist.is_from_top_five && (
              <div className="position-relative z-index-high">
                <div className="top-five-circle ms-2" data-title={`number ${artist.order_in_top_five} in top five`}>
                  <span className="d-inline-flex top-five-order">{artist.order_in_top_five}</span>
                </div>
              </div>
            )}
            <h2 className="artist-name mb-0 w-100 ps-2 pe-2">{artist.artist_name}</h2>
          </div>
        </div>
      </Reveal>
    </div>
  );
};

export default ArtistCard;
