import React, { createContext, useState } from "react";

export let fmArtistsContext = createContext(0);

const ArtistsContext = ({ children }) => {
  let [filtrationDetails, setFiltrationDetails] = useState({
    search_query: "",
    label_keys: [],
    main_artist: null,
    top_five_artists: null,
  });

  let [isFiltrationLoading, setIsFiltrationLoading] = useState(false);

  let fmArtistsLifting = {
    filtrationDetails,
    setFiltrationDetails,
    isFiltrationLoading,
    setIsFiltrationLoading,
  };

  return (
    <>
      <fmArtistsContext.Provider value={{ ...fmArtistsLifting }}>{children}</fmArtistsContext.Provider>
    </>
  );
};

export default ArtistsContext;
