import React, { useContext, useRef, useState } from "react";
import { fmVideosContext } from "../../Context/VideosContext";
import { fmDataContext } from "../../Context/FmData";
import LoadingCircle from "../LoadingCircle/LoadingCircle";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { toast } from "react-toastify";

const VideosFiltration = (props) => {
  let { labels, artists, genres, getVideos, perPage, orderBy, orderType } = props;

  let { filtrationDetails, setFiltrationDetails, setIsFiltrationLoading, isFiltrationLoading } = useContext(fmVideosContext);

  let { baseUrl } = useContext(fmDataContext);

  const animatedComponents = makeAnimated();

  let [selectedLabelKeys, setSelectedLabelKeys] = useState([]);
  let [selectedArtistKeys, setSelectedArtistKeys] = useState([]);
  let [selectedGenreKeys, setSelectedGenreKeys] = useState([]);

  let labelKeysRef = useRef();
  let artistKeysRef = useRef();
  let genreKeysRef = useRef();

  const submitFiltration = (e) => {
    e.preventDefault();

    setIsFiltrationLoading(true);
    let currentFiltrationDetails = { ...filtrationDetails };

    if (e.nativeEvent.submitter.innerText === "DONE") {
      if (e.target.search_query.value === "" && currentFiltrationDetails.search_query === "" && selectedLabelKeys.length === 0 && currentFiltrationDetails.label_keys.length === 0 && selectedArtistKeys.length === 0 && currentFiltrationDetails.artist_keys.length === 0 && selectedGenreKeys.length === 0 && currentFiltrationDetails.genre_keys.length === 0) {
        toast("There are no search info for search!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          progressStyle: { backgroundColor: "#fdb915" },
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        setIsFiltrationLoading(false);
      } else if (
        currentFiltrationDetails.search_query === e.target.search_query.value &&
        JSON.stringify(currentFiltrationDetails.label_keys.sort()) === JSON.stringify(selectedLabelKeys.map((labelKey) => labelKey.value).sort()) &&
        JSON.stringify(currentFiltrationDetails.artist_keys.sort()) === JSON.stringify(selectedArtistKeys.map((artistKey) => artistKey.value).sort()) &&
        JSON.stringify(currentFiltrationDetails.genre_keys.sort()) === JSON.stringify(selectedGenreKeys.map((genreKey) => genreKey.value).sort())
      ) {
        toast("Search result to this search is already in show!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          progressStyle: { backgroundColor: "#fdb915" },
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        setIsFiltrationLoading(false);
      } else {
        currentFiltrationDetails.search_query = e.target.search_query.value;
        currentFiltrationDetails.label_keys = selectedLabelKeys.map((labelKey) => labelKey.value);
        currentFiltrationDetails.genre_keys = selectedGenreKeys.map((genreKey) => genreKey.value);
        currentFiltrationDetails.artist_keys = selectedArtistKeys.map((artistKey) => artistKey.value);
        setFiltrationDetails(currentFiltrationDetails);
        getVideos(
          "GET",
          `${baseUrl}fm-videos`,
          {
            page_num: 1,
            per_page: perPage,
            order_type: orderType,
            order_by: orderBy,
            ...currentFiltrationDetails,
          },
          setIsFiltrationLoading
        );
      }
    } else if (e.nativeEvent.submitter.innerText === "CLEAR SEARCH") {
      if (e.target.search_query.value === "" && currentFiltrationDetails.search_query === "" && selectedLabelKeys.length === 0 && currentFiltrationDetails.label_keys.length === 0 && selectedArtistKeys.length === 0 && currentFiltrationDetails.artist_keys.length === 0 && selectedGenreKeys.length === 0 && currentFiltrationDetails.genre_keys.length === 0) {
        toast("There are no search info to clear it!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          progressStyle: { backgroundColor: "#fdb915" },
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        setIsFiltrationLoading(false);
      } else {
        e.target.reset();
        labelKeysRef.current.clearValue();
        artistKeysRef.current.clearValue();
        genreKeysRef.current.clearValue();

        setFiltrationDetails({
          search_query: "",
          label_keys: [],
          genre_keys: [],
          artist_keys: [],
        });
        getVideos(
          "GET",
          `${baseUrl}fm-videos`,
          {
            page_num: 1,
            per_page: perPage,
            order_type: orderType,
            order_by: orderBy,
            search_query: "",
            label_keys: [],
            genre_keys: [],
            artist_keys: [],
          },
          setIsFiltrationLoading
        );
      }
    }
  };
  return (
    <>
      <form onSubmit={submitFiltration}>
        <div className="row g-3 align-items-center">
          {/* label_keys */}
          <div className="col-12 col-sm-12 col-lg-6">
            <div className="select-options">
              <Select
                ref={labelKeysRef}
                classNames={{
                  control: () => "select-filtration",
                  valueContainer: ({ innerProps }) => {
                    innerProps.style = { display: "flex", flexWrap: "nowrap" };
                  },
                }}
                styles={{
                  multiValue: () => ({ overflow: "visible", width: "max-content", display: "inline-flex", alignItems: "center", backgroundColor: "#2f2f2f", margin: "0 2px", borderRadius: "3px", fontSize: "14px", padding: "0 0 0 2px" }),
                  multiValueLabel: () => ({ color: "#fdb915" }),
                  multiValueRemove: () => ({ color: "#a5a5a5", marginLeft: "3px", padding: "2px", display: "inline-flex", alignItems: "center", transition: "all 0.3s", ":hover": { color: "#fdb915" } }),
                }}
                placeholder="Filter By Labels...."
                name="labels"
                onChange={setSelectedLabelKeys}
                closeMenuOnSelect={false}
                components={animatedComponents}
                isMulti
                options={labels.map((label) => ({ value: label.key, label: label.name }))}
              />
            </div>
          </div>
          {/* artist_keys */}
          <div className="col-12 col-sm-12 col-lg-6">
            <div className="select-options">
              <Select
                ref={artistKeysRef}
                classNames={{
                  control: () => "select-filtration",
                  valueContainer: ({ innerProps }) => {
                    innerProps.style = { display: "flex", flexWrap: "nowrap" };
                  },
                }}
                styles={{
                  multiValue: () => ({ overflow: "visible", width: "max-content", display: "inline-flex", alignItems: "center", backgroundColor: "#2f2f2f", margin: "0 2px", borderRadius: "3px", fontSize: "14px", padding: "0 0 0 2px" }),
                  multiValueLabel: () => ({ color: "#fdb915" }),
                  multiValueRemove: () => ({ color: "#a5a5a5", marginLeft: "3px", padding: "2px", display: "inline-flex", alignItems: "center", transition: "all 0.3s", ":hover": { color: "#fdb915" } }),
                }}
                placeholder="Filter By Artists...."
                name="artists"
                onChange={setSelectedArtistKeys}
                closeMenuOnSelect={false}
                components={animatedComponents}
                isMulti
                options={artists.map((artist) => ({ value: artist.key, label: artist.name }))}
              />
            </div>
          </div>
          {/* genre_keys */}
          <div className="col-12 col-sm-12 col-lg-6">
            <div className="select-options">
              <Select
                ref={genreKeysRef}
                classNames={{
                  control: () => "select-filtration",
                  valueContainer: ({ innerProps }) => {
                    innerProps.style = { display: "flex", flexWrap: "nowrap" };
                  },
                }}
                styles={{
                  multiValue: () => ({ overflow: "visible", width: "max-content", display: "inline-flex", alignItems: "center", backgroundColor: "#2f2f2f", margin: "0 2px", borderRadius: "3px", fontSize: "14px", padding: "0 0 0 2px" }),
                  multiValueLabel: () => ({ color: "#fdb915" }),
                  multiValueRemove: () => ({ color: "#a5a5a5", marginLeft: "3px", padding: "2px", display: "inline-flex", alignItems: "center", transition: "all 0.3s", ":hover": { color: "#fdb915" } }),
                }}
                placeholder="Filter By Genres...."
                name="genres"
                onChange={setSelectedGenreKeys}
                closeMenuOnSelect={false}
                components={animatedComponents}
                isMulti
                options={genres.map((genre) => ({ value: genre.key, label: genre.title }))}
              />
            </div>
          </div>
          {/* search_query */}
          <div className="col-12 col-sm-12 col-lg-6">
            <div>
              <input type="text" className="form-control shadow-none" name="search_query" id="search_query" placeholder="Search In Tracks..." />
            </div>
          </div>
          <div className="col-12">
            <div className="d-flex mt-0 mt-sm-2 align-items-center justify-content-start">
              <div>
                <button type="submit" className="btn fu-btn-sm fu-btn-gray" disabled={isFiltrationLoading ? true : false}>
                  <span className="btn-bg"></span>
                  DONE
                </button>
              </div>
              <div className="ms-3">
                <button type="submit" className="btn fu-btn-sm fu-btn-gray" disabled={isFiltrationLoading ? true : false}>
                  <span className="btn-bg"></span>
                  CLEAR SEARCH
                </button>
              </div>
              {isFiltrationLoading ? (
                <div className="position-relative ms-4">
                  <LoadingCircle circleCont={28} icon={23} loader={28} dot={28} />
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default VideosFiltration;
