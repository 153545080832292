import React, { createContext, useState } from "react";

export let fmLabelsContext = createContext(0);

const LabelsContext = ({ children }) => {
  let [filtrationDetails, setFiltrationDetails] = useState({
    search_query: "",
  });

  let [isFiltrationLoading, setIsFiltrationLoading] = useState(false);

  let fmLabelsLifting = {
    filtrationDetails,
    setFiltrationDetails,
    isFiltrationLoading,
    setIsFiltrationLoading,
  };

  return (
    <>
      <fmLabelsContext.Provider value={{ ...fmLabelsLifting }}>{children}</fmLabelsContext.Provider>
    </>
  );
};

export default LabelsContext;
