import axios from "axios";
import React, { createContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export let fmDataContext = createContext(0);

const FmData = ({ children }) => {
  let [mainData, setMainData] = useState({});
  let [isMainDataLoading, setIsMainDataLoading] = useState(true);

  let [globalSearchText, setGlobalSearchText] = useState("");

  let baseUrl = process.env.REACT_APP_FM_API_BASE_URL;

  let navigate = useNavigate();

  let [globalCrumbs, setGlobalCrumbs] = useState([]);

  const getAllDataWithoutPaginate = async (method, url, params, setLoading) => {
    setLoading(true);
    return await axios({
      method,
      url,
      params,
      headers: {
        Accept: "application/json",
        "fm-api-secret-key": process.env.REACT_APP_FM_API_SECRET_KEY,
      },
    });
  };

  const getData = async (
    method,
    url,
    params,
    setLoading,
    headers = {
      Accept: "application/json",
      "fm-api-secret-key": process.env.REACT_APP_FM_API_SECRET_KEY,
    }
  ) => {
    setLoading(true);
    return await axios({
      method,
      url,
      params,
      headers,
    });
  };

  const showData = async (
    method,
    url,
    setLoading,
    headers = {
      Accept: "application/json",
      "fm-api-secret-key": process.env.REACT_APP_FM_API_SECRET_KEY,
    },
    params = null
  ) => {
    setLoading(true);
    return await axios({
      method,
      url,
      params,
      headers,
    });
  };

  const goToBlogDetails = (pathname, searchText) => {
    navigate({
      pathname,
      search: `?archived=${searchText}`,
    });
  };

  const getMainData = (reqMethod, pathName, params = null, setLoading = setIsMainDataLoading) => {
    getData(reqMethod, pathName, params, setLoading)
      .then((response) => {
        setMainData(response.data);
        setLoading(false);
      })
      .catch((error) => {
        navigate("/not-found");
      });
  };

  let fmDataLifting = {
    globalSearchText,
    setGlobalSearchText,
    mainData,
    isMainDataLoading,
    baseUrl,
    navigate,
    getData,
    showData,

    globalCrumbs,
    setGlobalCrumbs,
    goToBlogDetails,
    getAllDataWithoutPaginate,
  };

  useEffect(() => {
    getMainData("GET", `${baseUrl}fm-main-data`, null, setIsMainDataLoading);
  }, []);

  return (
    <>
      <fmDataContext.Provider value={{ ...fmDataLifting }}>{children}</fmDataContext.Provider>
    </>
  );
};

export default FmData;
