import React, { useContext, useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import styles from "./DemoDrop.module.scss";
import { fmDataContext } from "../../Context/FmData";
import LoadingScreen from "../LoadingScreen/LoadingScreen";
import { Helmet } from "react-helmet-async";
import Joi from "joi";
import axios from "axios";
import { toast } from "react-toastify";
import Reveal from "../Reveal/Reveal";
import LoadingCircle from "../LoadingCircle/LoadingCircle";
import { motion } from "framer-motion";

const DemoDrop = () => {
  let { baseUrl, navigate, getData, isMainDataLoading } = useContext(fmDataContext);

  let [pageHeader, setPageHeader] = useState({});

  let [isGetPageHeaderLoading, setIsGetPageHeaderLoading] = useState(true);

  let [joiErrorsList, setJoiErrorsList] = useState([]);

  let [demoResponse, setDemoResponse] = useState({});

  let [isSendDemoLoading, setIsSendDemoLoading] = useState(false);

  const getPageHeader = (reqMethod, pathName, params, setLoading = setIsGetPageHeaderLoading) => {
    getData(reqMethod, pathName, params, setLoading)
      .then((response) => {
        // ------------------- REMOVE BEFORE PRODUCTION -----------------------------------
        // if (response.data.page_header && response.data.page_header.image.includes("via.placeholder.com")) {
        //   response.data.page_header.image = response.data.page_header.image.replace("http://127.0.0.1:8000/storage/", "");
        // }
        // ------------------- REMOVE BEFORE PRODUCTION -----------------------------------
        setPageHeader(response.data.page_header);
        setLoading(false);
      })
      .catch((error) => {
        navigate("/not-found");
      });
  };

  const sendDemoMail = async (e) => {
    e.preventDefault();

    let demoData = {
      artist_name: e.target.artist_name.value,
      artist_email_address: e.target.artist_email_address.value,
      demo_url: e.target.demo_url.value,
      about_demo: e.target.about_demo.value.replaceAll("\n", "<br />"),
    };

    const validationResult = validateDemoForm(demoData);
    setDemoResponse({});

    if (validationResult.error) {
      validationResult.error ? setJoiErrorsList(validationResult.error.details) : setJoiErrorsList([]);
    } else {
      setIsSendDemoLoading(true);
      setJoiErrorsList([]);
      await axios({
        method: "POST",
        url: `${baseUrl}fm-track-demo-confirmation-mail`,
        data: demoData,
        headers: {
          Accept: "application/json",
          "fm-api-secret-key": process.env.REACT_APP_FM_API_SECRET_KEY,
        },
      })
        .then((response) => {
          setIsSendDemoLoading(false);
          if (response.data.message && response.data.message.includes("successfully")) {
            e.target.reset();
            setDemoResponse({});
            toast(`confirmation mail was sent successfully to ${demoData.artist_email_address}`, {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              progressStyle: { backgroundColor: "#fdb915" },
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
              progress: undefined,
              theme: "dark",
            });
          } else if (response.data.errors) {
            setDemoResponse(response.data);
          } else {
            navigate("/not-found");
          }
        })
        .catch((errors) => {
          navigate("/not-found");
        });
    }
  };

  const validateDemoForm = (demoData) => {
    const scheme = Joi.object({
      artist_name: Joi.string().min(1).max(255).required(),
      artist_email_address: Joi.string()
        .email({ tlds: { allow: ["com", "net", "org", "eg"] } })
        .required(),
      demo_url: Joi.string().pattern(new RegExp("^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|(www\\.)?){1}([0-9A-Za-z-\\.@:%_+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?")).message("Beatport URL is not valid").required(),
      about_demo: Joi.string().min(1).max(1000).required(),
    });
    return scheme.validate(demoData, { abortEarly: false });
  };

  useEffect(() => {
    getPageHeader(
      "GET",
      `${baseUrl}fm-get-page-header`,
      {
        page_title: "demo-drop",
      },
      setIsGetPageHeaderLoading
    );
  }, []);

  return (
    <>
      <Helmet>
        <title>Futurising Music - Demo Drop</title>
        <link rel="canonical" href={`${window.location.href}`} />
      </Helmet>
      {isGetPageHeaderLoading || isMainDataLoading ? (
        <LoadingScreen />
      ) : (
        <>
          {pageHeader ? (
            <div className="page-heading">
              <div className="heading-img-container" style={{ backgroundImage: `url(${pageHeader.image})` }} />
            </div>
          ) : (
            ""
          )}
          <div className="container position-relative pt-4">
            <div className="mb-3 mb-sm-4 mb-xl-5">
              <h1 className="page-heading-long-title mb-0">Demo Drop</h1>
            </div>
            <div className={`mb-3 mb-md-4 item-row-heading ${styles.contact_row_heading}`}>
              <h2 className="mb-0 row-title">Submit Your Demo Now</h2>
            </div>
            <div className="row">
              <div className="col-xl-8 order-last order-xl-first">
                <div className={`pe-0 pe-xl-4 ${styles.contact_form_box}`}>
                  <div>
                    <Form onSubmit={sendDemoMail}>
                      <Form.Group className="mb-2 mb-sm-3 fu-transition" controlId="artistName">
                        <Form.Label className="fu-text-light-gray" data-title="required">
                          Artist Name
                          <span className="required-input mx-1" data-title="required">
                            *
                          </span>
                        </Form.Label>
                        <Form.Control type="text" placeholder="Enter your name" className="fu-input" name="artist_name" />
                        {joiErrorsList.map((error, index) =>
                          error.path[0] === "artist_name" ? (
                            <motion.div key={index} initial={{ height: 0 }} animate={{ height: "auto" }} exit={{ height: 0 }} transition={{ duration: 0.2, ease: "easeInOut" }}>
                              <Reveal>
                                <div className="alert fu-alert-yellow mt-2 mb-1">{error.message.replaceAll(`"artist_name"`, "Artist Name")}</div>
                              </Reveal>
                            </motion.div>
                          ) : (
                            ""
                          )
                        )}
                      </Form.Group>

                      <Form.Group className="mb-2 mb-sm-3 fu-transition" controlId="artistEmail">
                        <Form.Label className="fu-text-light-gray" data-title="required">
                          Your E-mail Address
                          <span className="required-input mx-1" data-title="required">
                            *
                          </span>
                        </Form.Label>
                        <Form.Control type="email" placeholder="Enter your E-mail address" className="fu-input" name="artist_email_address" />
                        {joiErrorsList.map((error, index) =>
                          error.path[0] === "artist_email_address" ? (
                            <motion.div key={index} initial={{ height: 0 }} animate={{ height: "auto" }} exit={{ height: 0 }} transition={{ duration: 0.2, ease: "easeInOut" }}>
                              <Reveal>
                                <div className="alert fu-alert-yellow mt-2 mb-1">{error.message.replaceAll(`"artist_email_address"`, "Email Address")}</div>
                              </Reveal>
                            </motion.div>
                          ) : (
                            ""
                          )
                        )}
                      </Form.Group>

                      <Form.Group className="mb-2 mb-sm-3 fu-transition" controlId="trackUrl">
                        <Form.Label className="fu-text-light-gray" data-title="required">
                          Your track URL
                          <span className="required-input mx-1" data-title="required">
                            *
                          </span>
                        </Form.Label>
                        <Form.Control type="text" placeholder="Enter your unique track URL" className="fu-input" name="demo_url" />
                        {joiErrorsList.map((error, index) =>
                          error.path[0] === "demo_url" ? (
                            <motion.div key={index} initial={{ height: 0 }} animate={{ height: "auto" }} exit={{ height: 0 }} transition={{ duration: 0.2, ease: "easeInOut" }}>
                              <Reveal>
                                <div className="alert fu-alert-yellow mt-2 mb-1">{error.message.replaceAll(`"demo_url"`, "Track Demo")}</div>
                              </Reveal>
                            </motion.div>
                          ) : (
                            ""
                          )
                        )}
                      </Form.Group>
                      <Form.Group className="mb-2 mb-sm-3 fu-transition" controlId="aboutTrack">
                        <Form.Label className="fu-text-light-gray" data-title="required">
                          Tell us about your track
                          <span className="required-input mx-1" data-title="required">
                            *
                          </span>
                        </Form.Label>
                        <Form.Control as="textarea" rows={4} className="fu-input" placeholder="Enter your track description" name="about_demo" />
                        {joiErrorsList.map((error, index) =>
                          error.path[0] === "about_demo" ? (
                            <motion.div key={index} initial={{ height: 0 }} animate={{ height: "auto" }} exit={{ height: 0 }} transition={{ duration: 0.2, ease: "easeInOut" }}>
                              <Reveal>
                                <div className="alert fu-alert-yellow mt-2 mb-1">{error.message.replaceAll(`"about_demo"`, "Demo Description")}</div>
                              </Reveal>
                            </motion.div>
                          ) : (
                            ""
                          )
                        )}
                      </Form.Group>
                      {demoResponse.errors
                        ? Object.keys(demoResponse.errors).map((key) =>
                            demoResponse.errors[key].map((error, index) => (
                              <motion.div key={index} initial={{ height: 0 }} animate={{ height: "auto" }} exit={{ height: 0 }} transition={{ duration: 0.2, ease: "easeInOut" }}>
                                <Reveal>
                                  <div className="alert fu-alert-yellow mt-2 mb-1">{error.includes("The demo url has already been taken") ? "This Demo link might have already been sent before" : error}</div>
                                </Reveal>
                              </motion.div>
                            ))
                          )
                        : ""}
                      <div className="d-flex align-items-center mt-4">
                        <div>
                          <Button className="btn fu-btn fu-btn-gray d-flex justify-content-center align-items-center" type="submit" disabled={isSendDemoLoading ? true : false}>
                            <span className="btn-bg"></span>
                            Send<i className="bi bi-send ms-2"></i>
                          </Button>
                        </div>
                        {isSendDemoLoading ? (
                          <div className="position-relative ms-3">
                            <LoadingCircle circleCont={30} icon={25} loader={30} dot={30} />
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 order-first order-xl-last">
                <div className="mb-3 mb-xl-4">
                  <p className="fu-text-light-gray demo-drop-explain mb-0">
                    <span className="fu-fw-700 fu-text-yellow">Only</span> submit streamable links to your unreleased tracks. Preferably Soundcloud, Dropbox, Google Drive, etc. Please don't chase us for a response. if we like it, we will get in touch with you.
                  </p>
                </div>
                <div className="d-flex mb-4 mb-xl-0">
                  <div className={styles.social_box}>
                    <div>
                      <a href="https://soundcloud.com/" target="_blank" rel="noreferrer" className={styles.social_icon_container}>
                        <svg className={styles.social_icon} width={18} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" viewBox="0 0 97.774 97.774" space="preserve">
                          <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                          <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                          <g id="SVGRepo_iconCarrier">
                            <g>
                              <g>
                                <g>
                                  <path d="M4.723,49.69c-0.209,0-0.38,0.166-0.407,0.395l-0.961,8.781l0.961,8.586c0.027,0.229,0.198,0.396,0.407,0.396 c0.206,0,0.373-0.164,0.406-0.395l1.094-8.588l-1.094-8.781C5.096,49.851,4.929,49.69,4.723,49.69z"></path> <path d="M1.108,53.054c-0.203,0-0.365,0.156-0.392,0.383L0,58.866l0.716,5.337c0.026,0.226,0.188,0.382,0.392,0.382 c0.198,0,0.359-0.156,0.391-0.38l0.847-5.339l-0.847-5.434C1.467,53.21,1.306,53.054,1.108,53.054z"></path>
                                  <path d="M8.564,47.968c-0.258,0-0.464,0.202-0.49,0.48L7.161,58.866l0.913,10.037c0.026,0.279,0.232,0.48,0.49,0.48 c0.253,0,0.459-0.201,0.489-0.479l1.036-10.039L9.053,48.447C9.023,48.17,8.817,47.968,8.564,47.968z"></path> <path d="M12.437,47.6c-0.304,0-0.551,0.242-0.575,0.564L11,58.869l0.862,10.354c0.024,0.32,0.271,0.563,0.575,0.563 c0.299,0,0.545-0.243,0.573-0.563l0.979-10.354L13.01,48.162C12.982,47.842,12.736,47.6,12.437,47.6z"></path>
                                  <polygon points="16.996,69.31 16.996,69.31 16.996,69.312 "></polygon> <path d="M16.996,48.935c-0.024-0.365-0.308-0.644-0.656-0.644c-0.353,0-0.635,0.278-0.657,0.647l-0.814,9.93l0.814,10.441 c0.022,0.367,0.305,0.646,0.657,0.646c0.349,0,0.632-0.279,0.656-0.646l0.923-10.441L16.996,48.935z"></path>
                                  <path d="M20.276,41.985c-0.396,0-0.72,0.322-0.74,0.733l-0.764,16.155l0.764,10.44c0.021,0.406,0.345,0.729,0.74,0.729 c0.392,0,0.716-0.322,0.74-0.732v0.004l0.864-10.44l-0.864-16.156C20.991,42.307,20.667,41.985,20.276,41.985z"></path>
                                  <path d="M24.181,38.264c-0.443,0-0.804,0.358-0.824,0.815c0,0.002-0.715,19.85-0.715,19.85l0.716,10.375 c0.02,0.455,0.38,0.813,0.823,0.813c0.44,0,0.804-0.358,0.823-0.815v0.006l0.809-10.379l-0.809-19.85 C24.985,38.622,24.621,38.264,24.181,38.264z"></path>
                                  <path d="M28.241,36.47c-0.491,0-0.89,0.396-0.907,0.9c0,0.001-0.665,21.508-0.665,21.508l0.665,10.268 c0.018,0.5,0.416,0.895,0.907,0.895c0.488,0,0.887-0.395,0.907-0.898v0.006l0.751-10.27L29.148,37.37 C29.127,36.866,28.729,36.47,28.241,36.47z"></path>
                                  <path d="M33.26,36.647c-0.017-0.552-0.452-0.985-0.989-0.985c-0.541,0-0.976,0.434-0.991,0.985l-0.616,22.231l0.617,10.204 c0.015,0.546,0.449,0.978,0.99,0.978c0.537,0,0.973-0.432,0.989-0.981l0.694-10.198L33.26,36.647z"></path> <polygon points="33.26,69.078 33.26,69.078 33.26,69.083 "></polygon>
                                  <path d="M36.332,36.149c-0.59,0-1.061,0.469-1.075,1.068l-0.566,21.665l0.568,10.105c0.013,0.593,0.483,1.064,1.073,1.064 c0.586,0,1.057-0.472,1.073-1.07v0.008l0.638-10.109l-0.638-21.666C37.389,36.616,36.918,36.149,36.332,36.149z"></path>
                                  <path d="M40.425,36.856c-0.636,0-1.146,0.507-1.158,1.153L38.75,58.882l0.517,10.045c0.012,0.64,0.522,1.145,1.158,1.145 c0.635,0,1.144-0.505,1.156-1.152v0.008l0.58-10.043l-0.58-20.875C41.569,37.363,41.06,36.856,40.425,36.856z"></path>
                                  <path d="M45.235,33.02c-0.196-0.133-0.433-0.211-0.686-0.211c-0.246,0-0.475,0.075-0.668,0.201 c-0.339,0.221-0.566,0.602-0.572,1.036l-0.004,0.234l-0.466,24.598c0,0.014,0.47,9.984,0.47,9.984 c0,0.016,0.002,0.027,0.003,0.042c0.014,0.278,0.119,0.534,0.288,0.737c0.229,0.273,0.57,0.449,0.949,0.449 c0.337,0,0.644-0.139,0.868-0.363c0.225-0.223,0.367-0.531,0.373-0.873l0.052-0.986l0.471-8.984l-0.522-24.839 C45.783,33.618,45.563,33.242,45.235,33.02z"></path>
                                  <polygon points="45.79,68.862 45.79,68.859 45.79,68.863 45.79,68.858 45.79,68.854 45.79,68.854 45.79,68.859 "></polygon> <path d="M49.329,30.665c-0.196-0.12-0.428-0.19-0.673-0.19c-0.316,0-0.607,0.114-0.836,0.302 c-0.294,0.243-0.484,0.609-0.49,1.019l-0.003,0.136l-0.54,26.956l0.276,4.982l0.267,4.85c0.011,0.717,0.604,1.311,1.326,1.311 c0.719,0,1.312-0.594,1.322-1.32v0.01v0.002l0.59-9.834l0,0L49.98,31.794C49.973,31.315,49.711,30.896,49.329,30.665z"></path>
                                  <path d="M85.748,46.063c-1.646,0-3.22,0.334-4.65,0.933c-0.959-10.837-10.047-19.339-21.133-19.339 c-2.714,0-5.357,0.534-7.693,1.437c-0.907,0.352-1.147,0.712-1.156,1.414c0,0.001,0,38.168,0,38.168 c0.009,0.735,0.579,1.308,1.298,1.379c0.031,0.004,33.336,0,33.336,0c6.642,0,12.025-5.324,12.025-11.967 C97.774,51.447,92.391,46.063,85.748,46.063z"></path>
                                </g>
                              </g>
                            </g>
                          </g>
                        </svg>
                      </a>
                    </div>
                    <div>
                      <a href="https://www.dropbox.com/" target="_blank" rel="noreferrer" className={styles.social_icon_container}>
                        <svg className={styles.social_icon} width={15} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                          <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                          <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                          <g id="SVGRepo_iconCarrier">
                            <path d="M8 2.412l-8 5.093 8 5.093 8-5.093zM24 2.412l-8 5.093 8 5.093 8-5.093zM0 17.697l8 5.1 8-5.1-8-5.093zM24 12.604l-8 5.093 8 5.1 8-5.1zM8 24.495l8 5.093 8-5.093-8-5.093z"></path>
                          </g>
                        </svg>
                      </a>
                    </div>
                    <div>
                      <a href="https://drive.google.com/" target="_blank" rel="noreferrer" className={styles.social_icon_container}>
                        <svg className={styles.social_icon} width={15} viewBox="0 0 24 24" role="img" xmlns="http://www.w3.org/2000/svg">
                          <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                          <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                          <g id="SVGRepo_iconCarrier">
                            <path d="M4.433 22.396l4-6.929H24l-4 6.929H4.433zm3.566-6.929l-3.998 6.929L0 15.467 7.785 1.98l3.999 6.931-3.785 6.556zm15.784-.375h-7.999L7.999 1.605h8.002l7.785 13.486h-.003z"></path>
                          </g>
                        </svg>
                      </a>
                    </div>
                    <div>
                      <a href="https://wetransfer.com/" target="_blank" rel="noreferrer" className={styles.social_icon_container}>
                        <svg className={styles.social_icon} width={20} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                          <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                          <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                          <g id="SVGRepo_iconCarrier">
                            <path d="m2.705 15.571-2.596-7.348c-.063-.168-.102-.361-.109-.564v-.003c.009-.568.471-1.025 1.04-1.025.013 0 .026 0 .04.001h-.002 12.08.014c.566 0 1.025.459 1.026 1.025-.009.18-.041.349-.093.509l.004-.014-2.628 7.418c-.139.561-.631.972-1.223.986h-.001-.075c-.003 0-.006 0-.01 0-.579 0-1.064-.4-1.195-.938l-.002-.008-1.166-3.552c-.096-.296-.369-.505-.691-.505-.01 0-.02 0-.03.001h.001c-.007 0-.015 0-.023 0-.326 0-.602.209-.704.5l-.002.005-1.167 3.553c-.131.546-.615.946-1.193.946h-.07c-.597-.001-1.096-.418-1.223-.977l-.002-.008zm20.218-1.668c-.053-.01-.113-.016-.175-.016-.184 0-.356.053-.502.144l.004-.002c-.605.39-1.342.627-2.133.64h-.003c-.039.002-.085.003-.131.003-1.486 0-2.706-1.143-2.828-2.597l-.001-.01c.862.339 1.861.536 2.906.536.164 0 .327-.005.489-.014l-.022.001h.056c1.005 0 1.948-.262 2.765-.722l-.028.015c.423-.285.697-.763.697-1.304 0-.149-.021-.294-.06-.431l.003.011c-.193-2.116-1.959-3.761-4.109-3.761-.074 0-.148.002-.222.006h.01c-.047-.002-.102-.003-.157-.003-2.695 0-4.88 2.185-4.88 4.88 0 .107.003.214.01.319l-.001-.014c-.001.035-.001.077-.001.118 0 2.802 2.271 5.073 5.073 5.073.09 0 .179-.002.268-.007l-.012.001c1.248-.004 2.41-.372 3.384-1.005l-.024.015c.232-.164.395-.411.446-.697l.001-.007c.011-.057.018-.122.018-.188 0-.494-.361-.903-.834-.978l-.006-.001z"></path>
                          </g>
                        </svg>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default DemoDrop;
