import React from "react";
import styles from "./LoadingCircle.module.scss";
import Images from "../../images";

const LoadingCircle = ({ circleCont, icon, loader, dot }) => {
  return (
    <div className={styles.loading_circle} style={{ width: `${circleCont}px`, height: `${circleCont}px` }}>
      <div className={styles.logo_container} style={{ width: `${icon}px`, height: `${icon}px` }}>
        <img src={Images.futurisingIconSquare} alt="Company logo" style={{ width: `${icon}px`, height: `${icon}px` }} />
      </div>
      <div className={styles.loader} style={{ width: `${loader}px`, height: `${loader}px` }}>
        <div className={styles.circle} style={{ width: `${dot}px`, height: `${dot}px` }}></div>
        <div className={styles.circle} style={{ width: `${dot}px`, height: `${dot}px` }}></div>
        <div className={styles.circle} style={{ width: `${dot}px`, height: `${dot}px` }}></div>
        <div className={styles.circle} style={{ width: `${dot}px`, height: `${dot}px` }}></div>
        <div className={styles.circle} style={{ width: `${dot}px`, height: `${dot}px` }}></div>
      </div>
    </div>
  );
};

export default LoadingCircle;
