import React, { createContext, useState } from "react";

export let fmMusicContext = createContext(0);

const MusicContext = ({ children }) => {
  let [filtrationDetails, setFiltrationDetails] = useState({
    search_query: "",
    pre_order: null,
    label_keys: [],
    genre_keys: [],
    artist_keys: [],
    top_five_releases: null,
  });

  let [isFiltrationLoading, setIsFiltrationLoading] = useState(false);

  let fmMusicLifting = {
    filtrationDetails,
    setFiltrationDetails,
    isFiltrationLoading,
    setIsFiltrationLoading,
  };

  return (
    <>
      <fmMusicContext.Provider value={{ ...fmMusicLifting }}>{children}</fmMusicContext.Provider>
    </>
  );
};

export default MusicContext;
