import React from "react";
import styles from "./LoadingScreen.module.scss";
import Images from "../../images";

const LoadingScreen = () => {
  return (
    <div className={styles.loading_layout}>
      <div className={styles.loading_screen}>
        <div className={styles.logo_container}>
          <img src={Images.futurisingIconSquare} alt="Company logo" />
        </div>
        <div className={styles.loader}>
          <div className={styles.circle}></div>
          <div className={styles.circle}></div>
          <div className={styles.circle}></div>
          <div className={styles.circle}></div>
          <div className={styles.circle}></div>
        </div>
      </div>
    </div>
  );
};

export default LoadingScreen;
