import React from "react";
import ReleaseCard from "../ReleaseCard/ReleaseCard";
import VideoCard from "../VideoCard/VideoCard";
import ArtistCard from "../ArtistCard/ArtistCard";
import LabelCard from "../LabelCard/LabelCard";
import NewBlogCard from "../NewBlogCard/NewBlogCard";

const SearchResult = (props) => {
  let { searchWords, searchObj, searchitems, isSearchItemsLoading, isPaginateLoading, isSearchLoading } = props;

  return (
    <>
      <>
        {searchObj === "releases" ? (
          <>
            {searchitems.data && searchitems.data.length > 0 && searchitems.data[0].release_key ? (
              <div className="row g-2 g-sm-3">
                {searchitems.data.map((release, index) => (
                  <div className="col-xxs-6 col-xs-6 col-sm-6 col-lg-4 col-xxl-3" key={index}>
                    <ReleaseCard release={release} />
                  </div>
                ))}
              </div>
            ) : searchitems.data && searchitems.data.length === 0 && !isSearchItemsLoading && !isPaginateLoading && !isSearchLoading ? (
              <div className="d-flex flex-column justify-content-center align-items-center mt-5 pt-5 item-msg">
                <div>
                  <h2 className="fu-text-3-rem fu-text-yellow">
                    <i className="bi bi-info-circle"></i>
                  </h2>
                </div>
                <div>
                  <h3 className="h5 fu-text-light-gray">
                    THERE IS NO RESULT FOR <span className="fu-text-yellow">"{`${searchWords.length > 15 ? searchWords.slice(0, 15) + "..." : searchWords}`.toUpperCase()}"</span> IN <span className="fu-text-yellow">RELEASES</span>
                  </h3>
                </div>
              </div>
            ) : (
              ""
            )}
          </>
        ) : (
          ""
        )}
        {searchObj === "videos" ? (
          <>
            {searchitems.data && searchitems.data.length > 0 && searchitems.data[0].video_key ? (
              <div className="row g-3">
                {searchitems.data.map((video, index) => (
                  <div className="col-12 col-lg-6" key={index}>
                    <VideoCard video={video} />
                  </div>
                ))}
              </div>
            ) : searchitems.data && searchitems.data.length === 0 && !isSearchItemsLoading && !isPaginateLoading && !isSearchLoading ? (
              <div className="d-flex flex-column justify-content-center align-items-center mt-5 pt-5 item-msg">
                <div>
                  <h2 className="fu-text-3-rem fu-text-yellow">
                    <i className="bi bi-info-circle"></i>
                  </h2>
                </div>
                <div>
                  <h3 className="h5 fu-text-light-gray">
                    THERE IS NO RESULT FOR <span className="fu-text-yellow">"{`${searchWords.length > 15 ? searchWords.slice(0, 15) + "..." : searchWords}`.toUpperCase()}"</span> IN <span className="fu-text-yellow">VIDEOS</span>
                  </h3>
                </div>
              </div>
            ) : (
              ""
            )}
          </>
        ) : (
          ""
        )}
        {searchObj === "artists" ? (
          <>
            {searchitems.data && searchitems.data.length > 0 && searchitems.data[0].artist_key ? (
              <div className="row g-2 g-md-3">
                {searchitems.data.map((artist, index) => (
                  <div className="col-vs-6 col-xxxs-6 col-xxs-4 col-xs-4 col-sm-4 col-md-3 fu-col-lg-20 fu-col-xl-20 col-xxl-2" key={index}>
                    <ArtistCard artist={artist} />
                  </div>
                ))}
              </div>
            ) : searchitems.data && searchitems.data.length === 0 && !isSearchItemsLoading && !isPaginateLoading && !isSearchLoading ? (
              <div className="d-flex flex-column justify-content-center align-items-center mt-5 pt-5 item-msg">
                <div>
                  <h2 className="fu-text-3-rem fu-text-yellow">
                    <i className="bi bi-info-circle"></i>
                  </h2>
                </div>
                <div>
                  <h3 className="h5 fu-text-light-gray">
                    THERE IS NO RESULT FOR <span className="fu-text-yellow">"{`${searchWords.length > 15 ? searchWords.slice(0, 15) + "..." : searchWords}`.toUpperCase()}"</span> IN <span className="fu-text-yellow">ARTISTS</span>
                  </h3>
                </div>
              </div>
            ) : (
              ""
            )}
          </>
        ) : (
          ""
        )}
        {searchObj === "labels" ? (
          <>
            {searchitems.data && searchitems.data.length > 0 && searchitems.data[0].label_key ? (
              <div className="row g-2 g-md-3">
                {searchitems.data.map((label, index) => (
                  <div className="col-vs-6 col-xxxs-6 col-xxs-4 col-xs-4 col-sm-4 col-md-3 fu-col-lg-20 fu-col-xl-20 col-xxl-2" key={index}>
                    <LabelCard label={label} />
                  </div>
                ))}
              </div>
            ) : searchitems.data && searchitems.data.length === 0 && !isSearchItemsLoading && !isPaginateLoading && !isSearchLoading ? (
              <div className="d-flex flex-column justify-content-center align-items-center mt-5 pt-5 item-msg">
                <div>
                  <h2 className="fu-text-3-rem fu-text-yellow">
                    <i className="bi bi-info-circle"></i>
                  </h2>
                </div>
                <div>
                  <h3 className="h5 fu-text-light-gray">
                    THERE IS NO RESULT FOR <span className="fu-text-yellow">"{`${searchWords.length > 15 ? searchWords.slice(0, 15) + "..." : searchWords}`.toUpperCase()}"</span> IN <span className="fu-text-yellow">LABELS</span>
                  </h3>
                </div>
              </div>
            ) : (
              ""
            )}
          </>
        ) : (
          ""
        )}
        {searchObj === "news" ? (
          <>
            {searchitems.data && searchitems.data.length > 0 && searchitems.data[0].new_key ? (
              <div className="row g-3">
                {searchitems.data.map((newBlog, index) => (
                  <div className="col-12 col-lg-6" key={index}>
                    <NewBlogCard newBlog={newBlog} />
                  </div>
                ))}
              </div>
            ) : searchitems.data && searchitems.data.length === 0 && !isSearchItemsLoading && !isPaginateLoading && !isSearchLoading ? (
              <div className="d-flex flex-column justify-content-center align-items-center mt-5 pt-5 item-msg">
                <div>
                  <h2 className="fu-text-3-rem fu-text-yellow">
                    <i className="bi bi-info-circle"></i>
                  </h2>
                </div>
                <div>
                  <h3 className="h5 fu-text-light-gray">
                    THERE IS NO RESULT FOR <span className="fu-text-yellow">"{`${searchWords.length > 15 ? searchWords.slice(0, 15) + "..." : searchWords}`.toUpperCase()}"</span> IN <span className="fu-text-yellow">NEWS</span>
                  </h3>
                </div>
              </div>
            ) : (
              ""
            )}
          </>
        ) : (
          ""
        )}
      </>
    </>
  );
};

export default SearchResult;
